import SourceContainer from '@tyrio/forms';
import { PageTemplateContent } from '../../../components/PageTemplate/PageTemplate';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import {
  DeepPartial,
  FieldValues,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderFields } from './RenderFormFields';
import { DropdownValues, FormShape } from '../constants/service.form';
import { ZodSchema } from 'zod';
import { useEffect, useState } from 'react';
import { isEqual, isNaN, keys, omit } from 'lodash';
import { CancelModal } from '@tyrio/ui-library';
import { useAuth } from '../../../context/AuthContext';
import { SettingsPayload } from '@tyrio/dto';

interface GenerateFormProps<T extends object> {
  form: FormShape[];
  zodSchema: ZodSchema<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  doSubmit: (data: FieldValues) => void;
  fixedPriceForm?: FormShape;
  chargedByLaborTime?: FormShape;
  changeProductCategory?: (a: string) => void;
  disableDelete?: boolean;
  onDelete?: () => void;
  onCancel?: () => void;
  changeSubcategory?: (a: DropdownValues[]) => void;
}

export function GenerateForm<T extends object>({
  form,
  zodSchema,
  initialValues,
  doSubmit,
  fixedPriceForm,
  chargedByLaborTime,
  changeProductCategory,
  disableDelete,
  onDelete,
  onCancel,
  changeSubcategory,
}: GenerateFormProps<T>) {
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: initialValues as UnpackNestedValue<DeepPartial<T>>,
    resolver: zodResolver(zodSchema),
  });

  const { user } = useAuth();

  const pricePerHour = (
    user?.client?.generalSettings as unknown as SettingsPayload
  )?.pricePerHour;

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const watchRadioButton = watch('radioButton');
  const productCategoryId = watch('productCategoryId');
  const laborTime = watch('servicePrice.chargedByLaborTime.laborTime');
  const subcategories = watch('subCategoryId');
  const exclusiveVat = watch('servicePrice.fixedPrice.exclusiveVat');

  useEffect(() => {
    const keysOfValues = keys(getValues());
    const keysOfInitialValues = keys(initialValues);

    // RESET ONLY WHEN SWITCHING BETWEEN FORMS
    if (!isEqual(keysOfValues, keysOfInitialValues)) reset(initialValues);
  }, [getValues, initialValues, reset]);

  useEffect(() => {
    if (changeProductCategory !== undefined)
      changeProductCategory(productCategoryId);
  }, [productCategoryId, changeProductCategory]);

  useEffect(() => {
    if (changeSubcategory !== undefined) changeSubcategory(subcategories);
  }, [subcategories, changeSubcategory]);

  useEffect(() => {
    if (watchRadioButton === 'chargedByLaborTime') {
      setValue(
        'servicePrice.chargedByLaborTime.pricePerHour',
        pricePerHour.toFixed(2)
      );
      setValue(
        'servicePrice.chargedByLaborTime.finalPrice',
        (Number(pricePerHour) * laborTime).toFixed(2)
      );
    } else if (watchRadioButton === 'freeService')
      setValue('servicePrice.freeService', {});
    else if (watchRadioButton === 'fixedPrice') {
      // PDV is 25% therefore we multiply price with 1.25
      setValue(
        'servicePrice.fixedPrice.inclusiveVat',
        (exclusiveVat * 1.25).toFixed(2)
      );
    }
  }, [
    setValue,
    watch,
    watchRadioButton,
    pricePerHour,
    getValues,
    laborTime,
    exclusiveVat,
  ]);

  const checkIfDirty = () => {
    let equalValues = isEqual(initialValues, watch());
    if (!equalValues) {
      // when serviceDuration is empty it appears as different value
      if (
        isNaN(watch('serviceDuration')) &&
        initialValues.serviceDuration === null
      )
        equalValues = isEqual(
          omit(watch(), 'serviceDuration'),
          omit(initialValues, 'serviceDuration')
        );
    }

    return { dirtyFields: isDirty || !equalValues };
  };

  const { dirtyFields } = checkIfDirty();

  const doCancel = () => {
    if (dirtyFields) setIsCancelModalVisible(true);
    else if (onCancel) onCancel();
  };

  return (
    <PageTemplateContent style={{ paddingBottom: '100px', height: '70%' }}>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            if (onCancel) onCancel();
            setIsCancelModalVisible(false);
          }}
        />
      )}
      <Wrapper>
        <FormWrapper onSubmit={handleSubmit(doSubmit)}>
          {form.map((fields, index) => {
            return (
              <div key={index}>
                {fields.isGroup ? (
                  <GroupWrapper>
                    <Typography
                      fontWeight={600}
                      fontSize={18}
                      style={{ paddingBottom: '10px' }}
                    >
                      {fields.title}
                    </Typography>
                    {renderFields(fields, register, control, errors)}
                    {watchRadioButton === 'fixedPrice' &&
                      fields.title === 'Service price' &&
                      fixedPriceForm !== undefined && (
                        <div>
                          {renderFields(
                            fixedPriceForm,
                            register,
                            control,
                            errors
                          )}
                        </div>
                      )}
                    {watchRadioButton === 'chargedByLaborTime' &&
                      fields.title === 'Service price' &&
                      chargedByLaborTime !== undefined && (
                        <div>
                          {renderFields(
                            chargedByLaborTime,
                            register,
                            control,
                            errors
                          )}
                        </div>
                      )}
                  </GroupWrapper>
                ) : (
                  renderFields(fields, register, control, errors)
                )}
              </div>
            );
          })}
          <SourceContainer
            data={{
              data_source: '',
              created: '',
              last_edited: '',
            }}
            onCancel={doCancel}
            onDelete={onDelete}
            customStyle={{
              background: 'white',
              zIndex: 1,
              bottom: 0,
              paddingBottom: '16px',
            }}
            disableSave={!dirtyFields}
            disabledDelete={disableDelete}
          />
        </FormWrapper>
      </Wrapper>
    </PageTemplateContent>
  );
}

const GroupWrapper = styled.div`
  padding: 16px;
  background: rgb(249, 250, 251);
  border: 1px solid rgb(223, 227, 232);
  border-radius: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  height: 100%;
  overflow-y: scroll;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
