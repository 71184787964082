import * as z from "zod"
import { DBStockInItemStatus, DBStockInItemType } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBOrderDocument, RelatedDBOrderDocumentModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBStockInItemsModel = z.object({
  id: z.string(),
  clientId: z.string(),
  status: z.nativeEnum(DBStockInItemStatus),
  documentId: z.string(),
  documentMeta: jsonSchema,
  createdAt: z.date(),
  orderType: z.nativeEnum(DBStockInItemType),
  receivedFromMeta: jsonSchema,
  orderMeta: jsonSchema,
  destinationMeta: jsonSchema,
  lineItems: jsonSchema,
  priceMeta: jsonSchema,
  quantity: z.number().int(),
  remark: z.string(),
  reference: z.string(),
  locationInfo: jsonSchema,
  stockInProductsReq: jsonSchema,
  referenceMeta: jsonSchema,
  isLocked: z.boolean(),
  favoritedBy: z.string().array(),
  createdBy: jsonSchema,
  customerDetails: jsonSchema,
  dropShipping: z.boolean(),
})

export interface CompleteDBStockInItems extends z.infer<typeof DBStockInItemsModel> {
  client: CompleteDBClient
  DBOrderDocument: CompleteDBOrderDocument[]
}

/**
 * RelatedDBStockInItemsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBStockInItemsModel: z.ZodSchema<CompleteDBStockInItems> = z.lazy(() => DBStockInItemsModel.extend({
  client: RelatedDBClientModel,
  DBOrderDocument: RelatedDBOrderDocumentModel.array(),
}))
