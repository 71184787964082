import styled from '@emotion/styled/macro';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  ActivePurchaseAndTransferOrdersList,
  DBClientSupplierOrderItemApi,
  DBStockInItemsResponse,
  DBStockInListApi,
  DBTransferOrderItemApi,
  PriceMeta,
  StockInDestinationMeta,
  StockInLineItem,
  StockInOrderMeta,
} from '@tyrio/dto';

import { TyrioSelectInputOption } from '@tyrio/forms';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from '../../../../../app/query-client';
import {
  InputOption,
  parseEnumToInputOption,
} from '../../../../../helpers/enum-parser';
import { StockInContext } from '../../../context/StockInContext';
import DetailsTableItem from './DetailsTableItem';
import { ConfirmationModal } from './components/ConfirmationModal';
import StyledDelivery from './components/StyledDelivery';
import { useGetOrderPrices } from './queries/get-order-prices';

interface StockInOrderDetailsProps {
  selectedOrderData: DBStockInItemsResponse;
  setSelectedOrderData: (item: DBStockInItemsResponse | null) => void;
}

const StockInOrderDetails = ({
  selectedOrderData,
  setSelectedOrderData,
}: StockInOrderDetailsProps) => {
  const { setIsDirty, checkedLineItems } = useContext(StockInContext);
  const [suppliersData, setSuppliersData] = useState<TyrioSelectInputOption[]>(
    []
  );
  const [purchaseData, setPurchaseData] = useState<TyrioSelectInputOption[]>(
    []
  );
  const [transferData, setTransferData] = useState<TyrioSelectInputOption[]>(
    []
  );

  const [showModal, setShowModal] = useState(false);

  const statuses = parseEnumToInputOption(DBStockInItemStatus);
  const prefix =
    selectedOrderData.orderType === 'TRANSFER_ORDER' ? 'TO-0000' : '';
  const orderMeta: StockInOrderMeta =
    selectedOrderData.orderMeta as unknown as StockInOrderMeta;
  const destinationMeta: StockInDestinationMeta =
    selectedOrderData.destinationMeta as unknown as StockInDestinationMeta;
  const receivedFromMeta: StockInDestinationMeta =
    selectedOrderData?.receivedFromMeta as unknown as StockInDestinationMeta;

  const defaultStatus = useMemo(() => {
    return {
      label: selectedOrderData.status as keyof typeof DBStockInItemStatus,
      value: selectedOrderData.status as keyof typeof DBStockInItemStatus,
    } as InputOption;
  }, [selectedOrderData]);

  const defaultSupplier = useMemo(() => {
    if (
      orderMeta.supplierId &&
      orderMeta.supplierName &&
      orderMeta.supplierName !== 'UNKNOWN'
    )
      return {
        value: orderMeta.supplierId,
        label: orderMeta.supplierName,
      } as TyrioSelectInputOption;
    return {
      value: 'unknown',
      label: 'Unknown',
    } as TyrioSelectInputOption;
  }, [orderMeta]);

  const defaultPurchase = useMemo(() => {
    if (
      orderMeta.orderNumber &&
      orderMeta.orderId &&
      orderMeta.requestType === 0
    )
      return {
        value: orderMeta.orderId,
        label: orderMeta.orderNumber.toString(),
        reference: orderMeta.reference ?? '',
      } as TyrioSelectInputOption;
    return {
      value: 'unmatched',
      label: 'Unmatched',
    };
  }, [
    orderMeta.orderId,
    orderMeta.orderNumber,
    orderMeta.reference,
    orderMeta.requestType,
  ]);

  const defaultTransfer = useMemo(() => {
    if (
      orderMeta.orderNumber &&
      orderMeta.orderId &&
      orderMeta.requestType === 1
    )
      return {
        value: orderMeta.orderId,
        label: orderMeta.orderNumber.toString(),
        reference: orderMeta.reference ?? '',
      } as TyrioSelectInputOption;
    return {
      value: 'unmatched',
      label: 'Unmatched',
    };
  }, [
    orderMeta.orderId,
    orderMeta.orderNumber,
    orderMeta.reference,
    orderMeta.requestType,
  ]);

  useQuery(
    ['get_all_stock_in_suppliers'],
    async () => {
      return await api.fetch<DBStockInListApi['getSuppliers']>(
        'get_all_stock_in_suppliers',
        { sortAlphabetically: true }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.map(
          (item: ActivePurchaseAndTransferOrdersList) => ({
            label: item.source,
            value: item.id,
            reference: item.type === 1 ? 'transfer' : 'purchase',
          })
        );

        dropdownItems.push({
          value: 'unknown',
          label: 'Unknown',
          reference: 'purchase',
        });

        setSuppliersData(dropdownItems);
      },
    }
  );

  const { refetch: refetchPurchase } = useQuery(
    [
      'active_purchase_orders',
      {
        id: orderMeta.orderId,
      },
    ],
    async () => {
      return await api.fetch<DBClientSupplierOrderItemApi['getActive']>(
        'active_purchase_orders',
        {
          id: orderMeta.orderId,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data
          ?.filter((item) => item.supplierId === orderMeta.supplierId)
          .map((item) => {
            return {
              value: item.id,
              label: item.orderNumber,
              reference: item.reference ?? '',
            };
          });

        dropdownItems.push({
          value: 'unmatched',
          label: 'Unmatched',
          reference: '',
        });

        setPurchaseData(dropdownItems);
      },
    }
  );

  const { refetch: refetchTransfer } = useQuery(
    [
      'active_transfer_orders',
      {
        id: orderMeta.orderId,
      },
    ],
    async () => {
      return await api.fetch<DBTransferOrderItemApi['getActive']>(
        'active_transfer_orders',
        {
          id: orderMeta.orderId,
        }
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.map((item) => ({
          value: item.id,
          label: item.orderNumber ?? '',
          reference: item.reference ?? '',
        }));

        dropdownItems.push({
          value: 'unmatched',
          label: 'Unmatched',
          reference: '',
        });

        setTransferData(dropdownItems);
      },
    }
  );

  useEffect(() => {
    refetchPurchase();
    refetchTransfer();
  }, [
    orderMeta.supplierId,
    selectedOrderData,
    refetchPurchase,
    refetchTransfer,
  ]);

  const { mutateAsync, status } = useGetOrderPrices({
    selectedOrderData,
    setSelectedOrderData,
  });

  const toolTipText =
    checkedLineItems.length === 0
      ? 'Select items'
      : checkedLineItems.length ===
        (selectedOrderData.lineItems as unknown as StockInLineItem[]).length
      ? `You can't move all items`
      : 'Split stock in';

  const filterDropdownOptions = (
    value: string | undefined,
    options: TyrioSelectInputOption[]
  ) => {
    if (!value || value === '') return options;
    return options.filter(
      (option: TyrioSelectInputOption) =>
        option.label.toLowerCase().includes(value.toLowerCase()) ||
        (option.reference &&
          option.reference.toLowerCase().includes(value.toLowerCase()))
    );
  };

  return (
    <Container>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          setOpen={setShowModal}
          id={selectedOrderData.id}
        />
      )}
      <Grid>
        <Grid container spacing={1.5} padding="16px">
          <Grid item xs={5}>
            <Autocomplete
              disablePortal
              disableClearable
              id="supplier"
              value={defaultSupplier}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, newValue) => {
                setSelectedOrderData({
                  ...selectedOrderData,
                  orderMeta: {
                    ...orderMeta,
                    supplierId:
                      newValue.value !== 'unknown'
                        ? newValue.value.toString()
                        : '',
                    supplierName:
                      newValue.value !== 'unknown'
                        ? suppliersData.find(
                            (item) => item.value === newValue.value
                          )?.label || ''
                        : '',
                    requestType: newValue.reference === 'transfer' ? 1 : 0,
                    orderId: 'unmatched',
                    orderNumber: 'Unmatched',
                  },
                });
              }}
              options={suppliersData}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Supplier" />
              )}
            />
          </Grid>

          <Grid item xs={3.5}>
            <Autocomplete
              disablePortal
              disableClearable
              id={
                orderMeta.requestType === 0
                  ? 'purchaseOrders'
                  : 'transferOrders'
              }
              filterSelectedOptions
              value={
                orderMeta.requestType === 0 ? defaultPurchase : defaultTransfer
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, newValue) => {
                setSelectedOrderData({
                  ...selectedOrderData,
                  reference:
                    newValue.reference ?? selectedOrderData.reference ?? '',
                  orderMeta: {
                    ...orderMeta,
                    orderId:
                      newValue.value !== 'unmatched'
                        ? newValue.value.toString()
                        : 'unmatched',
                    orderNumber:
                      newValue.value !== 'unmatched' ? newValue.label : 0,
                    reference: newValue.reference ?? orderMeta.reference ?? '',
                  },
                });
              }}
              options={
                orderMeta.requestType === 0 ? purchaseData : transferData
              }
              filterOptions={(options, state) =>
                filterDropdownOptions(state.inputValue, options)
              }
              sx={{ width: '100%' }}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <span>{option.label}</span>
                    <br />
                    <span style={{ fontSize: '0.875em', color: 'gray' }}>
                      {option.reference}
                    </span>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    orderMeta.requestType === 0
                      ? 'Purchase orders'
                      : 'Transfer orders'
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={3.5}>
            <Autocomplete
              disablePortal
              disableClearable
              id="orderStatus"
              value={defaultStatus}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_event, newValue) => {
                if (selectedOrderData.status !== newValue.value)
                  setIsDirty(true);
                setSelectedOrderData({
                  ...selectedOrderData,
                  status: newValue.value as keyof typeof DBStockInItemStatus,
                });
              }}
              options={statuses}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Delivery details */}
      <StyledDelivery
        orderMeta={orderMeta}
        destinationMeta={destinationMeta}
        prefix={prefix}
        selectedOrderData={selectedOrderData}
        receivedFromMeta={receivedFromMeta}
      />

      <Grid
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {selectedOrderData.status !== 'COMPLETED' && (
          <Tooltip placement="top" title={toolTipText}>
            <Button
              size="small"
              component="label"
              color="info"
              variant="outlined"
              disabled={checkedLineItems.length === 0}
              startIcon={<CallSplitIcon />}
              onClick={() => setShowModal(true)}
            >
              SPLIT STOCK IN
            </Button>
          </Tooltip>
        )}

        <Button
          size="small"
          component="label"
          color="info"
          variant="outlined"
          startIcon={
            status === 'loading' ? (
              <CircularProgress
                sx={{ marginRight: '2px' }}
                size={14}
                color="info"
              />
            ) : (
              <SyncIcon />
            )
          }
          onClick={() => {
            const orderId = (
              selectedOrderData.orderMeta as unknown as StockInOrderMeta
            ).orderId;
            if (orderId !== 'unmatched') {
              queryClient.invalidateQueries('get_order_prices');
              mutateAsync(orderId);
            }
          }}
        >
          FETCH PRICES
        </Button>
      </Grid>
      <ProductsTable>
        <TableHead>
          <StockWrapper>
            <Typography
              variant="body2"
              sx={{ marginLeft: '15px', marginRight: '25px' }}
            >
              Price
            </Typography>
            <Typography variant="body2">Order</Typography>
            <Typography variant="body2">Backorder</Typography>
            <Typography variant="body2">Received</Typography>
          </StockWrapper>
        </TableHead>

        {/* Line items table */}
        <ProductItemContainer>
          {selectedOrderData &&
            selectedOrderData.lineItems &&
            Object.values(
              selectedOrderData.lineItems as unknown as StockInLineItem[]
            ).map((item, index) => {
              const lineItems =
                selectedOrderData.lineItems as unknown as StockInLineItem[];
              return (
                <DetailsTableItem
                  item={item}
                  priceMeta={
                    selectedOrderData.priceMeta as unknown as Record<
                      string,
                      PriceMeta
                    >
                  }
                  key={index}
                  disableCheck={
                    lineItems.length === 1 && lineItems[0].quantity === 1
                  }
                />
              );
            })}
        </ProductItemContainer>
        <TotalContainer>
          TOTAL:
          <TotalQuantity>{selectedOrderData.quantity}</TotalQuantity>
        </TotalContainer>
      </ProductsTable>
      <RemarkWrapper>
        <TextField
          label="Remark"
          variant="outlined"
          value={selectedOrderData.remark}
          disabled={selectedOrderData?.status === 'CONFIRMED'}
          onChange={(e) => {
            if (selectedOrderData?.priceMeta)
              setSelectedOrderData({
                ...selectedOrderData,
                remark: e.target.value,
              });
          }}
        />
      </RemarkWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ProductsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100svh - 750px);
  overflow-y: auto;
`;

const TableHead = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #dfe3e8;
`;

const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: flex-end;
  width: 100%;
  max-width: 300px;
  justify-content: space-around;
`;

const TotalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #212b36;
  margin-top: 16px;
`;

const TotalQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 80px;
  height: 50px;
  background: #f4f6f8;
  border-radius: 8px 0px 0px 8px;
  background: #454f5b;
  color: white;
  margin-left: 10px;
`;

const RemarkWrapper = styled.div`
  padding: 16px;
  position: sticky;
  top: 100%;
`;

export default StockInOrderDetails;
