import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Divider, Grid } from '@mui/material';
import { CheckAppointmentsRes } from '@tyrio/dto';
import { cartIcons } from '@tyrio/ui-library';
import { WmsIcon } from '@tyrio/wms-ui-library';
import moment from 'moment';
import { BorderedBox } from '../BorderedBox';
import { Services } from '../services-list/Services';
import { DashedDivider } from './DashedDivider';
import { Item } from './Item';

interface AppointmentPopoverProps {
  appointment: CheckAppointmentsRes;
  onMove?: () => void;
  onSwitch?: () => void;
  onClose?: () => void;
}

export const AppointmentPopover = (props: AppointmentPopoverProps) => {
  const { appointment, onClose, onMove, onSwitch } = props;

  return (
    <Container>
      <Header>
        <IconTextWrapper>
          <WmsIcon
            icon={cartIcons.appointment}
            sx={{
              color: '#1976D2',
              width: '24px',
              height: '24px',
            }}
            viewBox={'0 0 32 32'}
            stroke={'#1976D2'}
          />
          <HeaderText>Appointment details</HeaderText>
        </IconTextWrapper>
        {onClose && <CloseIcon onClick={onClose} />}
      </Header>
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Item
              header
              label="Date"
              text={moment(appointment.appointmentDateFrom).format(
                'DD.MM.YYYY'
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Item
              header
              label="Time"
              text={`${moment(appointment.appointmentDateFrom).format(
                'HH:mm'
              )} - ${moment(appointment.expectedDurationTime).format('HH:mm')}`}
            />
          </Grid>
          <Grid item xs={2}>
            <Item
              header
              label="Box"
              text={`${appointment.reservedBox.meta.subzoneName?.replace(
                'BOX',
                ''
              )}`}
            />
          </Grid>
        </Grid>
        <DashedDivider />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Item
              label="Vehicle"
              text={`${appointment.vehicleInfo.brand} ${appointment.vehicleInfo.model}`}
            />
          </Grid>
          <Grid item xs={5}>
            <Item
              label="License plate"
              text={`${appointment.vehicleInfo.licensePlateNumber}`}
            />
          </Grid>
        </Grid>
        <DashedDivider />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Item
              label="Customer"
              text={`${appointment.customer.firstName} ${appointment.customer.lastName}`}
            />
          </Grid>
          <Grid item xs={5}>
            <Item
              label="Mobile phone"
              text={`${appointment.customer.phoneNumberDetails}`}
            />
          </Grid>
        </Grid>
        <DashedDivider />
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Item label="CO Reference" text={`${appointment.orderReference}`} />
          </Grid>
          <Grid item xs={5}>
            <Item label="WO Reference" text={`${appointment.woReference}`} />
          </Grid>
        </Grid>
        <DashedDivider />
        <BorderedBox>
          <Services
            category={appointment.serviceType.name}
            services={appointment.services}
          />
        </BorderedBox>
        {appointment.remark && (
          <Grid>
            <Alert severity="info">{appointment.remark}</Alert>
          </Grid>
        )}
        <Divider />
        <CreatedText>{`Created by: ${appointment.createdBy} - ${moment(
          appointment.createdAt
        ).format('DD.MM.YYYY')} - ${moment(appointment.createdAt).format(
          'HH:mm:ss'
        )}`}</CreatedText>
        <Grid>{onMove && <Button onClick={onMove}>MOVE</Button>}</Grid>
        <Grid>{onSwitch && <Button onClick={onSwitch}>SWITCH</Button>}</Grid>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.span`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ContentContainer = styled.span`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`;

const IconTextWrapper = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`;

const HeaderText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  margin-left: 15px;
`;

const CreatedText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
`;
