import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CircularProgress, TextField } from '@mui/material';

import api from '@tyrio/api-factory';
import SourceContainer from '@tyrio/forms';
import { ClientPrintersApi, DBClientApi, PrintersApi } from '@tyrio/dto';
import { LoaderWrapper } from '../../components/PageTemplate/PageTemplate';

import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { getAutocompleteSelectChip } from '../../features/form-helper';

import { useAuth } from '../../context/AuthContext';
import { isEmpty } from 'lodash';

const Printers = () => {
  const { t } = useTranslation();
  const { user, refetchUser, userLoading } = useAuth();
  const [apiKey, setApiKey] = useState<string>(
    user?.client?.printNodeApiKey ?? ''
  );

  const clientsPrinters = !isEmpty(user?.client?.printers)
    ? JSON.parse(user?.client?.printers as string)
    : {};

  const generateDefaultValues = useCallback(() => {
    return {
      a4Documents:
        clientsPrinters &&
        clientsPrinters.a4Documents &&
        clientsPrinters.a4Documents.length > 0
          ? clientsPrinters.a4Documents
          : [],
      posReceipts:
        clientsPrinters &&
        clientsPrinters.posReceipts &&
        clientsPrinters.posReceipts.length > 0
          ? clientsPrinters.posReceipts
          : [],
      thermalLabels:
        clientsPrinters &&
        clientsPrinters.thermalLabels &&
        clientsPrinters.thermalLabels.length > 0
          ? clientsPrinters.thermalLabels
          : [],
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,
  });

  const { data: printersData, isFetching: isPrintersDataFetching } = useQuery(
    ['printers'],
    () => api.fetch<PrintersApi['list']>('get_printers'),
    { enabled: !!user?.client?.printNodeApiKey }
  );

  const printers = useMemo(() => {
    if (user?.client?.printNodeApiKey) {
      return printersData?.map((printer) => {
        return {
          name: printer.name,
          id: printer.id.toString(),
          key: `${printer.name}-${printer.id}`,
        };
      });
    } else return [];
  }, [printersData, user?.client]);

  // Update clients print node api key
  const updateClientPrintNodeApi = useMutation(
    (client: DBClientApi['updateOne']['request']) =>
      api.fetch<DBClientApi['updateOne']>('update_client', {
        ...client,
      }),
    {
      mutationKey: 'update_client',
      onSuccess: () => {
        updateClientsPrinters.mutate({
          a4Documents: [],
          posReceipts: [],
          thermalLabels: [],
        });
        refetchUser();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  const updateClientsPrinters = useMutation(
    (data: ClientPrintersApi['create']['request']) =>
      api.fetch<ClientPrintersApi['create']>('set_client_printers', {
        ...data,
      }),
    {
      mutationKey: 'set_client_printers',
      onSuccess: () => {
        reset();
        refetchUser();
        ToastHelper.showToast(
          'Default client printer data',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  const onSubmit = (data: ClientPrintersApi['create']['requestBody']) => {
    const payload: ClientPrintersApi['create']['requestBody'] = {
      a4Documents: data.a4Documents,
      posReceipts: data.posReceipts,
      thermalLabels: data.thermalLabels,
    };

    if (apiKey !== user?.client?.printNodeApiKey) {
      updateClientPrintNodeApi.mutate({
        id: user?.client?.id,
        printNodeApiKey: apiKey,
      });
    } else {
      updateClientsPrinters.mutate(payload);
    }
  };

  return (
    <PrintersLayout>
      <TitleWrapper>Printer Settings</TitleWrapper>
      <Divider />
      {!user?.client && userLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <>
          <IntegrationsWrapper>
            <Subtitle>Integrations:</Subtitle>
            <ApiKeyWrapper style={{ marginTop: '12px' }}>
              <TextField
                label="API Key"
                sx={{ width: '35%' }}
                onChange={({ target: { value } }) => {
                  setApiKey(value);
                }}
                type="text"
                value={apiKey}
              />
            </ApiKeyWrapper>
            <Note style={{ fontSize: '14px', marginTop: '4px' }}>
              <span style={{ color: 'red', marginRight: '2px' }}>*</span>
              To get your Print node api key, click{' '}
              <a
                href="https://api.printnode.com/app/apikeys"
                target="_blank"
                rel={'noreferrer'}
              >
                here
              </a>
              .
            </Note>
          </IntegrationsWrapper>
          {isPrintersDataFetching ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : printers && printers.length > 0 ? (
            <form style={{ padding: '16px' }}>
              <Subtitle style={{ marginBottom: '45px' }}>
                Choose default printers
              </Subtitle>
              <PrintersSelect>
                <Subtitle>A4 Documents</Subtitle>
                <Note>size: 210 x 297 mm</Note>
                {getAutocompleteSelectChip(
                  t('Select printers'),
                  'a4Documents',
                  () => true,
                  errors,
                  control,
                  { lg: 8, xs: 12 },
                  printers,
                  (opt: { id: string; name: string; key: string }) => opt.name,
                  false,
                  (opt, value) => opt.id === value.id,
                  (props, opt) => (
                    <li {...props} key={opt.id}>
                      {opt.name}
                    </li>
                  )
                )}

                <Divider style={{ marginTop: '24px', marginBottom: '32px' }} />
              </PrintersSelect>

              <PrintersSelect>
                <Subtitle>POS receipts</Subtitle>
                <Note>size: 80mm</Note>
                {getAutocompleteSelectChip(
                  t('Select printers'),
                  'posReceipts',
                  () => true,
                  errors,
                  control,
                  { lg: 8, xs: 12 },
                  printers,
                  (opt) => opt.name,
                  false,
                  (opt, value) => opt.id === value.id,
                  (props, opt) => (
                    <li {...props} key={opt.id}>
                      {opt.name}
                    </li>
                  )
                )}
                <Divider style={{ marginTop: '24px', marginBottom: '32px' }} />
              </PrintersSelect>

              <PrintersSelect>
                <Subtitle>Thermal labels</Subtitle>
                <Note>size: 102mm</Note>
                {getAutocompleteSelectChip(
                  t('Select printers'),
                  'thermalLabels',
                  () => true,
                  errors,
                  control,
                  { lg: 8, xs: 12 },
                  printers,
                  (opt) => opt.name,
                  false,
                  (opt, value) => opt.id === value.id,
                  (props, opt) => (
                    <li {...props} key={opt.id}>
                      {opt.name}
                    </li>
                  )
                )}

                <Divider style={{ marginTop: '24px', marginBottom: '32px' }} />
              </PrintersSelect>
            </form>
          ) : (
            <p style={{ padding: '16px' }}>
              {user?.client?.printNodeApiKey
                ? `You don't have printers on PrintNode dashboard`
                : 'Add API Key from Print node dashboard'}
            </p>
          )}
        </>
      )}

      <SourceContainer
        data={{
          data_source: 'source',
          created: 'created',
          last_edited: 'edited',
        }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset()}
        disabledDelete
        customStyle={{
          position: 'unset',
          marginBottom: '16px',
        }}
      />
    </PrintersLayout>
  );
};

const PrintersLayout = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: relative;
`;

const TitleWrapper = styled.div`
  color: #212b36;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.3px;
  padding: 16px;
`;
const IntegrationsWrapper = styled.div`
  padding: 16px;
`;

const Subtitle = styled.p`
  color: #212b36;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin: 0;
`;

const Note = styled.p`
  color: #919eab;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
  margin: 0 0 12px 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfe3e8;
`;
const PrintersSelect = styled.div`
  display: flex;
  flex-direction: column;
`;
const ApiKeyWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export default Printers;
