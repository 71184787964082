/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { Drawer, Grid, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import x from '../../assets/x-icon.svg';
import { useForm } from 'react-hook-form';
import {
  CategoryDropdownInput,
  MultiselectChipInput,
  SubcategoryDropdownInput,
  HierarchicalSelect,
} from '@tyrio/ui-library';
import Button from '@mui/material/Button/Button';
import {
  BRANDS,
  BRANDS_LIST,
  BUDGET_BRANDS,
  ECONOMY_BRANDS,
  MID_BRANDS,
  PREMIUM_BRANDS,
  SEASON_DROPDOWN_DATA,
  SPECIAL_MARKING_TYPE_DROPDOWN_DATA,
  WHEEL_POSITIONS_DROPDOWN_DATA,
} from '@tyrio/shared-vars';
import qs from 'qs';
import { InputOption, PosApi } from '@tyrio/dto';

interface FilterSidebarProps {
  isOpen: boolean;
  setOpen: (e: boolean) => void;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
  isPos?: boolean;
  data?: PosApi['getFilters']['response'];
}

const SidebarInternal = ({
  isOpen,
  setOpen,
  filterValues,
  setFilterValue,
  isPos = false,
  data = {},
}: FilterSidebarProps) => {
  const isPosFilters = isPos && data;
  const posBrands = isPosFilters
    ? Object.keys(BRANDS).filter((item) =>
        data['brands']?.includes(item as string & InputOption)
      )
    : [];

  const getBrandsByClass = (classNumber: number) => {
    return posBrands
      .filter((item) => BRANDS[item].brandClass === classNumber)
      .map((item) => BRANDS[item].name);
  };
  const brandClassesOptions = {
    PREMIUM: isPosFilters ? getBrandsByClass(1).sort() : PREMIUM_BRANDS.sort(),
    MID: isPosFilters ? getBrandsByClass(2).sort() : MID_BRANDS.sort(),
    ECONOMY: isPosFilters ? getBrandsByClass(3).sort() : ECONOMY_BRANDS.sort(),
    BUDGET: isPosFilters ? getBrandsByClass(4).sort() : BUDGET_BRANDS.sort(),
  };

  const getFilterValuesFromInitial = useCallback(() => {
    const data = {
      category: filterValues?.['categoryId'],
      subcategory: filterValues?.['subcategoryId'],
      season: filterValues?.['season'],
      brand: filterValues?.['brand'] as string[],
      wheelPosition: filterValues?.['wheelPosition'],
      specialMarking: filterValues?.['specialMarking'],
    };
    return data as any;
  }, [filterValues]);

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: getFilterValuesFromInitial(),
  });

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(false);
    };

  const onSubmit = ({
    category,
    subcategory,
    ...data
  }: Record<string, any>) => {
    const categoryId = category?.id ?? category;
    if (isPos) {
      setFilterValue({
        brand: data['brand'] as string[],
        season: data['season'],
        subcategoryId: subcategory?.value ?? subcategory,
        categoryId,
        specialMarking: data['specialMarking'],
        wheelPosition: data['wheelPosition'],
      });
    } else {
      setFilterValue({ ...data, categoryId });
    }
    setOpen(false);
  };

  if (!isOpen) return null;

  const isDataHere = (path: string) => {
    if (isPos) {
      if (data[path]?.length > 0) return true;
      else return false;
    } else return true;
  };

  const categories = isPosFilters ? data['categories'] : [];

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={toggleDrawer()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Header>
            <Title>Filter</Title>
            <CloseButton onClick={() => setOpen(false)}>
              <img src={x} alt="" />
            </CloseButton>
          </Header>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isPos ? (
                <CategoryDropdownInput
                  label="Category"
                  id="category"
                  control={control}
                />
              ) : (
                <TextField
                  label="Category"
                  value={categories?.length > 0 ? categories[0] : ''}
                  disabled
                  fullWidth
                />
              )}
            </Grid>
            {isPos && data['subcategories'] && (
              <Grid item xs={12}>
                <SubcategoryDropdownInput
                  label="Subcategory"
                  id="subcategory"
                  control={control}
                  subcategories={data['subcategories'] as InputOption[]}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <MultiselectChipInput
                title="Seasons"
                control={control}
                id="season"
                data={
                  isPosFilters
                    ? (data['seasons'] as string[])
                    : SEASON_DROPDOWN_DATA
                }
              />
            </Grid>
            {isDataHere('brands') && (
              <Grid item xs={12}>
                {isPosFilters ? (
                  <HierarchicalSelect
                    control={control}
                    title="Brands"
                    id="brand"
                    data={brandClassesOptions}
                  />
                ) : (
                  <MultiselectChipInput
                    title="Brands"
                    control={control}
                    id="brand"
                    data={BRANDS_LIST}
                  />
                )}
              </Grid>
            )}

            {isDataHere('wheelPositions') && (
              <Grid item xs={12}>
                <MultiselectChipInput
                  title="Wheel positions"
                  control={control}
                  id="wheelPosition"
                  disabled={isPos && !data['wheelPositions']?.length}
                  data={
                    isPosFilters
                      ? (data['wheelPositions'] as string[])
                      : WHEEL_POSITIONS_DROPDOWN_DATA
                  }
                />
              </Grid>
            )}
            {isDataHere('specialMarkings') && (
              <Grid item xs={12}>
                <MultiselectChipInput
                  title="Special markings"
                  control={control}
                  id="specialMarking"
                  disabled={isPos && !data['specialMarkings']?.length}
                  data={
                    isPosFilters
                      ? (data['specialMarkings'] as string[])
                      : SPECIAL_MARKING_TYPE_DROPDOWN_DATA
                  }
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  reset();
                  setFilterValue({
                    ...filterValues,
                    categoryId: isPos ? filterValues['categoryId'] : undefined,
                    subcategoryId: undefined,
                    season: undefined,
                    brand: undefined,
                    wheelPosition: undefined,
                    specialMarking: undefined,
                  });

                  setOpen(false);
                }}
                style={{ border: '1px solid #1B2130' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" type="submit">
                Filter
              </Button>
            </Grid>
          </Grid>
        </Wrapper>
      </form>
    </Drawer>
  );
};

const FilterSidebar = ({
  isOpen,
  setOpen,
  filterValues,
  setFilterValue,
  isPos,
  data,
}: FilterSidebarProps) => {
  if (!isOpen) return null;
  return (
    <SidebarInternal
      isOpen={isOpen}
      setOpen={setOpen}
      filterValues={filterValues}
      setFilterValue={setFilterValue}
      isPos={isPos}
      data={data}
    />
  );
};

export const Wrapper = styled.div`
  height: 100%;
  background: white;
  border-radius: 22px;
  padding: 20px;
  margin-right: 10px;
  max-width: 80vw;
  width: 425px;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export default FilterSidebar;
