import styled from '@emotion/styled/macro';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { DBStockCount } from '@prisma/client';
import _, { startCase } from 'lodash';
import { useContext, useState } from 'react';
import { StockCountContext } from '../../../context/StockCountContext';
import { getStockCountStatusColor } from '../../purchase-orders/helpers/generate-color';
import moment from 'moment';
import { DBBranchResponse } from '@tyrio/dto';
import { useStockCountHelper } from '../helpers/helper';

interface StockCountTableProps {
  data: DBStockCount[] | undefined;
  branches: DBBranchResponse[];
}

const StockCountTable = ({ data, branches }: StockCountTableProps) => {
  const { calculateQty } = useStockCountHelper();

  const [isOrderDesc, setIsOrderDesc] = useState(false);

  const { setSelectedStockCount, setFilters, filters } =
    useContext(StockCountContext);

  const openOrderDetails = (stockCountId: string) => {
    setFilters({
      ...filters,
      selected: stockCountId,
    });
    setSelectedStockCount(stockCountId);
  };

  const handleOrderSort = () => {
    setIsOrderDesc((prevState) => !prevState);
    return (
      data &&
      data.sort((a, b) => {
        if (a.docNumber && b.docNumber) {
          return !isOrderDesc
            ? a.docNumber < b.docNumber
              ? 1
              : -1
            : a.docNumber > b.docNumber
            ? 1
            : -1;
        } else return 0;
      })
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '8px',
        borderRadius: 0,
      }}
    >
      <StyledTable aria-label="simple table">
        <StyledTableHead>
          <StyledTableRowHeader>
            <CellWithSort>Status</CellWithSort>
            <Tooltip
              title={
                isOrderDesc
                  ? 'Click to sort ascending'
                  : 'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <StyledTableHeaderCell>
                <TableSortLabel
                  onClick={handleOrderSort}
                  IconComponent={UnfoldMoreIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                >
                  Number
                </TableSortLabel>
              </StyledTableHeaderCell>
            </Tooltip>
            <StyledTableHeaderCell>Scheduled</StyledTableHeaderCell>
            <StyledTableHeaderCell>Branch</StyledTableHeaderCell>
            <StyledTableHeaderCell>Type</StyledTableHeaderCell>
            <StyledTableHeaderCell>Finished</StyledTableHeaderCell>
            <StyledTableHeaderCell>Remark</StyledTableHeaderCell>
            <StyledTableHeaderCell>
              <QuantityHeaderWrapper>Expected</QuantityHeaderWrapper>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>
              <QuantityHeaderWrapper>Counted</QuantityHeaderWrapper>
            </StyledTableHeaderCell>
            <StyledTableHeaderCell>
              <QuantityHeaderWrapper>Difference</QuantityHeaderWrapper>
            </StyledTableHeaderCell>
          </StyledTableRowHeader>
        </StyledTableHead>
        <TableBody>
          {data &&
            data.map((stockCount: DBStockCount) => {
              const branch = () => {
                return branches?.find(
                  (item) => item.id === stockCount.branchId
                );
              };

              const expected = calculateQty(stockCount.expected);
              const scanned = calculateQty(stockCount.scanned);

              const diff = scanned - expected;

              const getScannedColor = () => {
                if (scanned === 0) return '#212B36';

                if (expected === scanned) return '#38A169';

                if (expected > scanned) return '#FFCC4D';
                else return '#DB1E1E';
              };

              const getDiffColor = () => {
                if (diff < 0) return '#DB1E1E';

                return '#212B36';
              };

              const isBolded = () => {
                return scanned !== 0 || diff < 0;
              };

              return (
                <StyledTableRow
                  sx={{
                    background: '#fff',
                    '&:hover': {
                      background: '#F9FAFB',
                      borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
                      cursor: 'pointer',
                    },
                    position: 'relative',
                  }}
                  onClick={() => openOrderDetails(stockCount.id)}
                  key={stockCount.id}
                >
                  <StyledTableCell
                    style={{ textAlign: 'center', width: '190px' }}
                  >
                    <CenterStatus>
                      <OrderStatus
                        color={getStockCountStatusColor[stockCount.status]}
                      >
                        {startCase(stockCount.status)}
                      </OrderStatus>
                    </CenterStatus>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '120px' }}>
                    {stockCount.docNumber}
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '140px' }}>
                    {moment(stockCount.scheduledDate).format('DD.MM.YYYY')}
                    <CellDetails>
                      {moment(stockCount.scheduledDate).format('HH:mm')}
                    </CellDetails>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: '170px' }}>
                    {branch()?.branchName}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '180px' }}>
                    {stockCount.countType}
                  </StyledTableCell>

                  {!_.isNull(stockCount.completedDate) ? (
                    <StyledTableCell style={{ width: '150px' }}>
                      {moment(stockCount.completedDate).format('DD.MM.YYYY')}
                      <CellDetails>
                        {moment(stockCount.completedDate).format('HH:mm')}
                      </CellDetails>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell></StyledTableCell>
                  )}

                  <StyledTableCell style={{ width: '150px' }}>
                    {stockCount.description}
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      width: '94px',
                      paddingRight: 0,
                      paddingLeft: 0,
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}
                  >
                    <QuantityPopoverWrapper>
                      <QuantityTypography>{expected}</QuantityTypography>
                    </QuantityPopoverWrapper>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '94px', paddingRight: 0, paddingLeft: 0 }}
                  >
                    <QuantityPopoverWrapper>
                      <QuantityTypography
                        sx={{
                          fontWeight: isBolded() ? 600 : 400,
                          color: getScannedColor(),
                        }}
                      >
                        {scanned}
                      </QuantityTypography>
                    </QuantityPopoverWrapper>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ width: '94px', paddingRight: 0, paddingLeft: 0 }}
                  >
                    <QuantityPopoverWrapper>
                      <QuantityTypography
                        sx={{
                          color: getDiffColor(),
                          fontWeight: isBolded() ? 600 : 400,
                        }}
                      >
                        {diff}
                      </QuantityTypography>
                    </QuantityPopoverWrapper>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  white-space: nowrap;
`;

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledTableRowHeader = styled(TableRow)`
  background: #ecf0f4;
`;

export const OrderStatus = styled.div<{
  color: string;
}>`
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 24px;
  background: ${(prop) => prop.color};
`;

const StyledTableRow = styled(TableRow)`
  height: 60px;
`;

const CellWithSort = styled(TableCell)`
  text-align: center;
`;

const CellDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const CenterStatus = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

const StyledTableCell = styled(TableCell)<{
  opacity?: boolean;
}>`
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: Barlow;
  text-align: start;
  opacity: 1;
`;

const QuantityPopoverWrapper = styled.div`
  background: #f4f6f8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 56px;
`;

const StyledTableHeaderCell = styled(TableCell)`
  padding: 0;
  text-align: start;
  padding-left: 8px;
`;

const QuantityHeaderWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 16px;
`;

const QuantityTypography = styled(Typography)`
  font-size: '16px';
  font-family: 'Barlow';
`;

export default StockCountTable;
