import styled from '@emotion/styled';
import { Button, Divider } from '@mui/material';
import { PosCartContext } from '../../../../../context/PosCartContext';
import { useContext } from 'react';
import { Countdown } from '../../../../../components/Timer/Countdown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface InfoFooterProps {
  text1: string;
  text2: string;
  onCancel: () => void;
  onSubmit: () => void;
  shouldShowAlert?: boolean;
  shouldShowDivider?: boolean;
  hasErrors?: boolean;
  disableCancel?: boolean;
  disableSubmit?: boolean;
  leftButtonStyle?: { height: string; width: string };
  rightButtonStyle?: { height: string; width: string };
  showBackIcon?: boolean;
}

export const InfoFooter = ({
  text1,
  text2,
  onCancel,
  onSubmit,
  shouldShowAlert = false,
  shouldShowDivider = true,
  disableCancel = false,
  disableSubmit = false,
  leftButtonStyle = { height: '60px', width: '200px' },
  rightButtonStyle = { height: '60px', width: '200px' },
  showBackIcon = false,
}: InfoFooterProps) => {
  const { timer } = useContext(PosCartContext);
  const displayTime = timer?.getDisplayTime();
  const { minutes, seconds } = displayTime ?? { minutes: 0, seconds: 0 };

  return (
    <Wrapper>
      {shouldShowDivider && (
        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: '2px',
            margin: '24px 0 24px 0',
            borderColor: '#E6E8F0',
          }}
        />
      )}

      {shouldShowAlert && (
        <div
          style={{
            marginBottom: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '70%' }}>
            <Countdown cart={true} minutes={minutes} seconds={seconds} />
          </div>
        </div>
      )}

      <ButtonsWrapper>
        <Button
          variant="outlined"
          color="info"
          sx={leftButtonStyle}
          onClick={onCancel}
          disableElevation
          startIcon={showBackIcon ? <ArrowBackIcon /> : undefined}
          disabled={disableCancel}
        >
          {text1}
        </Button>

        <Button
          variant="contained"
          color="info"
          sx={rightButtonStyle}
          onClick={onSubmit}
          disableElevation
          disabled={disableSubmit}
        >
          {text2}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: sticky;
  top: 100%;
  padding-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;
