import { Divider } from '@mui/material';
import {
  BranchesStockResponse,
  SupplierLineItems,
  SupplierStockResponse,
} from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { StockItemDetails } from './StockItemDetails';
import { StockItemsHeader } from './StockItemsHeader';
import { useContext } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { isDotOlderThanThreeYears } from '../../../stock-list/components/ProductDetails/helpers/dot-helper';

export const StockItems = ({
  data,
  index,
  stockType,
  extremeDates,
}: {
  data: BranchesStockResponse | SupplierStockResponse;
  stockType:
    | 'BRANCHES STOCK'
    | 'SUPPLIERS STOCK'
    | 'ON STOCK'
    | 'UPCOMING ON STOCK'
    | '';
  sale?: boolean;
  index?: number;
  extremeDates?: { min: string; max: string };
}) => {
  const { isSupplierNameVisible } = useContext(POSContext);
  return (
    <div>
      <StockItemsHeader
        branchName={
          (data as BranchesStockResponse)?.branchName ??
          (data as SupplierStockResponse)?.supplierName
        }
        deliveryDate={(data as SupplierStockResponse).deliveryDate ?? ''}
        visibleName={isSupplierNameVisible}
        index={index}
        showVisibility={stockType === 'SUPPLIERS STOCK'}
        isEarliestDeliveryDate={
          stockType === 'SUPPLIERS STOCK'
            ? !!(data?.deliveryDate?.split(' - ')[0] === extremeDates?.min)
            : false
        }
        isLongestDeliveryDate={
          stockType === 'SUPPLIERS STOCK'
            ? !!(data?.deliveryDate?.split(' - ')[0] === extremeDates?.max)
            : false
        }
      />
      <Divider />
      {!isEmpty(data.lineItems) &&
        data?.lineItems.map((item, index: number) => (
          <StockItemDetails
            data={data}
            reserved={item.reserved}
            available={item.quantity - item.reserved}
            dot={item.dot}
            sale={item.isOnSale ?? false}
            price={Number(item.price ?? 0) ?? 0}
            cashPrice={item.cashPrice}
            oldPrice={item?.oldPrice}
            discount={item?.discount}
            key={index}
            retailPrice={(item as SupplierLineItems)?.retailPrice}
            stockType={stockType}
            deliveryDate={data.deliveryDate ?? ''}
            inclusiveVatPrice={item.inclusiveVatPrice ?? item.price ?? 0}
            vatCashPrice={item.vatCashPrice ?? item.cashPrice ?? 0}
            purchasePrice={item.purchasePrice ?? 0}
            chipColor={
              isDotOlderThanThreeYears(item.dot)
                ? 'error'
                : item.dot === 'XXXX'
                ? 'primary'
                : 'success'
            }
          />
        ))}
    </div>
  );
};
