import styled from '@emotion/styled';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { tyrioUI } from '@tyrio/ui-library';
import { PosRowCell } from '../../pos/components/Table/Row';
import { ProductItem } from './ProductsItem';
import { useContext } from 'react';
import { StockCountContext } from '../../../context/StockCountContext';
import SidebarFiltersList from './SidebarFilters';
import _ from 'lodash';
import { LoaderWrapper } from '../../../components/PageTemplate/PageTemplate';
import { useGetStockCountById } from '../queries/get-stock-count-by-id';

interface PosTableProps {
  onOpenSidebarFilters: () => void;
}

export const StockCountProducts = ({ onOpenSidebarFilters }: PosTableProps) => {
  const { showDifferences, filters } = useContext(StockCountContext);

  const { data: selectedStockCountData, isLoading } = useGetStockCountById(
    filters['selected']?.toString() ?? undefined
  );

  const products = Object.entries(selectedStockCountData?.products ?? {});

  const data = Array.from(
    new Map(
      products.map((item) => {
        const baseEan = item[0].split('_')[0];
        return [baseEan, { key: baseEan, value: item[1] }];
      })
    ).values()
  );

  return (
    <TableWrapper isDefault={true}>
      <Table>
        <PosTableHead>
          <PosTableHeadRow>
            <PosTableCell>
              <SidebarFilterWrapper>
                <SidebarFiltersList onOpen={onOpenSidebarFilters} />
              </SidebarFilterWrapper>
              <PosTableHeadWrapper showDifferences={showDifferences}>
                {!showDifferences && (
                  <PosTableHeadItem>Expected</PosTableHeadItem>
                )}
                {!showDifferences && (
                  <PosTableHeadItem>Counted</PosTableHeadItem>
                )}
                <PosTableHeadItem>Difference</PosTableHeadItem>
              </PosTableHeadWrapper>
            </PosTableCell>
          </PosTableHeadRow>
        </PosTableHead>
        {isLoading ? (
          <LoaderWrapper style={{ marginTop: '20px' }}>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <TableBody sx={{ padding: 0 }} id="hide">
            <PosRowCell>
              <Grid>
                <Grid>
                  {data.map((item, index) => {
                    const formattedData = {};

                    Object.keys(selectedStockCountData?.products ?? {}).forEach(
                      (key) => {
                        if (key.startsWith(item.key)) {
                          const productData = _.get(
                            selectedStockCountData?.products,
                            key
                          );
                          const newKey = item.key;

                          Object.keys(productData?.locations ?? {}).forEach(
                            (locationKey) => {
                              const newLocationKey = `${locationKey}_${newKey}`;
                              _.set(
                                formattedData,
                                newLocationKey,
                                productData?.locations[locationKey]
                              );
                            }
                          );
                        }
                      }
                    );

                    const locations = Object.values(formattedData);

                    const expected = _.sumBy(_.values(locations), 'expected');
                    const scanned = _.sumBy(_.values(locations), 'scanned');

                    return (
                      <ProductItem
                        key={`${item.key}_${index}`}
                        product={item.value.product}
                        ean={item.value.ean}
                        isFavorite={false}
                        expected={expected}
                        scanned={scanned}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </PosRowCell>
          </TableBody>
        )}
      </Table>
    </TableWrapper>
  );
};

const PosTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PosTableCell = styled(TableCell)`
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
`;

export const SidebarFilterWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 140px);
`;

const PosTableHeadWrapper = styled.div<{
  showDifferences: boolean;
}>`
  display: flex;
  justify-content: space-around;
  max-width: ${({ showDifferences }) => (showDifferences ? '40px' : '210px')};
  width: 100%;
`;

const PosTableHeadRow = styled(TableRow)`
  background: #fff;
`;

const PosTableHeadItem = styled.span`
  color: ${tyrioUI.colors.black.B90};
  font-size: 14px;
`;

const TableWrapper = styled.div<{
  isDefault?: boolean;
}>`
  height: ${({ isDefault }) =>
    isDefault ? `calc(100svh - 110px)` : 'calc(100% - 60px)'};
  overflow-y: ${({ isDefault }) => (isDefault ? 'scroll' : 'unset')};
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;

  @media (max-width: 1630px) {
    height: ${({ isDefault }) => !isDefault && 'calc(100% - 120px)'};
  }
`;

export const Title = styled(Typography)`
  margin-left: 10px;
  font-weight: 500;
`;
