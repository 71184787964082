import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from '@mui/material';
import { DBBranchResponse, DBStockCountItemCountByStatus } from '@tyrio/dto';
import { backIcon, tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../../components/NoData/NoDataFound';
import { StockCountContext } from '../../../context/StockCountContext';
import { useBranchQuery } from '../../../hooks/api/useBranchQuery';
import { SearchInput } from '../../pos/components/SearchPOS/SearchInput';
import DatePeriodPicker from '../../transfer-orders/filter/TransferDatePicker';
import { AddStockCount } from '../components/Add';
import StockCountTable from '../components/Table';
import StatusTabs from '../components/Tabs';
import { useGetStatusCount } from '../queries/get-count-by-status';
import { useGetStockCounts } from '../queries/get-stock-counts';
import {
  BackButton,
  Container,
  ContentWrapper,
  HeaderText,
  HeaderWrapperTop,
} from './StockCountDetails';

export const StockCountList = () => {
  const { filters, setFilters } = useContext(StockCountContext);

  const { data: statusData, status: statusCountStatus } =
    useGetStatusCount(filters);

  const { data, status: sc_status } = useGetStockCounts(filters);

  const activateCurrentBranch = useCallback(() => {
    setFilters({
      status: 'all',
      page: 1 as unknown as string,
      pageSize: 10 as unknown as string,
      selected: undefined,
    });
  }, [setFilters]);

  const { data: branches, status } = useBranchQuery(() => {
    activateCurrentBranch();
  });

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const pageSize = _.toNumber(filters['pageSize']);

  const count = data?.count;

  const totalPageNumber = count
    ? count / pageSize - Math.floor(count / pageSize) === 0
      ? count / pageSize
      : Math.floor(count / pageSize) + 1
    : 1;

  return (
    <Container>
      <AddStockCount open={open} onClose={() => setOpen(false)} />
      <HeaderWrapperTop>
        <Grid>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Stock count list</HeaderText>
        </Grid>
        {
          <HeaderButtonsWrapper>
            <Button
              variant="outlined"
              color="info"
              size="small"
              endIcon={<AddIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              NEW STOCK COUNT
            </Button>
          </HeaderButtonsWrapper>
        }
      </HeaderWrapperTop>
      <Divider />
      <ContentWrapper>
        <Grid display="flex" gap={1.5}>
          <Grid width={'40%'}>
            <SearchInput
              filterQuery={filters}
              setFilterQuery={setFilters}
              options={[
                { value: 'docNumber', name: 'Doc number' },
                { value: 'storageLocation', name: 'Storage location' },
              ]}
            />
          </Grid>
          <Grid width={'30%'} marginTop={0.6}>
            {status === 'success' && (
              <FormControl fullWidth>
                <InputLabel>Branch</InputLabel>
                <Select
                  value={filters['branchId']}
                  label="Branch"
                  size="medium"
                  onChange={(e) => {
                    setFilters({ branchId: e.target.value });
                  }}
                >
                  {branches?.data.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid width={'30%'} marginTop={0.6}>
            <DatePeriodPicker
              isSidebarOpen={false}
              filters={filters}
              setFilters={setFilters}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
      {statusCountStatus === 'success' && (
        <StatusTabs
          countOrders={statusData as DBStockCountItemCountByStatus[]}
        />
      )}
      {sc_status === 'success' && data.data.length > 0 ? (
        <StockCountTable
          data={data.data}
          branches={branches?.data as unknown as DBBranchResponse[]}
        />
      ) : (
        <NoDataFound />
      )}
      {data && data?.data?.length > 0 && (
        <StyledBox>
          <Pagination
            count={totalPageNumber}
            onChange={(_e: ChangeEvent<unknown>, page: number) => {
              setFilters({ page: `${page}` });
            }}
            page={parseInt(filters['page'] as string, 10)}
            sx={{
              button: {
                '&.css-d3ksqy-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':
                  {
                    backgroundColor: `${tyrioUI.colors.lightBlue}`,
                    color: 'white',
                  },
              },
            }}
          />
          <FormController>
            <InputLabel size="small">Rows per page</InputLabel>
            <Select
              fullWidth
              size="small"
              value={filters['pageSize']}
              defaultValue={filters['pageSize']}
              label="Rows per page"
              onChange={(data) => setFilters({ pageSize: data.target.value })}
              style={{ height: '40px' }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormController>
        </StyledBox>
      )}
    </Container>
  );
};

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: center;
  padding: 16px;
  margin-top: 16px;
`;

const FormController = styled(FormControl)`
  position: absolute;
  right: 24px;
  width: 110px;
  padding: 16px;
  @media (max-width: 768px) {
    display: none;
  }
`;
