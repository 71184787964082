import * as z from "zod"
import { DBLanguage, DBUserRole } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBAdmin, RelatedDBAdminModel, CompleteDBRole, RelatedDBRoleModel, CompleteDBBranch, RelatedDBBranchModel, CompleteDBCustomerOrderItem, RelatedDBCustomerOrderItemModel, CompleteDBReservationSession, RelatedDBReservationSessionModel, CompleteDBReservationsItems, RelatedDBReservationsItemsModel, CompleteDBTransferOrderItem, RelatedDBTransferOrderItemModel, CompleteDBPosItemFavorite, RelatedDBPosItemFavoriteModel, CompleteDBAppointments, RelatedDBAppointmentsModel, CompleteDBDispatch, RelatedDBDispatchModel } from "./index"

export const DBUserModel = z.object({
  id: z.string(),
  firebaseId: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  mobilePhone: z.string(),
  birthday: z.string(),
  remark: z.string(),
  language: z.nativeEnum(DBLanguage),
  userRole: z.nativeEnum(DBUserRole),
  active: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  clientId: z.string().nullish(),
  adminId: z.string().nullish(),
  roleId: z.string(),
  mainBranchId: z.number().int().nullish(),
  secondaryIds: z.number().int().array(),
  currentBranchId: z.number().int().nullish(),
  businessPhone: z.string().nullish(),
  personalIdentificationNumber: z.string().nullish(),
  pin: z.string().nullish(),
  points: z.number().int().nullish(),
  erpId: z.string().nullish(),
})

export interface CompleteDBUser extends z.infer<typeof DBUserModel> {
  client?: CompleteDBClient | null
  admin?: CompleteDBAdmin | null
  role: CompleteDBRole
  mainBranch?: CompleteDBBranch | null
  secondaryBranches: CompleteDBBranch[]
  currentBranch?: CompleteDBBranch | null
  DBCustomerOrderItem: CompleteDBCustomerOrderItem[]
  carts: CompleteDBReservationSession[]
  cartItems: CompleteDBReservationsItems[]
  transferOrderItems: CompleteDBTransferOrderItem[]
  posFavourite: CompleteDBPosItemFavorite[]
  appointments: CompleteDBAppointments[]
  dispatchedOrders: CompleteDBDispatch[]
}

/**
 * RelatedDBUserModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBUserModel: z.ZodSchema<CompleteDBUser> = z.lazy(() => DBUserModel.extend({
  client: RelatedDBClientModel.nullish(),
  admin: RelatedDBAdminModel.nullish(),
  role: RelatedDBRoleModel,
  mainBranch: RelatedDBBranchModel.nullish(),
  secondaryBranches: RelatedDBBranchModel.array(),
  currentBranch: RelatedDBBranchModel.nullish(),
  DBCustomerOrderItem: RelatedDBCustomerOrderItemModel.array(),
  carts: RelatedDBReservationSessionModel.array(),
  cartItems: RelatedDBReservationsItemsModel.array(),
  transferOrderItems: RelatedDBTransferOrderItemModel.array(),
  posFavourite: RelatedDBPosItemFavoriteModel.array(),
  appointments: RelatedDBAppointmentsModel.array(),
  dispatchedOrders: RelatedDBDispatchModel.array(),
}))
