import { defaultEnv } from './default.api';
import { APIConfig } from './types';

export const environment: APIConfig = {
  ...defaultEnv,
  production: false,
  awsS3: {
    bucket: 'd-tyrio-storage-189d46b',
    key: 'AKIA3ZYSEBGHZC2QVDUJ',
    secret: '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',
  },
  rabbitmq: {
    queueName: 'd-importer',
    connectionUrl:
      'amqps://uyubflnm:kXB4rKyBYVKfzoCBh0Z6VA96s5QdFuTt@chimpanzee.rmq.cloudamqp.com/uyubflnm',
    // connectionUrl: 'amqp://api:t1r1op4sword@localhost',
    // config: {
    //   connectionOptions: {
    //     timeout: 30000,
    //     credentials: {
    //       mechanism: 'plain',
    //       username: 'ccaaepyi',
    //       password: 'lxIhQUW4EftFV1-Aj7j8u5wPQXcQ13iz',
    //       response: () => new Buffer(''),
    //     },
    //   },
    // },
  },
  redis: {
    url: 'redis://default:5WMurjWXD4V7cG8jxOh9s22GQ0EAJvvJ@redis-12999.c57.us-east-1-4.ec2.redns.redis-cloud.com:12999',
    host: 'redis-12999.c57.us-east-1-4.ec2.redns.redis-cloud.com',
    port: 12999,
    username: 'default',
    password: '5WMurjWXD4V7cG8jxOh9s22GQ0EAJvvJ',
  },
};
