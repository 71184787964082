import _ from 'lodash';
import { useFilter } from '../../../../../hooks/useFilter';
import { HeaderText } from '../../../steps/Search/SelectStepper';
import { Card, CardWrapper, Container, StyledCheckbox } from './BrandSelector';
import { useContext, useEffect, useMemo } from 'react';
import { POSContext } from '../../../../../context/POSContext';
import { useGetPosFilters } from '../query/get-filter';
import { InputOption } from '@tyrio/dto';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled/macro';
import { PosSearchContext } from '../SearchContext';

interface ColorGroupSelectorProps {
  label: string;
  options: string[];
}

export const ColorGroupSelector = ({
  label,
  options,
}: ColorGroupSelectorProps) => {
  const { ...filtersHook } = useFilter();

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const initialSelectedValues: string[] = get('color');

  let selectedValues = initialSelectedValues;

  const { filterValues, setFilterValue, shouldRefetch } =
    useContext(POSContext);
  const { setSupplierPage } = useContext(PosSearchContext);

  const { filtersPos, isLoading, refetch } = useGetPosFilters(filterValues);
  const colorsFromStock = useMemo(() => {
    if (isLoading) return [];
    return filtersPos['colors'];
  }, [filtersPos, isLoading]);

  const handleSelectValue = (isSelected: boolean, checkboxKey: string) => {
    if (isSelected) {
      selectedValues.push(checkboxKey.toLowerCase());
    } else
      selectedValues = selectedValues.filter(
        (el) => el.toLowerCase() !== checkboxKey.toLowerCase()
      );
    setFilterValue({
      ...filterValues,
      color: selectedValues,
    });

    setSupplierPage(0);
    // Scroll to the top of pos table
    const posTable = document.getElementById('pos-table');
    posTable?.scroll({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (shouldRefetch) refetch();
  }, [filterValues, refetch]);

  return (
    <Container>
      <HeaderText>{label}</HeaderText>

      {!isLoading ? (
        <CardWrapper
          style={{
            flexWrap: 'wrap',
            gap: '16px',
            justifyContent: 'flex-start',
          }}
        >
          {options.map((value, key) => {
            const isSelected = selectedValues.includes(value.toLowerCase());

            return (
              <Card
                onClick={() => {
                  if (
                    colorsFromStock?.includes(
                      value.toLowerCase() as string & InputOption
                    )
                  )
                    handleSelectValue(!isSelected, _.capitalize(value));
                }}
                selected={isSelected}
                key={key}
                width={180}
                style={{ fontSize: '20px' }}
                disabled={
                  !colorsFromStock?.includes(
                    value.toLowerCase() as string & InputOption
                  )
                }
              >
                <StyledCheckbox
                  type="checkbox"
                  key={`checkbox_${value}`}
                  checked={isSelected}
                  onChange={() => null}
                  style={{ marginRight: '10px' }}
                  disabled={
                    !colorsFromStock?.includes(
                      value.toLowerCase() as string & InputOption
                    )
                  }
                />
                {value}
              </Card>
            );
          })}
        </CardWrapper>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </Container>
  );
};

const LoaderWrapper = styled.div`
  margin-top: 32px;
`;
