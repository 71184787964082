import styled from '@emotion/styled';
import { Typography } from '@mui/material';

interface StockListHeaderProps {
  branchName: string;
  deliveryDate?: string;
  visibleName?: boolean;
  index?: number;
  showVisibility?: boolean;
  isEarliestDeliveryDate?: boolean;
  isLongestDeliveryDate?: boolean;
}

export const StockItemsHeader = ({
  branchName,
  deliveryDate,
  visibleName,
  index,
  showVisibility = false,
  isEarliestDeliveryDate = false,
  isLongestDeliveryDate = false,
}: StockListHeaderProps) => {
  return (
    <TitleWrapper>
      <StorageName>
        <Wrapper>
          <Typography variant="subtitle1" style={{ fontSize: '16px' }}>
            {showVisibility
              ? visibleName
                ? branchName
                : `Supplier ${index}`
              : branchName}
          </Typography>
          <Typography
            fontSize={'12px'}
            color={
              isEarliestDeliveryDate
                ? 'green'
                : isLongestDeliveryDate
                ? 'red'
                : 'primary'
            }
          >
            {deliveryDate}
          </Typography>
        </Wrapper>
      </StorageName>
      <StockWrapper>
        <StyledTypography
          variant="body2"
          style={{ textAlign: 'center', maxWidth: '80px', width: '100%' }}
        >
          Price
        </StyledTypography>
        <StyledTypography
          variant="body2"
          style={{ textAlign: 'center', maxWidth: '75px', width: '100%' }}
        >
          Cash
        </StyledTypography>
        <ValWrapper>
          <StyledTypography variant="body2">Reserved</StyledTypography>
          <StyledTypography variant="body2">Available</StyledTypography>
        </ValWrapper>
      </StockWrapper>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 24px;
  height: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StorageName = styled.div`
  width: calc(100% - 250px - 12px);
  padding-left: 12px;
`;

export const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 290px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const ValWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 160px;
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  max-width: 80px;
  width: 100%;
  text-align: center;
  color: #454f5b;
  font-style: normal;
  font-weight: 400;
`;

// color: '#DB1E1E';
// fontSize: 12;
// fontFamily: 'Barlow';
// fontWeight: '400';
// lineHeight: 16;
// letterSpacing: 0.3;
// wordWrap: 'break-word';
