import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { TYROO_CATALOG_RULESET } from './tyroo/catalog';
import { TYROO_PRICELIST_RULESET } from './tyroo/pricelist';
import { TYROO_STOCKLIST_RULESET } from './tyroo/stocklist';

export const TYROO_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: TYROO_CATALOG_RULESET,

  pricelistRuleset: TYROO_PRICELIST_RULESET,
  stockRuleset: TYROO_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'TYROO',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    width: {
      'A25': '225',
      '0350': '3.50',
      '0400': '4.00',
      ',': '.',
    },
    'rim offset': {
      ',': '.',
    },
    'rim colour': {
      '�': 'ss',
    },
    'rim width': {
      ',': '.',
    },
    'rim pitch circle 1': {
      ',': '.',
    },
    'rim inch': {
      ',': '.',
    },
    producer: {
      'Alcar,KPZ,MW,SR,OE': 'ALCAR',
      'Mefro/Kronprinz': 'KRONPRINZ',
      'BF-Goodrich': 'BFGOODRICH',
    },
    'image url': {
      ' ': '_',
    },
    'list designation 2': {
      ' (ben�tigt kein Label)': '',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['article information'].toUpperCase().includes(substr)
          ) &&
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['profile'].toUpperCase().includes(substr)
          ) &&
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['ID number producer'].toUpperCase().includes(substr)
          ) &&
          item['producer'] !== 'Original' &&
          item['profile'] &&
          item['profile'] !== '-' &&
          ((item['width'] && parseFloat(item['width']) !== 0) ||
            (item['rim width'] && parseFloat(item['rim width']) !== 0)) &&
          (item['inch'] || item['rim inch'])
      ),
      // .slice(1118, 1121),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['article information'].toUpperCase().includes(substr)
          ) &&
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['profile'].toUpperCase().includes(substr)
          ) &&
          !['DOT', 'DEMO', 'TEST'].some((substr) =>
            item['ID number producer'].toUpperCase().includes(substr)
          ) &&
          item['producer'] !== 'Original' &&
          item['profile'] &&
          item['profile'] !== '-' &&
          ((item['width'] && parseFloat(item['width']) !== 0) ||
            (item['rim width'] && parseFloat(item['rim width']) !== 0)) &&
          (item['inch'] || item['rim inch'])
      ),
      // .slice(1118, 1121),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const filteredItems = json.filter(
      (item) =>
        !['DOT', 'DEMO', 'TEST'].some((substr) =>
          item['article information'].toUpperCase().includes(substr)
        ) &&
        !['DOT', 'DEMO', 'TEST'].some((substr) =>
          item['profile'].toUpperCase().includes(substr)
        ) &&
        !['DOT', 'DEMO', 'TEST'].some((substr) =>
          item['ID number producer'].toUpperCase().includes(substr)
        ) &&
        item['producer'] !== 'Original' &&
        item['profile'] &&
        item['profile'] !== '-' &&
        ((item['width'] && parseFloat(item['width']) !== 0) ||
          (item['rim width'] && parseFloat(item['rim width']) !== 0)) &&
        (item['inch'] || item['rim inch'])
    );

    return handlePreprocessConversions(filteredItems, this.preprocessKeys);
  },
};
