import { BranchesStockResponse, SupplierStockResponse } from '@tyrio/dto';
import { DBService, DBServiceCategory } from '@prisma/client';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BadgeContent, POSContext } from './POSContext';
import { useGetBranchesStock } from '../features/pos/query/get-branches-stock';
import { useGetSuppliersStock } from '../features/pos/query/get-suppliers-stock';
import { useGetServices } from '../features/pos/query/get-services';
import { usePosCartData } from '../features/pos/steps/Cart/helpers/cart-data';

export interface IServiceItems {
  services: DBService[];
  recommended: (DBService & { serviceCategory: DBServiceCategory })[];
}
type PosDetailsContextInterface = {
  branchesStock: BranchesStockResponse[];
  setBranchesStock: (a: BranchesStockResponse[]) => void;
  suppliersStockItems: SupplierStockResponse[];
  setSupplierStockItems: (a: SupplierStockResponse[]) => void;
  servicesItems: IServiceItems;
  setServices: (a: IServiceItems) => void;
  upcomingStockItems: BranchesStockResponse[];
  setUpcomingOnStockItems: (a: BranchesStockResponse[]) => void;
  onStockItems: BranchesStockResponse[];
  setOnStockItems: (a: BranchesStockResponse[]) => void;
  isFetchingBranchStock: boolean;
  setIsFetchingBranchStock: (a: boolean) => void;
  isFetchingSupplierStock: boolean;
  setIsFetchingSupplierStock: (a: boolean) => void;
  searchService: string;
  setSearchService: (a: string) => void;
  badgeContent: BadgeContent;
  setBadgeContent: Dispatch<SetStateAction<BadgeContent>>;
};

export const POSDetailsContext =
  React.createContext<PosDetailsContextInterface>({
    branchesStock: [],
    setBranchesStock: () => null,
    suppliersStockItems: [],
    setSupplierStockItems: () => null,
    servicesItems: { services: [], recommended: [] },
    setServices: () => null,
    upcomingStockItems: [],
    setUpcomingOnStockItems: () => null,
    onStockItems: [],
    setOnStockItems: () => null,
    isFetchingSupplierStock: false,
    setIsFetchingSupplierStock: () => null,
    isFetchingBranchStock: false,
    setIsFetchingBranchStock: () => null,
    searchService: '',
    setSearchService: () => null,
    badgeContent: { BRANCHES: 0, SUPPLIERS: 0, CART: 0 },
    setBadgeContent: () => null,
  });

interface PosDetailsProviderProps {
  children: React.ReactNode;
}

export const PosDetailsProvider = ({ children }: PosDetailsProviderProps) => {
  const {
    selectedTableRow,
    selectedWarehouseId,
    shouldShowDot,
    filterValues,
    shouldRefetch,
  } = useContext(POSContext);
  const { modalData } = usePosCartData();
  const [searchService, setSearchService] = useState<string>('');
  const [isFetchingBranchStock, setIsFetchingBranchStock] =
    useState<boolean>(false);
  const [isFetchingSupplierStock, setIsFetchingSupplierStock] =
    useState<boolean>(false);
  const [onStockItems, setOnStockItems] = useState<BranchesStockResponse[]>([]);
  const [upcomingStockItems, setUpcomingOnStockItems] = useState<
    BranchesStockResponse[]
  >([]);
  const [branchesStock, setBranchesStock] = useState<BranchesStockResponse[]>(
    []
  );
  const [suppliersStockItems, setSupplierStockItems] = useState<
    SupplierStockResponse[]
  >([]);
  const [servicesItems, setServices] = useState<IServiceItems>({
    recommended: [],
    services: [],
  });
  const [badgeContent, setBadgeContent] = useState({
    BRANCHES: 0,
    SUPPLIERS: 0,
    CART: 0,
  });

  const {
    warehouseStock,
    upcomingStock,
    onStock,
    showLoader: showBranchesLoader,
  } = useGetBranchesStock(
    selectedTableRow?.product?.ean ?? '',
    selectedWarehouseId?.toString(),
    undefined,
    undefined,
    selectedTableRow?.stockType,
    shouldShowDot,
    filterValues['customer']?.toString() ?? 'default-customer',
    shouldRefetch
  );

  const {
    suppliersStock: supplierStockResponse,
    showLoader: showLoaderSupplier,
  } = useGetSuppliersStock(
    selectedTableRow?.product?.ean ?? '',
    'Price ascending',
    selectedWarehouseId?.toString() as string,
    filterValues['customer']?.toString() ?? 'default-customer',
    undefined,
    undefined
  );
  const { recommendedServices, filtredServices } = useGetServices(
    selectedTableRow?.product?.ean as string,
    selectedWarehouseId as number,
    '',
    modalData
  );

  useEffect(() => {
    setIsFetchingBranchStock(showBranchesLoader);
    setIsFetchingSupplierStock(showLoaderSupplier);
    if (
      !selectedTableRow &&
      !shouldRefetch &&
      !showBranchesLoader &&
      !showLoaderSupplier
    )
      return;
    setSupplierStockItems(supplierStockResponse);
    setBranchesStock(warehouseStock as BranchesStockResponse[]);
    setUpcomingOnStockItems(upcomingStock as BranchesStockResponse[]);
    setOnStockItems(onStock as BranchesStockResponse[]);
    setServices({
      recommended: recommendedServices ?? [],
      services: filtredServices ?? [],
    });
    if (shouldRefetch && warehouseStock && selectedTableRow) {
      let branchSum = 0;
      warehouseStock?.forEach((r) =>
        r?.lineItems?.forEach(
          (item) => (branchSum += item.quantity - item.reserved)
        )
      );
      let supplierSum = 0;
      supplierStockResponse?.forEach((r) =>
        r?.lineItems?.forEach(
          (item) => (supplierSum += item.quantity - item.reserved)
        )
      );
      setBadgeContent({
        ...badgeContent,
        BRANCHES: branchSum,
        SUPPLIERS: supplierSum,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTableRow, showBranchesLoader, showLoaderSupplier]);

  return (
    <POSDetailsContext.Provider
      value={{
        branchesStock,
        setBranchesStock,
        suppliersStockItems,
        setSupplierStockItems,
        servicesItems,
        setServices,
        upcomingStockItems,
        setUpcomingOnStockItems,
        onStockItems,
        setOnStockItems,
        isFetchingBranchStock,
        setIsFetchingBranchStock,
        isFetchingSupplierStock,
        setIsFetchingSupplierStock,
        searchService,
        setSearchService,
        badgeContent,
        setBadgeContent,
      }}
    >
      {children}
    </POSDetailsContext.Provider>
  );
};
