import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { TRule } from '../typings';
import { INTERCARS_PRICELIST_RULESET } from './intercars/pricelist';
import { INTERCARS_STOCKLIST_RULESET } from './intercars/stocklist';

export const INTERCARS_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: INTERCARS_PRICELIST_RULESET,
  stockRuleset: INTERCARS_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      dataSource: 'INTERCARS',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'INTERCARS',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {},
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        !item['description'].toLowerCase().includes('dot')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        !item['description'].toLowerCase().includes('dot')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const list = json.filter(
      (item) =>
        !item['description'].toLowerCase().includes('dot')
    );

    return handlePreprocessConversions(list, this.preprocessKeys);
  },
};
