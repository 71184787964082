import { TRule } from '../typings';
import { RawImport } from '@tyrio/dto';

// export function getIntercarsEAN(input: RawImport, _rule: TRule) {
//   let ean = '';

//   if (input['Manufacturer_Code']) {
//     ean =
//       '8019227' +
//       (input['Manufacturer_Code'].length === 7
//         ? input['Manufacturer_Code'].slice(0, -2)
//         : '0' + input['Manufacturer_Code'].slice(0, -2));

//     if (ean.length !== 12) {
//       console.log(
//         'Ean is not 12 digits - INTERCARS - ',
//         input['Manufacturer_Code']
//       );
//       ean = '';
//     } else {
//       let sum = 0;

//       // Iterate over each digit in the EAN
//       for (let i = 0; i < ean.length; i++) {
//         const digit = parseInt(ean.charAt(i), 10);

//         // Multiply even-positioned digits (index 1, 3, 5,...) by 3
//         if (i % 2 === 1) {
//           sum += digit * 3;
//         } else {
//           // Add odd-positioned digits (index 0, 2, 4,...) as they are
//           sum += digit;
//         }
//       }

//       // Calculate the check digit
//       const checkDigit = (10 - (sum % 10)) % 10;

//       ean = ean + String(checkDigit);
//     }
//   }

//   // console.log(input['Manufacturer_Code'], ' - EAN: ', ean);

//   return ean;
// }

export function getIntercarsPrice(input: RawImport, _rule: TRule) {
  let price = 0;

  price = parseFloat((parseFloat(input['customer_price']) / 1.25).toFixed(2));

  return price;
}
