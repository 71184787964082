import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DropdownValues, ServiceFormHelper } from './constants/service.form';
import { FieldValues, useForm } from 'react-hook-form';
import { GenerateForm } from './components/GenerateForm';
import { useGetServiceById } from './query/get-service-by-id';
import { useCallback, useEffect, useState } from 'react';
import { useGetServiceCategory } from '../../pages/dashboard/services/query/get-service-category';
import { DBServiceCategoryApi, DBServicesApi } from '@tyrio/dto';
import {
  useGetMainCategories,
  useGetRims,
  useGetSubcategoryByCategoryId,
} from './query/get-subcategory';
import { TitleWrapper } from './components/TitleWrapper';
import { renderFields } from './components/RenderFormFields';
import { PageTemplateContainer } from '../../components/PageTemplate/PageTemplate';
import { ServiceCategoryFormHelper } from './constants/service-category.form';
import { useGetServiceCategoryById } from './query/get-service-category-by-id';
import { InitialValues } from './constants/initial-values';
import { useCreateService, useCreateServiceCategory } from './query/create';
import { useQueryClient } from 'react-query';
import { useUpdateService, useUpdateServiceCategory } from './query/update';
import { zodResolver } from '@hookform/resolvers/zod';
import { zodCategoryAndCodeSchema } from './constants/validation';
import {
  useUpdateActiveService,
  useUpdateActiveServiceCategory,
} from './query/update-active-status';
import { useDeleteService, useDeleteServiceCategory } from './query/delete';
import { omit } from 'lodash';

export const ServicesForm = ({ isNew }: { isNew: boolean }) => {
  // URL PARAMS
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const history = useHistory();

  const loadServices = search.includes('services=true');

  // GET MAIN DATA - serviceData (if url includes services ) or serviceCategoryData
  const { data: serviceData } = useGetServiceById(id, loadServices);
  const { data: serviceCategoryData } = useGetServiceCategoryById(
    id,
    !loadServices
  );

  const queryClient = useQueryClient();

  // STATES
  const [category, setCategory] = useState<DropdownValues[]>([]);
  const [productCategories, setProductCategories] = useState<DropdownValues[]>(
    []
  );
  const [subcategoryDropdown, setSubcategoryDropdown] = useState<
    DropdownValues[]
  >([]);
  const [rims, setRims] = useState<DropdownValues[]>([]);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState<
    string | undefined
  >(serviceData?.productCategoryId?.toString());
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    DropdownValues[] | undefined
  >((serviceData?.subcategory as unknown as DropdownValues[]) ?? []);

  const generateCategoryValue = useCallback(() => {
    if (loadServices)
      return {
        category: serviceData?.serviceCategoryId,
        code: serviceData?.code,
      };
    else return { category: '1', code: serviceCategoryData?.code ?? '' };
  }, [
    loadServices,
    serviceCategoryData?.code,
    serviceData?.code,
    serviceData?.serviceCategoryId,
  ]);

  const {
    register,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      ...generateCategoryValue(),
    } as FieldValues,
    resolver: zodResolver(zodCategoryAndCodeSchema),
  });

  useEffect(() => {
    reset(generateCategoryValue());
  }, [
    serviceData,
    serviceCategoryData,
    reset,
    generateCategoryValue,
    loadServices,
    isNew,
  ]);

  const selectedCat = watch('category');
  const isServiceCategory = selectedCat === '1';

  // FORM DEFAULT VALUES
  const initialValues = InitialValues(
    isNew,
    isServiceCategory,
    selectedProductCategoryId,
    serviceData,
    serviceCategoryData
  );

  // FORM DATA AND ZOD SCHEMA
  const {
    form: serviceForm,
    fixedPriceForm,
    chargedByLaborTime,
    zodSchema,
    firstRow,
  } = ServiceFormHelper(
    category,
    productCategories,
    subcategoryDropdown,
    rims,
    isNew,
    initialValues
  );

  const { form: serviceCategoryForm, zodSchema: serviceCategoryZod } =
    ServiceCategoryFormHelper([{ label: 'PDV-25%', value: '25' }]);

  // FUNCTIONS
  const handleSubmit = (data: FieldValues) => {
    // CREATE NEW DATA
    if (isNew) {
      if (isServiceCategory)
        createServiceCategory(
          data as DBServiceCategoryApi['create']['requestBody']
        );
      else {
        const modifiedData = {
          ...omit(data, [
            'radioButton',
            'subCategoryId',
            'productCategoryId',
            'category',
          ]),
          serviceCategoryId: selectedCat,
          subcategory: data['subCategoryId'] ?? [],
          productCategoryId: Number(selectedProductCategoryId),
        };

        createService(modifiedData as DBServicesApi['create']['requestBody']);
      }
    }
    // UPDATE OLD DATA
    else {
      if (isServiceCategory) {
        updateServiceCategory({ id, ...omit(data, 'code') });
      } else {
        const modifiedData = {
          ...omit(data, [
            'code',
            'radioButton',
            'category',
            'subCategoryId',
            'productCategoryId',
          ]),
        };

        updateService({
          id,
          ...modifiedData,
          subcategory: data['subCategoryId'] ?? [],
          productCategoryId: Number(data['productCategoryId']),
        });
      }
    }
  };

  const changeProductCategory = (prodCategoryId: string) => {
    if (prodCategoryId !== selectedProductCategoryId && prodCategoryId !== '')
      setSelectedProductCategoryId(prodCategoryId);
  };

  const changeSubcategory = (subcategory: DropdownValues[]) => {
    if (subcategory !== selectedSubcategories && subcategory !== null)
      setSelectedSubcategories(subcategory);
  };

  const getCategories = (data: DBServiceCategoryApi['list']['response']) => {
    const categories = data.data.map((d) => {
      return { label: d.name, value: d.id };
    });
    setCategory(categories);
  };

  const handleChangeStatus = () => {
    if (loadServices) updateActiveService({ id, active: !serviceData?.active });
    else
      updateActiveServiceCategory({ id, active: !serviceCategoryData?.active });
  };

  const handleDelete = () => {
    if (loadServices) deleteService({ id });
    else deleteServiceCategory({ id });
  };

  const handleCancel = () => {
    history.push('/dashboard/services-category');
  };

  // QUERY
  useGetServiceCategory(undefined, undefined, undefined, getCategories);
  useGetMainCategories(setProductCategories);
  useGetSubcategoryByCategoryId(
    selectedProductCategoryId ?? '',
    setSubcategoryDropdown,
    !isNew ? serviceData?.subcategory ?? [] : []
  );
  useGetRims(
    selectedSubcategories?.map((v) => v.value) ?? [],
    setRims,
    !isNew ? serviceData?.rimDiameter ?? [] : []
  );

  // MUTATIONS
  const { createServiceCategory } = useCreateServiceCategory(queryClient);
  const { createService } = useCreateService(queryClient);
  const { updateServiceCategory } = useUpdateServiceCategory(queryClient);
  const { updateService } = useUpdateService(queryClient);
  const { updateActiveServiceCategory } =
    useUpdateActiveServiceCategory(queryClient);
  const { updateActiveService } = useUpdateActiveService(queryClient);
  const { deleteServiceCategory } = useDeleteServiceCategory(queryClient);
  const { deleteService } = useDeleteService(queryClient);

  return (
    <PageTemplateContainer style={{ height: '95vh' }}>
      <TitleWrapper
        title={
          isNew
            ? isServiceCategory
              ? 'Create new service category'
              : 'Create new service'
            : isServiceCategory
            ? serviceCategoryData?.name
            : serviceData?.name
        }
        active={
          isNew
            ? true
            : loadServices
            ? serviceData?.active
            : serviceCategoryData?.active
        }
        onChangeStatus={handleChangeStatus}
      />
      <div
        style={{ padding: '16px 16px 0 16px' }}
        key={generateCategoryValue().code}
      >
        {renderFields(firstRow, register, control, errors)}
      </div>

      {selectedCat !== '' && (
        <GenerateForm
          form={isServiceCategory ? serviceCategoryForm : serviceForm}
          zodSchema={isServiceCategory ? serviceCategoryZod : zodSchema}
          doSubmit={handleSubmit}
          initialValues={initialValues}
          key={isServiceCategory ? serviceCategoryData?.id : serviceData?.id}
          fixedPriceForm={isServiceCategory ? undefined : fixedPriceForm}
          chargedByLaborTime={chargedByLaborTime}
          changeProductCategory={
            isServiceCategory ? undefined : changeProductCategory
          }
          disableDelete={false}
          onDelete={handleDelete}
          onCancel={handleCancel}
          changeSubcategory={isServiceCategory ? undefined : changeSubcategory}
        />
      )}
    </PageTemplateContainer>
  );
};
