import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const RONAL_CATALOG_RULESET: TRule[] = [
  // EAN
  {
    type: 'rule.map',
    sourceKey: "c['EAN Code']",
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.operate',
    sourceKey: "c['Wheel number']",
    destination: 'product',
    targetKey: 'manufacturerCode',
    operationName: 'getRonalManufacturerCode',
  },
  // Category types
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'category_id',
    template: '72',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'brand',
    template: 'RONAL',
    outputType: 'string',
  },
  // Model name
  {
    type: 'rule.operate',
    sourceKey: 'c.Design',
    destination: 'model',
    targetKey: 'modelName',
    operationName: 'getRonalModelData',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.Width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.Diameter',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'pcd',
    template: "#{c.Holes}x#{c['Bolt circle']}",
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c.Offset',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
  },
  {
    type: 'rule.map',
    sourceKey: "c['Center bore']",
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
  },
  // Color
  {
    type: 'rule.operate',
    sourceKey: 'c.Colour',
    destination: 'model',
    targetKey: 'color',
    operationName: 'getRonalColor',
  },
  // Photos
  {
    type: 'rule.map',
    sourceKey: 'c.imageUrl',
    targetKey: 'originalPhotos',
    destination: 'model',
    outputType: 'string',
  },
  // Instalation kit
  {
    type: 'rule.template',
    destination: 'product',
    targetKey: 'installationKit',
    template: 'partialKit',
    outputType: 'string',
  },
  // Weight
  {
    type: 'rule.map',
    sourceKey: "c['Weight, kg']",
    targetKey: 'weight',
    destination: 'product',
    outputType: 'string',
  },
  ...GLOBAL_RULESET,
];
