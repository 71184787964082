import styled from '@emotion/styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { usePosCtx } from '../../../../context/POSContext';

export type HeaderInfo =
  | 'BRANCHES STOCK'
  | 'SUPPLIERS STOCK'
  | 'ON STOCK'
  | 'UPCOMING ON STOCK';

export const StockInfoHeader = ({
  text,
  showVisibility = false,
}: {
  text: HeaderInfo;
  showVisibility: boolean;
}) => {
  const matchColorToText = () => {
    switch (text) {
      case 'BRANCHES STOCK':
        return 'rgb(25, 118, 210)';
      case 'SUPPLIERS STOCK':
        return '#DB1E1E';
      case 'ON STOCK':
        return '#3EB274';
      case 'UPCOMING ON STOCK':
        return '#EA6C42';
      default:
        return 'white';
    }
  };
  const { isSupplierNameVisible, setIsSupplierNameVisible } = usePosCtx();
  return (
    <StockInfo background={matchColorToText()}>
      <Text>{text}</Text>
      {showVisibility && (
        <div
          style={{ cursor: 'pointer', padding: '8px', display: 'flex' }}
          onClick={() =>
            setIsSupplierNameVisible &&
            setIsSupplierNameVisible(!isSupplierNameVisible)
          }
        >
          {isSupplierNameVisible ? (
            <VisibilityIcon sx={{ color: 'white' }} />
          ) : (
            <VisibilityOffIcon sx={{ color: 'white' }} />
          )}
        </div>
      )}
    </StockInfo>
  );
};

export const StockInfo = styled.div<{ background: string }>`
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: fit-content;
  background: ${(props) => props.background};
  padding: 4px 12px;
`;

export const Text = styled.p`
  color: white;
  font-size: 16px;
  font-family: Barlow;
  font-weight: 600;
  letter-spacing: 0.3px;
  overflow-wrap: break-word;
  margin: 0;
`;
