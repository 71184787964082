import _ from 'lodash';
import { useFilter } from '../../../../../hooks/useFilter';
import { HeaderText } from '../../../steps/Search/SelectStepper';
import { Card, CardWrapper, Container, StyledCheckbox } from './BrandSelector';
import { useGetPosFilters } from '../query/get-filter';
import { useContext, useEffect, useMemo } from 'react';
import { POSContext } from '../../../../../context/POSContext';
import { InputOption } from '@tyrio/dto';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled/macro';
import { PosSearchContext } from '../SearchContext';

interface RimSizeSelectorProps {
  label: string;
  options: string[];
}

export const RimSizeSelector = ({ label, options }: RimSizeSelectorProps) => {
  const { ...filtersHook } = useFilter();

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const initialSelectedValues: string[] = get('rimSize');
  const { filterValues, setFilterValue, shouldRefetch } =
    useContext(POSContext);
  const { setSupplierPage } = useContext(PosSearchContext);

  let selectedValues = initialSelectedValues;
  const { filtersPos, isLoading, refetch } = useGetPosFilters(
    filterValues,
    shouldRefetch
  );

  const rimSizesFromStock = useMemo(() => {
    if (isLoading) return [];
    else return filtersPos['rimSizes'];
  }, [filtersPos, isLoading]);

  const handleSelectValue = (isSelected: boolean, checkboxKey: string) => {
    if (isSelected) {
      selectedValues.push(checkboxKey);
    } else selectedValues = selectedValues.filter((el) => el !== checkboxKey);

    setFilterValue({
      ...filterValues,
      rimSize: selectedValues,
    });
    setSupplierPage(0);

    // Scroll to the top of pos table
    const posTable = document.getElementById('pos-table');
    posTable?.scroll({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    refetch();
  }, [filterValues, refetch]);

  return (
    <Container>
      <HeaderText>{label}</HeaderText>

      {!isLoading ? (
        <CardWrapper
          style={{
            flexWrap: 'wrap',
            gap: '16px',
            justifyContent: 'flex-start',
          }}
        >
          {options.map((value, key) => {
            const isSelected = selectedValues?.includes(value);

            return (
              <Card
                onClick={() => {
                  if (
                    rimSizesFromStock?.includes(value as string & InputOption)
                  )
                    handleSelectValue(!isSelected, value);
                }}
                selected={isSelected}
                key={key}
                width={100}
                disabled={
                  !rimSizesFromStock?.includes(value as string & InputOption)
                }
              >
                <StyledCheckbox
                  type="checkbox"
                  key={`checkbox_${value}`}
                  checked={isSelected}
                  onChange={() => null}
                  style={{ marginRight: '10px' }}
                  disabled={
                    !rimSizesFromStock?.includes(value as string & InputOption)
                  }
                />
                {value}
              </Card>
            );
          })}
        </CardWrapper>
      ) : (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </Container>
  );
};

const LoaderWrapper = styled.div`
  margin-top: 32px;
`;
