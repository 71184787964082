import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Modal, TextField } from '@mui/material';
import api from '@tyrio/api-factory';
import { DBProductApi, DBStockListApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../../query-client';
import { Footer } from '../../../../components/Modal/Footer';
import { StockListContext } from '../../../../context/StockListContext';
import {
  BoxStyled,
  CloseWrapper,
  Title,
  TitleWrapper,
} from './style/common.style';

interface IEditProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  product: DBProductApi['getOne']['response'];
}

export const EditModal = ({ open, setOpen, product }: IEditProps) => {
  const { selectedTableRow } = useContext(StockListContext);
  const [editStockPayload, setEditStockPayload] = useState<
    DBStockListApi['update']['requestBody']
  >({
    id: selectedTableRow?.id ?? '',
    reserved: selectedTableRow?.reserved ?? 0,
    quantity: selectedTableRow?.quantity ?? 0,
    upcoming: selectedTableRow?.upcoming ?? 0,
  });

  const editStockListItem = useMutation(
    () => {
      return api.fetch<DBStockListApi['update']>(
        'update_stock_list',
        editStockPayload
      );
    },
    {
      mutationKey: 'update_stock_list',
      onSuccess: () => {
        setTimeout(() => {
          queryClient.refetchQueries('get_stock_list_products');
          queryClient.refetchQueries('main_branch_stock');
        }, 100);
        setOpen(false);
        ToastHelper.showToast(
          'Item',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error?.response?.data?.error.name;
          ToastHelper.showToast(
            'Product',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <BoxStyled style={{ width: 'auto', height: 'auto' }}>
        <CloseWrapper>
          <CloseIcon
            onClick={() => setOpen(false)}
            sx={{ cursor: 'pointer' }}
          />
        </CloseWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>{`${product.model?.brand}  ${product.productName}`}</Title>
            <ProductCodes>{`SKU: ${product.sku} EAN: ${product.ean} IPC: ${product.manufacturerCode}`}</ProductCodes>
          </TitleWrapper>
        </Box>
        <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
        <Box style={{ overflowY: 'auto' }}>
          <StockOnCurrentLocationWrapper>
            <TextField
              fullWidth
              variant="outlined"
              id="quantity"
              label="Quantity"
              placeholder="Quantity on stock"
              sx={{ width: '40%' }}
              value={
                editStockPayload?.quantity && editStockPayload?.quantity !== 0
                  ? editStockPayload?.quantity.toString().replace(/^0+/, '')
                  : 0
              }
              type="number"
              onChange={(e) =>
                setEditStockPayload((prevState) => ({
                  ...prevState,
                  quantity: Number(e.target.value),
                }))
              }
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="reserved"
              label="Reserved"
              placeholder="Reserved from stock"
              sx={{ width: '40%' }}
              value={
                editStockPayload?.reserved && editStockPayload?.reserved !== 0
                  ? editStockPayload?.reserved.toString().replace(/^0+/, '')
                  : 0
              }
              type="number"
              onChange={(e) =>
                setEditStockPayload((prevState) => ({
                  ...prevState,
                  reserved: Number(e.target.value),
                }))
              }
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              id="upcoming"
              label="Upcoming"
              placeholder="Upcoming on stock"
              sx={{ width: '40%' }}
              value={
                editStockPayload?.upcoming && editStockPayload?.upcoming !== 0
                  ? editStockPayload?.upcoming.toString().replace(/^0+/, '')
                  : 0
              }
              type="number"
              onChange={(e) =>
                setEditStockPayload((prevState) => ({
                  ...prevState,
                  upcoming: Number(e.target.value),
                }))
              }
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </StockOnCurrentLocationWrapper>
        </Box>
        <Footer
          setOpen={setOpen}
          handleSubmit={() => editStockListItem.mutate()}
        />
      </BoxStyled>
    </Modal>
  );
};

const ProductCodes = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

const StockOnCurrentLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 10px;
`;
