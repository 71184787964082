import { APIConfig } from './types';

const firebasePrivateKey =
  '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCWfWDv2lqktXSO\nbcMw+B/Z+xzU52QbaucurgwlRKvabyf3366IfBsZlev2SMQI3GzuzEgmOy/Anw8d\n0iIsMd8JtUjPt+YkG0C48uSPVKhp8vnMq3r7t3171vJfihlP9msCvwGO6GfknIFr\ng3FYqSf4UdgUIgq6PFx+vPqitpK5X4YMEFDxTc6yl7fsYRwecf0WXWOswwPLLGxK\nZRn/MhB5VAJsNLoA7FkcBXzCf4UTjs+UhdX2eXTVf8OO5iPTfTN8p9Dyfl3sjE8z\n5+qB5FB2jvtGpm7QJ0crLYgz2a88u/WuSNqi64P0xCb+aztVFitR4qQlr+/Fspv/\nWBxQB9eNAgMBAAECggEADQtLFrjPhVokUAaPL7Ru7/I5mot//HYBgEtRZDDJA8/O\nU0iN+uiYlL9cMTNqqPX3DAlxWFb/zNHWRphxoj3Xl+86idhMpRgm123xhiopYVDQ\nepjpud3UWgkHzMsBZkWOtJgHzSWATXfykKlXbsu8WjE5HVQIdM7i//bmpqntzkF2\nUr6Em0guNw5nBmH2ah2Lh75o/F8TIjr8iBgFwHV7M1kvlJybmA/xludMCEbrNfVk\n2a6Ua1slrjLeyIS1vhjSr0QQ9pvMYKGjM7GhvXMVDLCrfhRdkiO8DX9sUCnPiof1\nGCunL8CNxEEVEhk9xa7sp3x8RhAnV6t+d90UpqQO5QKBgQDPEqWdXMzz0i2d5Q+z\nvK+kWX50ZUqeiPf9GxhYh5OKvCX5UcnsWJOW+w930DBA8rW5KJ+tud9MANkrCdhn\nRoenZ4eb3GauQylux7XPzmHmuec68px8CnxoRTzEyO0f6Fbo7POOzvp/0W/Stj1h\ngpCZJLvCasJo83D/+WZQAjmCBwKBgQC6DCc7PJucUJdTbxPm+hCdV3wb3jQEZhSn\n3V9dpKW4ooK1R/qnFlY22w9uI9R57JlBlPrEASvNEgX92IwP1OdOqbyOjmkaOPuX\n+mmUH1m9xtETyHxlEmsl8DGqmunVTR3fOV9ZCCqPRfuSP4MvG6ZkAGnVRr9Q6acZ\nodQ2OIVkywKBgQCZD+SBdEY++B4l91pq9k8ygcB6UyEcHIyKd8vALL7/ruG28CD8\n5eB6mqutWaNBbuaqrf+SD7Gh4YKlUIq4lCbTtDBGIl139EcdDtwydhBh1wq3xPus\nqKea/4rSvt6gVkJfvD6wUghSEMLCk1i9yiAOQ1vScs2vfDsLyxM4ktxlYwKBgE7H\nrKkZve7P9XQf+fZ5dyWZEPWf/rjKHP8D70jw2je3oM9wfkPYYoNx+TOzOlqyhCbQ\nFet/b3OzqRPXaV+e0J9vomdfCERRUsAFCkXtjIuer5PH9ly6EuMKtY0/cbqfoAGR\nAr3phlQK1z8TY9JDzA5WWno0LfjuJ5LkAr8+6cVHAoGAW8ravkwL/EUzxGH7h6n0\nQhncPfXZzO9C0Rm+lXlTtypZs4zbUGtw0iF2kwBw+tNeBVNjR4kbNoUHMF4K/7eg\nIKYFtevoGusTif0hZRd4QiGRAAweOO5GhSJw3ESv/GgBIwDyiXYczHFyd9sFzrGB\nuG70Q4FKYaz/fFO2lKol4Fs=\n-----END PRIVATE KEY-----\n';

console.log('IN CONFIG process env', {
  importer: process.env['IMPORTER'],
  api: process.env['API'],
  stockManager: process.env['STOCK_MANAGER'],
});
export const defaultEnv: APIConfig = {
  imageConfig: {
    isImporter: process.env['IMPORTER'] === 'true',
    isApiLayer: process.env['API'] === 'true',
    isStandalone: true,
    isStockManager: process.env['STOCK_MANAGER'] === 'true',
  },
  rabbitmq: {
    queueName: 'a-importer',
    connectionUrl: 'amqp://localhost',
  },
  production: false,
  traduora: {
    projectId: '3e4cab36-d544-4fb4-b29f-79eb83735b77',
    baseUrl: 'https://tyrio-traduora.herokuapp.com/api/v1',
    clientId: 'ef4337dc-85da-4d87-9de7-3325db4a01b9',
    secret: 'UfrR1l7uKmwlsoDLeJvwLRvxc6UZMarL',
  },
  awsS3: {
    bucket: 'd-tyrio-storage-189d46b',
    key: process.env['S3_KEY'],
    secret: process.env['S3_SECRET'],
  },
  firebaseConfig: {
    projectId: 'tyrio-b4941',
    clientEmail: 'firebase-adminsdk-obefc@tyrio-b4941.iam.gserviceaccount.com',
    privateKey: firebasePrivateKey,
    // private_key_id: this.configService.get('FIREBASE_PRIVATE_KEY_ID'),
    // client_id: this.configService.get('FIREBASE_CLIENT_ID'),
    // auth_uri: this.configService.get('FIREBASE_AUTH_URI'),
    // token_uri: this.configService.get('FIREBASE_TOKEN_URI'),
    // auth_provider_x509_cert_url: this.configService.get(
    //   'FIREBASE_AUTH_PROVIDER'
    // ),
    // client_x509_cert_url: this.configService.get('FIREBASE_CLIENT_URL'),
  },
  printNodeApi: 'https://api.printnode.com',
  glsApi: 'https://api.test.mygls.hr',
  glsTrackingApi: 'https://gls-group.eu/app/service/open/rest/HR/en/rstt001',
  dpdApi: 'https://easyship.hr/api',
  dpdSecret: 'FcJyN7vU7WKPtUh7m1bx',
  ritamApi: {
    username: 'aantonio_ws',
    password: '01byh72',
  },
  redis: {
    host: 'localhost',
    port: 6379,
    password: 'tyrio',
  },
};

// FIREBASE_TYPE=service_account
// FIREBASE_PROJECT_ID=tyrio-b4941
// FIREBASE_PRIVATE_KEY_ID=1bf260610b0e24847bbc9b781d83741f685707bb
// FIREBASE_PRIVATE_KEY=
// FIREBASE_CLIENT_EMAIL=firebase-adminsdk-obefc@tyrio-b4941.iam.gserviceaccount.com
// FIREBASE_CLIENT_ID=100233585884379385405
// FIREBASE_AUTH_URI=https://accounts.google.com/o/oauth2/auth
// FIREBASE_TOKEN_URI=https://oauth2.googleapis.com/token
// FIREBASE_AUTH_PROVIDER=https://www.googleapis.com/oauth2/v1/certs
// FIREBASE_CLIENT_URL=https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-obefc%40tyrio-b4941.iam.gserviceaccount.com
