import { wmsIcons } from '@tyrio/wms-ui-library';
import { isEmpty, sum } from 'lodash';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import {
  Text,
  WmsIconStyled,
} from '../../../../../../components/SideMenuLayout/SideMenuLayout.style';
import {
  initializeInput,
  usePosCartCtx,
} from '../../../../../../context/PosCartContext';
import {
  DeleteWrapper,
  MainWrapper,
} from '../../../../styles/CustomerForm.style';
import { InfoFooter } from '../Footer';
import { PaymentForm } from './PaymentForm';
import { Divider } from '@mui/material';
import {
  CancelModal,
  DiscardModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { useContext, useState } from 'react';
import { usePosCtx } from '../../../../../../context/POSContext';
import { useAuth } from '../../../../../../context/AuthContext';
import { POSDetailsContext } from '../../../../../../context/POSDetails';
import { useWS } from '../../../../../../context/WSContext';
import { useCalculatePrice } from '../../helpers/calculation';
import { usePosCartData } from '../../helpers/cart-data';
import _ from 'lodash';
import {
  AppointmentReq,
  CartData,
  CreateServiceInfo,
  CreateServiceReservationReq,
  PosApi,
  ServiceInfo,
  StockShape,
} from '@tyrio/dto';
import { useGetCountries } from '../../../../query/get-countries';
import { useMutation } from 'react-query';
import api from '@tyrio/api-factory';
import { AxiosError } from 'axios';
import { usePosSearchContext } from '../../../../components/SearchPOS/SearchContext';

const transformRequest = (data: CartData[], key?: string): StockShape[] => {
  const path = key ?? 'branchId';
  const groupedData = _.groupBy(data, path);

  const stockShape: StockShape[] = [];

  Object.entries(groupedData).forEach(([key, value]) => {
    const transformedValue = path === 'branchId' ? Number(key) : key;

    const items = value.map((v) => v.req);

    stockShape.push({
      [path]: transformedValue,
      supplierName: value[0]?.supplierName ?? '',
      supplierSlug: value[0]?.supplierSlug ?? '',
      items,
    } as unknown as StockShape);

    return;
  });

  return stockShape;
};

export const Payment = () => {
  const { user } = useAuth();
  const ws = useWS();

  const {
    setActiveInfo,
    paymentDetails,
    setPaymentDetails,
    input,
    setInput,
    deliveryPrice,
    customerDetails,
    appointmentDetails,
    setCartData,
    timer,
    clearCustomerDetails,
    clearVehicleForm,
    setIsRecommendedOpen,
    useCashPrice,
    clearAllPosCartData,
  } = usePosCartCtx();

  const { modalData, onStockData, branchesData, suppliersData, servicesData } =
    usePosCartData();
  const {
    isVatIncluded,
    selectedCustomer,
    setIsPosCheckout,
    setSelectedTableRow,
    setActiveStep,
    setFilterValue,
    setSelectedCustomer,
  } = usePosCtx();

  const { setBadgeContent } = useContext(POSDetailsContext);
  const { findCountryCode } = useGetCountries();

  const { resetAllValues } = usePosSearchContext();

  const { grandTotal, subTotalExlVat, servicesPrice, cashGrandTotal } =
    useCalculatePrice(
      modalData,
      deliveryPrice,
      isVatIncluded,
      selectedCustomer?.rebateCalculation?.rebate ?? 0,
      selectedCustomer?.rebateCalculation?.discount ?? 0,
      useCashPrice
    );
  const [isDirty, setIsDirty] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const isPaymentAmountValid = () => {
    const amount = sum(paymentDetails.map((p) => p.amount));
    const totalAmount = _.round(useCashPrice ? cashGrandTotal : grandTotal, 2);

    const isAnyNegative =
      paymentDetails.find((item) => item.amount < 0) !== undefined;
    return {
      isValid: amount === totalAmount && !isAnyNegative,
      isBigger: amount > totalAmount && !isAnyNegative,
      isNegative: isAnyNegative,
    };
  };

  const validate = () => {
    const { isValid, isBigger, isNegative } = isPaymentAmountValid();

    if (!isValid || isNegative) {
      const msg = isBigger
        ? 'The payment amount exceeds the requested amount.'
        : isNegative
        ? `The payment amount can't be negative.`
        : 'The payment amount is less than the requested amount.';
      ToastHelper.showToast(
        'Payment',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        msg
      );
      setIsValid(false);
    }

    setIsValid(true);
  };

  const handleCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else {
      setIsRecommendedOpen(true);
      setActiveInfo('');
      setIsPosCheckout(false);
    }
  };

  const handleDiscard = () => {
    setInput((prevState) => ({
      ...prevState,
      payment: [],
    }));
    setPaymentDetails([]);
    setIsDiscardModalVisible(false);
    setActiveInfo('');
    setIsDirty(false);
  };

  const getAppointments = () => {
    const appointments = Object.values(
      appointmentDetails['appointments']
    ) as unknown as AppointmentReq[];

    const final: CreateServiceReservationReq[] = [];

    appointments.forEach((item) => {
      const services: CreateServiceInfo[] = (
        Object.values(item.services) as unknown as ServiceInfo[]
      ).map((item) => ({
        serviceId: item.id,
        quantity: item.qty,
      }));

      if (!item.appointmentDateFrom || !item.appointmentDateTo)
        setIsValid(false);
      else
        final.push({
          appointmentDateFrom: item.appointmentDateFrom?.toString() as string,
          appointmentDateTo: item.appointmentDateTo?.toString() as string,
          boxId: item.resource?.shelfId as string,
          remark: '',
          services: services,
        });
    });

    return final;
  };

  const finishOrder = useMutation(
    (request: PosApi['finishOrder']['request']) =>
      api.fetch<PosApi['finishOrder']>('finish_pos_order', {
        ...request,
      }),
    {
      mutationKey: 'finish_pos_order',
      onSuccess: (data: PosApi['finishOrder']['response']) => {
        // TODO: export to function since we are using this on empty cart too
        setCartData([]);
        timer?.clear();
        ws.socket?.emit('remove-all-pos-cart-items');

        setInput(initializeInput());
        clearCustomerDetails();
        clearVehicleForm();
        setPaymentDetails([]);
        setIsPosCheckout(false);
        setSelectedTableRow(null);
        setActiveStep('SEARCH');

        setBadgeContent((prevState) => ({
          BRANCHES: prevState.BRANCHES,
          SUPPLIERS: prevState.SUPPLIERS,
          CART: 0,
        }));
        setFilterValue({
          categoryId: undefined,
          subcategoryId: undefined,
          season: undefined,
          brand: undefined,
          wheelPosition: undefined,
          specialMarking: undefined,
          customer: undefined,
          size: undefined,
        });
        clearAllPosCartData();
        setSelectedCustomer(null);
        setIsPosCheckout(false);
        setSelectedTableRow(null);
        setInput({
          customer: {},
          appointment: {},
          isCustomerEditDisabled: false,
          isCustomerSwitchActive: false,
          isTyrioCustomer: false,
          isSudRegCustomer: false,
          delivery_method: { address: [] },
          vehicle: {},
          payment: [],
          shouldDisableFields: false,
        });
        setActiveStep('SEARCH');
        resetAllValues(); // reset search sizes

        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE,
          `Order created: ${data.response.id}`
        );

        ToastHelper.showToast(
          'Ritam order',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          data.ritamResponse.join(' - ')
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Error',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occured.'
          );
        }
        throw error;
      },
    }
  );

  const handleFinish = () => {
    const appointments = getAppointments();
    // validate payment price
    validate();
    if (isValid) {
      const my_stock = transformRequest(onStockData);
      const branches_stock = transformRequest(branchesData);
      const supplier_stock = transformRequest(suppliersData, 'supplierId');

      const requestBody: PosApi['finishOrder']['requestBody'] = {
        branchesStock: branches_stock,
        myStock: my_stock,
        supplierStock: supplier_stock,
        delivery: {
          code:
            !isEmpty(input.delivery_method) &&
            !isEmpty(input.delivery_method.deliveryType)
              ? input?.delivery_method?.deliveryType?.code ?? ''
              : '',
          deliveryRange:
            input?.delivery_method?.deliveryType?.text ?? undefined,
        },
        payment: {
          code: input.payment.length > 0 ? input?.payment?.[0].code ?? '' : '',
        },
        appointments,
        services:
          servicesData.map((item) => ({
            erpId: item.req.erpId ?? '',
            code: item.req.code ?? '',
            name: item.req.productName ?? '',
            price: item.req.price ?? 0,
            quantity: item.req.quantity ?? 0,
            id: item.req.id ?? '',
          })) ?? [],
        mainWarehouseId: user?.mainBranchId ?? 1, // it would be nice to send erpBranchId also
        customer: !isEmpty(input.customer)
          ? {
              address: customerDetails['address'] ?? '',
              city: customerDetails['city'] ?? '',
              country: findCountryCode(customerDetails['country']) ?? 'hr',
              firstName: customerDetails['first_name'],
              lastName: customerDetails['last_name'],
              zip: customerDetails['zip_code'] ?? '',
              phoneNumberDetails: {
                countryCode: '+385', // TODO: fix this!
                phoneNumber: customerDetails['mobile_phone'],
              },
              newsletter: false,
              email: customerDetails['email'],
              mobilePhone: customerDetails['mobile_phone'],
            }
          : undefined,
        deliveryAddress:
          input &&
          !isEmpty(input.delivery_method) &&
          input?.delivery_method &&
          input?.delivery_method?.address &&
          input?.delivery_method?.address?.length > 0
            ? {
                address: input?.delivery_method?.address?.[0].address ?? '',
                city: input?.delivery_method?.address?.[0].city ?? '',
                companyInfo: {
                  companyName:
                    input?.delivery_method?.address?.[0].companyName ?? '',
                },
                country: 'HR',
                firstName: input?.delivery_method?.address?.[0].firstName ?? '',
                lastName: input?.delivery_method?.address?.[0].lastName ?? '',
                phoneNumberDetails: {
                  countryCode: '+385', // TODO: FIX THIS!
                  phoneNumber:
                    input?.delivery_method?.address?.[0].mobilePhone ?? '',
                },
                zip: input?.delivery_method?.address?.[0].zip ?? '',
              }
            : undefined,
        billingAddress: !isEmpty(input.customer)
          ? {
              address: customerDetails['address'] ?? '',
              city: customerDetails['city'] ?? '',
              country: findCountryCode(customerDetails['country']) ?? 'hr',
              firstName: customerDetails['first_name'] ?? '',
              lastName: customerDetails['last_name'] ?? '',
              zip: customerDetails['zip_code'] ?? '',
              phoneNumberDetails: {
                countryCode: '+385', // TODO: fix this!
                phoneNumber: customerDetails['mobile_phone'],
              },
              email: customerDetails['email'],
              companyInfo: {
                companyName: customerDetails['company_name'] ?? '',
                vat: customerDetails['vat'] ?? '',
                companyInvoice: false,
              },
            }
          : undefined,
        summary: {
          vatAmount: grandTotal - subTotalExlVat,
          deliveryExVat: deliveryPrice,
          discountExVat: 0,
          servicesExVat: servicesPrice,
          subtotalExVat: subTotalExlVat,
          grandTotalIncVat: grandTotal,
        },
        vehicleInfo: !_.isEmpty(input.vehicle)
          ? {
              brand: input.vehicle['brand'] ?? '',
              model: input.vehicle['model'] ?? '',
              year: input.vehicle['year'] ?? '',
              licensePlateNumber: input.vehicle['licence_plate'] ?? '',
              engineDisplacementCcm: input.vehicle['engine_displacement'] ?? '',
              enginePower: input.vehicle['engine_power'] ?? '',
              homologationNumber: input.vehicle['homogolation_nr'] ?? '',
              typeApprovalNumber: input.vehicle['type_approval_number'] ?? '',
            }
          : undefined,
        createdBy: {
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
        },
      };
      finishOrder.mutate(requestBody);
    }
  };

  const renderPayment = () => {
    return (
      <MainWrapper>
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              setPaymentDetails(input.payment);
              setIsCancelModalVisible(false);
              setIsPosCheckout(false);
              setActiveInfo('');
              setIsDirty(false);
            }}
          />
        )}
        {isDiscardModalVisible && (
          <DiscardModal
            LBAction={() => setIsDiscardModalVisible(false)}
            RBAction={handleDiscard}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Divider sx={{ marginTop: '10px' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              color: '#b9c4ce',
              fontSize: '18px',
              marginTop: '20px',
            }}
          >
            Select payment type
          </div>
          <PaymentForm setIsDirty={setIsDirty} />

          {/* <CashDiscount />
          <PromoCode /> */}
        </div>
        <DeleteWrapper onClick={() => setIsDiscardModalVisible(true)}>
          <Text id="delete_text">Discard changes</Text>
          <WmsIconStyled icon={wmsIcons.bin} id="delete_icon" />
        </DeleteWrapper>
      </MainWrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posPayment"
      children={renderPayment()}
      showSwitch={false}
      switchLabel="Vehicle"
      shouldShowTitle={true}
      footer={
        <InfoFooter
          text1="BACK"
          text2="FINISH"
          onCancel={handleCancel}
          onSubmit={handleFinish}
          shouldShowAlert={false}
          disableSubmit={_.isEmpty(paymentDetails) || modalData.length === 0}
          leftButtonStyle={{ height: '60px', width: '250px' }}
          rightButtonStyle={{ height: '60px', width: '100%' }}
          showBackIcon={true}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};
