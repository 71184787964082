import { defaultEnv } from './default.api';
import { APIConfig } from './types';

export const environment: APIConfig = {
  ...defaultEnv,
  production: false,
  awsS3: {
    bucket: 'd-tyrio-storage-189d46b',
    key: 'AKIA3ZYSEBGHQOSK53NF',
    secret: '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',
  },
};
