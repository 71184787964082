import { Button, Grid } from '@mui/material';
import { TextFieldInput } from '../../features/supplier-form/helpers/components';

export const RepeatableInputList = ({
  value: items,
  setValue: setItems,
}: {
  value: string[][];
  setValue: (v: string[][]) => void;
}) => {
  return (
    <div>
      <Grid container sx={{ paddingLeft: '80px' }} spacing={2}>
        {items.map((item, idx) => (
          <>
            <Grid item xs={7}>
              <TextFieldInput
                label="File Location"
                value={item[0]}
                onChange={(e) => {
                  setItems([
                    ...items.slice(0, idx),
                    [e.target.value, item[1]],
                    ...items.slice(idx + 1),
                  ]);
                  console.log(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextFieldInput
                label="Match key"
                value={item[1]}
                onChange={(e) => {
                  console.log(e.target.value);
                  setItems([
                    ...items.slice(0, idx),
                    [item[0], e.target.value],
                    ...items.slice(idx + 1),
                  ]);
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                onClick={() => {
                  const newItems = [
                    ...items.slice(0, idx),
                    ...items.slice(idx + 1),
                  ];
                  setItems(newItems);
                }}
              >
                REMOVE
              </Button>
            </Grid>
          </>
        ))}
        <Grid item xs={12}>
          <Button
            onClick={() => {
              setItems([...items, [] as string[]]);
            }}
          >
            Add another file location
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
