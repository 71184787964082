import { CheckAppointmentsRes } from './appointment';
import { CheckPriceRes } from './price-calculation';
import { IUpdateAfterInboundResponse } from './work-orders';

export interface CartStockListItem {
  quantity: number;
  ean: string;
  ipc: number;
  sku: number;
  dot: number;
  productionYear: number;
  createdAt: Date;
  productName?: string;
  uid?: string;
  stockListItemId?: string;
  price: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
  brand?: string;
}

export type orderType = Record<
  string,
  Record<string, CartStockListItem | string>
>;

export interface CartObject {
  inbound: orderType;
  outbound: orderType;
}

export type CartState = {
  userCart: Record<string, CartObject>;
};

// pos cart
export interface PosCartItem {
  productName: string;
  productBrand: string;
  productDescription: string;
  price: number;
  discount: number;
  rebate: number;
  quantity: number;
  total: number;
  ean: string;
  sku: string;
  uid: string;
  dot: string;
  reserved: number;
  realQuantity: number;
  categoryId: number;
  inclusiveVat: number;
  serviceCategory?: string;
  pcd?: string;
  et?: number;
  cashPrice?: number | null;
  vatCashPrice?: number | null;
  code?: string;
  id?: string;
  erpId?: string;
  purchasePrice: number;
  customColor?: string;
}

type StockType =
  | 'ON STOCK'
  | 'UPCOMING ON STOCK'
  | 'BRANCHES STOCK'
  | 'SUPPLIERS STOCK'
  | 'SERVICES'
  | '';

export interface CartData {
  key: string;
  req: PosCartItem;
  stockType: StockType;
  branchId?: number;
  supplierId?: string;
  supplierName?: string;
  supplierSlug?: string;
}

export interface PosCartObject {
  onStock: CartData[];
  upcomingOnStock: CartData[];
  branches: CartData[];
  suppliers: CartData[];
  // TODO: prepare type for services when we implement them
  services: CartData[];
}

export type PosCartState = {
  userCart: Record<string, PosCartObject>;
};

export type OrdersInProcessState = {
  userOrders: Record<string, string>;
};

export interface WSState {
  locks: {
    [key: string]: {
      userId: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  activeEdits: {
    [key: string]: unknown;
  };
  cart: CartState;
  posCart: PosCartState;
  ordersInProcess: {
    [key: string]: {
      userId: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  activePickingAppointmentsList: {
    [key: string]: {
      userId: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  activeServicesList: {
    [key: string]: {
      userId: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  appointments: {
    [key: string]: {
      resourceId: string;
      userId: string;
      appointment: CheckAppointmentsRes;
      source: string;
    };
  };
  activeStockIns: {
    [key: string]: {
      userId: string;
      firstName: string;
      lastName: string;
    } | null;
  };
  workOrdersUpdate: {
    [userId: string]: {
      [customerOrderId: string]: {
        response: IUpdateAfterInboundResponse;
      };
    };
  };
}

export enum SSE_ENTITY_KEYS {
  ROLE = 'role',
  TRANSFER_ORDER = 'transfer-orders',
  CUSTOMER_ORDER = 'customer-orders',
  STOCK_IN_ORDER = 'stock-in-orders',
}

/** Admin import status */

export type ImportStatus = {
  queuePosition: number;
  startedAt: number | null;
  queuedAt: number;
  finishedAt: number | null;
  isImporting: boolean;
  lastError?: unknown | null;
  modelCount?: null | number;
  productCount?: null | number;
  newProductsCount?: null | number;
};
/**
 * Shape:
 * {
 *   admin: {}
 *   [clientId]: {
 *     clientName: string
 *     activeImports: {
 *       [supplierId]: {
 *         [clientConnectionId]: {
 *           queuePosition: number
 *           startedAt: Timestamp
 *           queuedAt: Timestamp
 *           isImporting: boolean
 *           finishedAt: Timestamp | null
 *         }
 *       }
 *     }
 *   }
 * }
 */

export type ClientImportStatePayload = {
  clientId: string;
  clientName: string;
  activeImports: Partial<{
    [supplierId: string]: Partial<{
      supplierName: string;
      connections: {
        [connectionId: string]: Partial<{
          name: string;
          status: Partial<{
            source: string;
            connectionName: string;
            lastScheduled: Date;
            lastStarted?: Date | null;
            lastFinished?: Date | null;
            itemsFound?: number | null;
            matchedProducts?: number | null;
            validItems?: number | null;
            newItems?: number | null;
            nextSchedule: Date;
            status: string;
          }>;
        }>;
      };
    }>;
  }>;
};
export type ClientImportState = {
  [clientId: string]: Partial<ClientImportStatePayload>;
};

export type AdminImportStateItem = Partial<{
  name: string;
  details: Partial<{
    source: string;
    connectionId: string;
    supplierId: string;
    connectionName: string;
    lastScheduled: Date;
    lastStarted?: Date | null;
    lastFinished?: Date | null;
    itemsFound?: number | null;
    matchedProducts?: number | null;
    newProducts?: number | null;
    validItems?: number | null;
    newItems?: number | null;
    nextSchedule: Date;
    status: string;
  }>;
}>;

export type AdminImportState = {
  [connectionId: string]: Partial<AdminImportStateItem>;
};

export type AdminWSState = {
  admin: AdminImportState;
} & ClientImportState;
