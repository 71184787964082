import envName from '../env';
import { environment as a } from './a.api';
import { environment as ci } from './ci.api';
import { environment as d } from './d.api';
import { defaultEnv as defaultConfig } from './default.api';
import { environment as p } from './p.api';
import { environment as t } from './t.api';
import { APIConfig } from './types';

const envMap = {
  a,
  ci,
  d,
  defaultConfig,
  p,
  t,
};

type EnvName = 'a' | 'd' | 't' | 'ci' | 'p' | undefined;

const processEnv = envName || (process.env['NX_ENV_NAME'] as EnvName);

const env = processEnv || 'a';

const config: APIConfig = {
  ...defaultConfig,
  ...envMap[env],
};

export { config as apiConfig };
