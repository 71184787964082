/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Modal } from '@mui/material';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioIcons,
} from '@tyrio/ui-library';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Footer } from '../../../components/Modal/Footer';
import { InboundInvoiceContext } from '../../../context/InboundInvoiceContext';
import { useCalculatePrice } from '../helpers/calculation';
import { formatCurrencyWithoutSymbol } from '../../../helpers/currency-format';
import _ from 'lodash';

interface IConfirmModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleConfirm: (data: any) => void;
  formData: any;
}

export const ConfirmModal = ({
  open,
  setOpen,
  handleConfirm,
  formData,
}: IConfirmModalProps) => {
  const { customSummary, preparedInvoices } = useContext(InboundInvoiceContext);

  const { subTotalExlVat, grandTotal, vatAmount } = useCalculatePrice(
    preparedInvoices?.items ?? [],
    preparedInvoices?.vatChargeType ?? 'V05'
  );

  const handleClose = () => {
    setOpen(false);
  };

  const determineTextColor = (amount1: string, amount2: string) => {
    console.log(_.round(Number(amount1.replace(',', '.')), 2), 'AM1');
    console.log(_.round(Number(amount2.replace(',', '.')), 2), 'AM2');
    if (
      _.round(Number(amount1.replace(',', '.')), 2) ===
      _.round(Number(amount2.replace(',', '.')), 2)
    )
      return 'green';
    return 'red';
  };

  const handleCreateInvoice = () => {
    if (
      Number(customSummary.grandTotal) === 0 ||
      Number(customSummary.subtotalExlVat) === 0
    )
      ToastHelper.showToast(
        'Inbound invoice',
        ToastType.WARNING,
        ToastMessageType.UPDATE,
        `Subtotal and grand total must be greater than 0!`
      );
    else handleConfirm(formData);
  };

  const shouldDisableSubmit = () => {
    const { subtotalExlVat, vatAmount, grandTotal } = customSummary;
    if (!subtotalExlVat || !vatAmount || !grandTotal) return true;
    return false;
  };

  const showHelperText = () => {
    const subTotalChecked =
      determineTextColor(
        customSummary.subtotalExlVat,
        subTotalExlVat.toString()
      ) === 'green';
    const vatChecked =
      determineTextColor(customSummary.vatAmount, vatAmount.toString()) ===
      'green';
    const grandTotalChecked =
      determineTextColor(customSummary.grandTotal, grandTotal.toString()) ===
      'green';
    if (subTotalChecked && vatChecked && grandTotalChecked) return false;
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <BoxStyled>
        <CloseWrapper>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </CloseWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={tyrioIcons.warning}
            alt="warning"
            style={{ marginBottom: '5px' }}
          />
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between  ',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          <TitleWrapper>
            <Title>{`Total amounts don't match`}</Title>
            <ProductCodes>{`Do you wish to continue or cancel?`}</ProductCodes>
          </TitleWrapper>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            border: '1px dashed #e6e6e6',
            borderRadius: '8px',
            padding: '16px',
            marginTop: '20px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ width: 'calc(100% - 300px)' }} />
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: '500',
                  }}
                >
                  Calculation
                </div>
                <div
                  style={{
                    width: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: '500',
                  }}
                >
                  Supplier invoice
                </div>
              </div>
            </div>
            <RowWrapper>
              <DetailWrapper>
                <Text>Subtotal excl. VAT:</Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Price weight="400" size="16px">
                    {formatCurrencyWithoutSymbol(
                      Number(customSummary.subtotalExlVat.replace(',', '.'))
                    )}
                  </Price>
                  <Price
                    color={determineTextColor(
                      customSummary.subtotalExlVat,
                      subTotalExlVat.toString()
                    )}
                    weight="400"
                    size="16px"
                  >
                    {formatCurrencyWithoutSymbol(subTotalExlVat)}
                  </Price>
                </div>
              </DetailWrapper>
              <DetailWrapper>
                <Text>VAT amount:</Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Price weight="500" size="16px">
                    {formatCurrencyWithoutSymbol(
                      Number(customSummary.vatAmount.replace(',', '.'))
                    )}
                  </Price>
                  <Price
                    color={determineTextColor(
                      customSummary.vatAmount,
                      vatAmount.toString()
                    )}
                    weight="500"
                    size="16px"
                  >
                    {formatCurrencyWithoutSymbol(vatAmount)}
                  </Price>
                </div>
              </DetailWrapper>
              <DetailWrapper>
                <Text>GRAND TOTAL:</Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Price weight="500" size="18px">
                    {formatCurrencyWithoutSymbol(
                      Number(customSummary.grandTotal.replace(',', '.'))
                    )}
                  </Price>
                  <Price
                    color={determineTextColor(
                      customSummary.grandTotal,
                      grandTotal.toString()
                    )}
                    weight="500"
                    size="18px"
                  >
                    {formatCurrencyWithoutSymbol(grandTotal)}
                  </Price>
                </div>
              </DetailWrapper>
            </RowWrapper>
          </div>
        </Box>

        <Divider style={{ marginTop: '10px' }} />

        {showHelperText() && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '5px',
            }}
          >
            <Text color="red" size="14px" width="100%" justifyContent="center">
              Please enter amounts from supplier invoice
            </Text>
          </div>
        )}

        <Footer
          setOpen={setOpen}
          handleSubmit={() => handleCreateInvoice()}
          confirmText="CONTINUE"
          disableSubmit={shouldDisableSubmit()}
        />
      </BoxStyled>
    </Modal>
  );
};

const ProductCodes = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: Barlow;
  font-weight: 600;
  font-size: 20px;
`;

export const LocationTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const LocationTypeText = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-right: 20px;
`;

export const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 24;
  min-height: 300px;
  padding: 24px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div<{
  size?: string;
  color?: string;
  width?: string;
  justifyContent?: string;
}>`
  font-family: Barlow;
  font-weight: 400;
  font-size: ${(props) => props.size ?? '16px'};
  color: ${(props) => props.color ?? 'black'};
  width: ${(props) => props.width ?? 'calc(100% - 300px)'};
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? ''};
`;

const Price = styled.div<{ color?: string; weight: string; size: string }>`
  font-family: Barlow;
  color: ${(props) => props.color ?? 'black'};
  font-size: ${(props) => props.size ?? '16px'};
  font-weight: ${(props) => props.weight ?? '400px'};
  width: 150px;
  display: flex;
  justify-content: center;
`;
