import { GLOBAL_RULESET } from '../global';
import { TRule } from '../../typings';

export const TYROO_CATALOG_RULESET: TRule[] = [
  // Category types
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'in',
    conditionValue:
      'OFF ROAD SO|OFF ROAD WI|OFF ROAD SO RFT|OFF ROAD GJ|OFF ROAD WI RFT|OFF ROAD GJ RFT',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '31',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'in',
    conditionValue:
      'PKW WI/Q|PKW SO/T|PKW GJ/T|PKW WI/T|PKW SO Special|Winter HR|PKW SO/H|PKW GJ/H|PKW GJ Special|Klassik Winter|PKW WI/H|PKW SO/V|PKW GJ/V|PKW WI/H RFT|PKW SO/V RFT|PKW GJ/H ROF|PKlassik|PKW SO/Z|PKW WI/V|PKW SO/H RFT|PKW WI/V RFT|PKW SO/Z RFT|PKW GJ/Z|PKW GJ/V ROF|PKW WI/Z|PKW WI/Z RFT|PKW SO Klassik|PKW SO Rennsport|PKW SO Special|PKW So/Q|PKW WI/T RFT',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '30',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'in',
    conditionValue: 'LLKW SO|LLKW WI|LLKW GJ',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '32',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'equals',
    conditionValue: 'Rennsport',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '33',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'in',
    conditionValue: 'Stahlfelgen|Felgen Stahl(eint./HS)',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '73',
    outputType: 'forced_integer',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'equals',
    conditionValue: 'Alu Felgen',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '72',
    outputType: 'forced_integer',
  },
  // {
  //   type: 'rule.condition',
  //   sourceKey: "c['Tyroo goods group name']",
  //   condition: 'in',
  //   conditionValue:
  //     'Diagonal/Strasse|Diagonal/Gelände|Geländesport NHS|Motorrad|Radial/Strasse|Radial/Gelände|Classic Racing',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   outputValue: '36',
  //   outputType: 'forced_integer',
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: "c['Tyroo goods group name']",
  //   condition: 'equals',
  //   conditionValue: 'Strassensport NHS',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   outputValue: '40',
  //   outputType: 'forced_integer',
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: "c['Tyroo goods group name']",
  //   condition: 'in',
  //   conditionValue: 'Roller Diagonal|Roller Diagonal Winter|Roller Radial',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   outputValue: '37',
  //   outputType: 'forced_integer',
  // },
  // {
  //   type: 'rule.condition',
  //   sourceKey: "c['Tyroo goods group name']",
  //   condition: 'equals',
  //   conditionValue: 'Mofa/Mopedreifen',
  //   outputLogic: 'custom',
  //   destination: 'model',
  //   targetKey: 'category_id',
  //   outputValue: '39',
  //   outputType: 'forced_integer',
  // },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'in',
    conditionValue: 'LKW|LLKW bis 17.5|LLKW/LKW (diagonal)',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'category_id',
    outputValue: '50',
    outputType: 'forced_integer',
  },
  // Brand
  {
    type: 'rule.map',
    sourceKey: 'c.producer',
    targetKey: 'brand',
    destination: 'model',
    outputType: 'string',
  },
  // Season
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'season',
    template: 'summer',
    ignoreForCategories: [72, 73], // Wheels
  },
  // {
  //   type: 'rule.condition_boolean',
  //   sourceKey: "c['Tyroo goods group name']",
  //   condition: 'contains',
  //   conditionValues: ['GJ', 'WI'],
  //   conditionOutputs: ['allseason', 'winter'],
  //   destination: 'model',
  //   targetKey: 'season',
  //   outputType: 'string',
  // },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'contains',
    conditionValue: 'GJ',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'allseason',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: "c['Tyroo goods group name']",
    condition: 'contains',
    conditionValue: 'WI',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'season',
    outputValue: 'winter',
    outputType: 'string',
  },
  // Model name - will be generated from Special Markings for carTyres
  {
    type: 'rule.map',
    sourceKey: 'c.profile',
    targetKey: 'modelName',
    destination: 'model',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.global.operate',
    destination: 'model',
    targetKey: 'modelName',
    operationName: 'getTyrooMotoModel',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  // EAN
  {
    type: 'rule.map',
    sourceKey: 'c.EAN',
    targetKey: 'ean',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["ID number producer"]',
    targetKey: 'manufacturerCode',
    destination: 'product',
    outputType: 'string',
  },
  // Dimensions
  {
    type: 'rule.map',
    sourceKey: 'c.width',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c["rim width"]',
    targetKey: 'width',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
    postProcess: ['trimTrailingZeroes'],
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.height',
    condition: 'equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'height',
    outputValue: 'R',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.cross-section',
    targetKey: 'height',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.height',
    condition: 'equals',
    conditionValue: '',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'height',
    outputValue: 'R',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.inch',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.map',
    sourceKey: 'c.["rim inch"]',
    targetKey: 'rimDiameter',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'construction',
    operationName: 'globalGetTyrooConstruction',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.replace',
    targetKey: 't.product.rimDiameter',
    matchValue: ['C', 'R'],
    replaceValue: ['', ''],
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'XL',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'XL',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'Reinf',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'rfd',
    outputValue: 'REINF',
    outputType: 'string',
  },
  // Load index and Speed Index
  {
    type: 'rule.map',
    sourceKey: 'c.loadindex',
    targetKey: 'loadIndex',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c.speedindex',
    targetKey: 'speedIndex',
    destination: 'product',
    outputType: 'string',
    ignoreForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'specialMarking',
    operationName: 'globalGetTyrooSpecialMarkings',
    applyOnlyForCategories: [30, 31, 32, 33, 50], // carTyres
  },
  // ThreePMFs
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c.3PMSF',
    condition: 'equals',
    conditionValues: ['N', 'J'],
    conditionOutputs: [false, true],
    destination: 'product',
    targetKey: 'threepmfs',
    outputType: 'boolean',
  },
  // iceGrip
  {
    type: 'rule.condition_boolean',
    sourceKey: 'c["ice grip"]',
    condition: 'equals',
    conditionValues: ['J', 'N'],
    conditionOutputs: [true, false],
    destination: 'product',
    targetKey: 'iceGrip',
    outputType: 'boolean',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.model.season',
    condition: 'equals',
    conditionValue: 'summer',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'iceGrip',
    outputValue: false,
    outputType: 'boolean',
  },
  // Globalni fieldovi
  {
    type: 'rule.map',
    sourceKey: 'c["energy efficiency class"]',
    targetKey: 'rollingResistance',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["wet grip class"]',
    targetKey: 'wetGrip',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["db value"]',
    targetKey: 'noisePerformance',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["external noise class"]',
    targetKey: 'noiseClassType',
    destination: 'product',
    outputType: 'string',
    applyOnlyForCategories: [30, 31, 32, 33], // carTyres
  },
  {
    type: 'rule.map',
    sourceKey: 'c["EU regulation"]',
    targetKey: 'euDirectiveNumber',
    destination: 'product',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 't.product.euDirectiveNumber',
    condition: 'equals',
    conditionValue: '0',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'euDirectiveNumber',
    outputValue: '',
    outputType: 'string',
  },
  {
    type: 'rule.operate',
    sourceKey: "c['EPREL-DB-link']",
    destination: 'product',
    targetKey: 'eprelId',
    operationName: 'getTyrooEprelId',
  },
  {
    type: 'rule.map',
    sourceKey: 't.product.eprelId',
    targetKey: 'eprelUrl',
    destination: 'product',
    postProcess: ['getEprelUrlFromId'],
  },
  {
    type: 'rule.global.operate',
    destination: 'product',
    targetKey: 'pcd',
    operationName: 'getTyrooPCD',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map_array',
    sourceKey: 'c["rim offset"]',
    targetKey: 'et',
    delimiter: '|',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c["hub diameter"]',
    targetKey: 'cb',
    destination: 'product',
    outputType: 'number',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  {
    type: 'rule.map',
    sourceKey: 'c["image url"]',
    targetKey: 'originalPhotos',
    destination: 'model',
    outputType: 'string',
  },
  // Tube Type - moto
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'TT',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'tubeType',
    outputValue: 'TT',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'TL',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'tubeType',
    outputValue: 'TL',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'TL/TT',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'tubeType',
    outputValue: 'TL/TT',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  // Axle - moto
  {
    type: 'rule.template',
    destination: 'model',
    targetKey: 'wheelPosition',
    template: 'front/rear',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'front',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'front',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'Front',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'front',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'rear',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'rear',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'Rear',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'rear',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'front/rear',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'front/rear',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'Front/rear',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'front/rear',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  {
    type: 'rule.condition',
    sourceKey: 'c["article information"]',
    condition: 'contains',
    conditionValue: 'Front/Rear',
    outputLogic: 'custom',
    destination: 'model',
    targetKey: 'wheelPosition',
    outputValue: 'front/rear',
    outputType: 'string',
    applyOnlyForCategories: [36, 40, 37, 39], // motoTyres
  },
  // Sidewall
  {
    type: 'rule.condition',
    sourceKey: 'c.profile',
    condition: 'contains',
    conditionValue: 'WW',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'sidewall',
    outputValue: 'WW',
    outputType: 'string',
  },
  {
    type: 'rule.condition',
    sourceKey: 'c.profile',
    condition: 'contains',
    conditionValue: 'WWW',
    outputLogic: 'custom',
    destination: 'product',
    targetKey: 'sidewall',
    outputValue: 'WWW',
    outputType: 'string',
  },
  // Color
  {
    type: 'rule.map',
    sourceKey: 'c["rim colur"]',
    targetKey: 'color',
    destination: 'model',
    outputType: 'string',
    applyOnlyForCategories: [72, 73], // Wheels
  },
  // Flank
  // {
  //   type: 'rule.condition',
  //   sourceKey: 'c["article information"]',
  //   condition: 'contains',
  //   conditionValue: ' FP',
  //   outputLogic: 'custom',
  //   destination: 'product',
  //   targetKey: 'flank',
  //   outputValue: true,
  //   outputType: 'boolean',
  // },
  ...GLOBAL_RULESET,
];
