import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { isEmpty, isUndefined } from 'lodash';
import { useContext } from 'react';
import { SideMenuLayout } from '../../../components/SideMenuLayout/SideMenuLayout';
import { POSContext } from '../../../context/POSContext';
import { AddItemsFooter } from '../components/AddItemsFooter';
import { StockInfo } from '../components/StockInfo/StockInfo';

import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../components/SearchPOS/SearchContext';
import { useVehicleCtx } from '../../../context/VehicleContext';
import { usePosCartCtx } from '../../../context/PosCartContext';
import { POSDetailsContext } from '../../../context/POSDetails';

export const Branches = () => {
  const { branchesStock } = useContext(POSDetailsContext);
  const { selectedTableRow } = useContext(POSContext);

  const { activeTab } = usePosSearchContext();
  const { selectedProduct } = useVehicleCtx();
  const { cartData } = usePosCartCtx();

  const unselectedRowCondition =
    (activeTab === PosSearchActiveTab.default_search && !selectedTableRow) ||
    (activeTab === PosSearchActiveTab.wheels_search && !selectedProduct);

  const renderBranches = () => {
    if (!isUndefined(branchesStock) && !isEmpty(branchesStock))
      return (
        <StockInfo
          data={branchesStock}
          text={'BRANCHES STOCK'}
          showText={false}
        />
      );
    else if (unselectedRowCondition)
      return <UnselectedRowWarning icon={wmsIcons.branches} text="branches" />;
    else
      return (
        <UnselectedRowWarning
          icon={wmsIcons.branches}
          emptyData="No available items for this product"
        />
      );
  };

  return (
    <SideMenuLayout
      type="branches"
      children={renderBranches()}
      showSwitch={false}
      checked={false}
      footer={
        !isUndefined(branchesStock) &&
        !isEmpty(branchesStock) &&
        cartData.length > 0 && <AddItemsFooter />
      }
    />
  );
};
