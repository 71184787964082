import _ from 'lodash';
import { CartData } from '../../../../../context/PosCartContext';

export const useCalculateData = (items: CartData[]) => {
  let totalQuantity = 0;
  let totalPrice = 0;
  let avgPrice = 0;

  if (items.length === 0) return { totalQuantity, totalPrice, avgPrice };

  // total quantity of the added items
  totalQuantity = items.reduce((acc, curr) => acc + curr.req.quantity, 0);
  // total price of added items
  totalPrice = items.reduce((acc, curr) => acc + curr.req.price, 0);

  // average price
  avgPrice = totalPrice / totalQuantity;
  avgPrice = Number(avgPrice.toFixed(2));

  return { totalQuantity, totalPrice, avgPrice };
};

// add function for calculation modal data prices
export const useCalculatePrice = (
  items: CartData[],
  delivery: number,
  isVatIncluded?: boolean,
  rebate?: number,
  discount?: number,
  applyCashDiscount?: boolean
) => {
  let total = 0;
  let grandTotal = 0;
  let vat = 0;
  let deliveryPrice = 0;
  let prices = {
    itemsPrice: 0,
    servicesPrice: 0,
    itemsInclVat: 0,
    servicesInclVat: 0,
    servicesExclVat: 0,
    subTotalExlVat: 0,
    itemsCashPrice: 0,
    itemsCashInclVat: 0,
    subTotalCashExlVat: 0,
  };
  let cashGrandTotal = 0;
  let cashTotal = 0;

  if (items && items.length > 0) {
    const useCashPrice = applyCashDiscount && items[0]?.req?.cashPrice;

    const price =
      useCashPrice && items[0].req.cashPrice
        ? items[0].req.cashPrice
        : items[0].req.price;

    const vatPrice =
      useCashPrice && items[0].req.vatCashPrice
        ? items[0].req.vatCashPrice
        : items[0].req.inclusiveVat;

    vat = items.length > 0 ? ((vatPrice - price) * 100) / price : 0;
    vat = Math.round(vat);

    deliveryPrice = !isVatIncluded
      ? delivery
      : delivery + delivery * (vat / 100);

    prices = items.reduce(
      (acc, curr) => {
        const { stockType, req } = curr;
        const { price, inclusiveVat, quantity, cashPrice, vatCashPrice } = req;

        let finalPrice = isVatIncluded ? inclusiveVat ?? 0 : price;
        let finalCashPrice = isVatIncluded ? vatCashPrice ?? 0 : cashPrice ?? 0;

        let inclusiveVatWithDiscount = inclusiveVat;
        let inclusiveCashVatDiscount = vatCashPrice ?? 0;
        let priceWithDiscount = price;
        let cashPriceWithDiscount = cashPrice ?? 0;

        if (rebate && rebate !== 0) {
          const decRebate = rebate / 100;

          finalPrice -= finalPrice * decRebate;
          finalCashPrice -= finalCashPrice * decRebate;
          inclusiveVatWithDiscount -= inclusiveVatWithDiscount * decRebate;
          inclusiveCashVatDiscount -= inclusiveCashVatDiscount * decRebate;
          priceWithDiscount -= priceWithDiscount * decRebate;
          cashPriceWithDiscount -= cashPriceWithDiscount * decRebate;
        }

        if (discount && discount !== 0) {
          const decDiscount = discount / 100;

          finalPrice -= finalPrice * decDiscount;
          finalCashPrice -= finalCashPrice * decDiscount;
          inclusiveVatWithDiscount -= inclusiveVatWithDiscount * decDiscount;
          inclusiveCashVatDiscount -= inclusiveCashVatDiscount * decDiscount;
          priceWithDiscount -= priceWithDiscount * decDiscount;
          cashPriceWithDiscount -= cashPriceWithDiscount * decDiscount;
        }

        const totalPrice = finalPrice * quantity;
        const totalCashPrice = finalCashPrice * quantity;

        if (stockType === 'SERVICES') {
          acc.servicesPrice += totalPrice;
          acc.servicesInclVat += price * quantity;
          acc.servicesExclVat += price * quantity;
        } else {
          // regular price
          acc.itemsPrice += totalPrice;
          acc.itemsInclVat += inclusiveVatWithDiscount * quantity;
          acc.subTotalExlVat += priceWithDiscount * quantity;
          // cash price
          acc.itemsCashPrice += totalCashPrice;
          acc.itemsCashInclVat += inclusiveCashVatDiscount * quantity;
          acc.subTotalCashExlVat += cashPriceWithDiscount * quantity;
        }

        return acc;
      },
      {
        itemsPrice: 0,
        itemsCashPrice: 0,
        servicesPrice: 0,
        itemsInclVat: 0,
        itemsCashInclVat: 0,
        servicesInclVat: 0,
        servicesExclVat: 0,
        subTotalExlVat: 0,
        subTotalCashExlVat: 0,
      }
    );

    prices.servicesPrice += deliveryPrice;
    total = prices.itemsPrice + prices.servicesPrice;
    cashTotal = prices.itemsCashPrice + prices.servicesPrice;
    grandTotal = _.round(
      prices.itemsInclVat +
        prices.servicesInclVat +
        delivery +
        delivery * (vat / 100),
      2
    );
    cashGrandTotal = _.round(
      prices.itemsCashInclVat +
        prices.servicesInclVat +
        delivery +
        delivery * (vat / 100),
      2
    );
  }

  return {
    itemsPrice: prices.itemsPrice,
    servicesPrice: prices.servicesPrice,
    total,
    subTotalExlVat: prices.subTotalExlVat + prices.servicesExclVat + delivery,
    grandTotal,
    vat,
    cashGrandTotal,
    cashTotal,
    subTotalCashExlVat:
      prices.subTotalCashExlVat + prices.servicesExclVat + delivery,
    itemsCashPrice: prices.itemsCashPrice,
  };
};
