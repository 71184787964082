import {
  BranchesStockResponse,
  DBPosTypesApi,
  SupplierStockResponse,
} from '@tyrio/dto';
import { useContext } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { StockItems } from '../StockItemDetails/StockItems';
import { StockInfoHeader } from './StockInfoHeader';

interface StockInfoProps {
  data: BranchesStockResponse[] | SupplierStockResponse[];
  text:
    | 'BRANCHES STOCK'
    | 'SUPPLIERS STOCK'
    | 'ON STOCK'
    | 'UPCOMING ON STOCK'
    | '';
  showText?: boolean;
  showVisibility?: boolean;
}

export const StockInfo = ({
  data,
  text,
  showText = true,
  showVisibility = false,
}: StockInfoProps) => {
  const highlightExtremeDates = () => {
    const dates = data.map(
      (d) =>
        new Date(d.deliveryDate.split(' - ')[0].split('.').reverse().join('-'))
    );
    const minTimestamp = Math.min(...dates.map((date) => date.getTime()));
    const maxTimestamp = Math.max(...dates.map((date) => date.getTime()));

    const minDate = new Date(minTimestamp);
    const maxDate = new Date(maxTimestamp);

    const formatDate = (date: Date) =>
      `${String(date.getDate()).padStart(2, '0')}.${String(
        date.getMonth() + 1
      ).padStart(2, '0')}.${date.getFullYear()}`;

    const minCount = dates.filter(
      (date) => date.getTime() === minTimestamp
    ).length;
    const maxCount = dates.filter(
      (date) => date.getTime() === maxTimestamp
    ).length;

    return {
      min: minCount === 1 ? formatDate(minDate) : '',
      max: maxCount === 1 ? formatDate(maxDate) : '',
    };
  };
  return (
    <div>
      {data.length > 0 && (
        <div>
          {text && showText && (
            <StockInfoHeader text={text} showVisibility={showVisibility} />
          )}
          {data.map(
            (
              item: BranchesStockResponse | SupplierStockResponse,
              idx: number
            ) => (
              <StockItems
                data={item}
                stockType={text ?? ''}
                key={
                  text !== 'SUPPLIERS STOCK'
                    ? (item as unknown as BranchesStockResponse).branchId
                    : (item as unknown as SupplierStockResponse).supplierName
                }
                index={idx + 1}
                extremeDates={
                  text === 'SUPPLIERS STOCK'
                    ? highlightExtremeDates()
                    : { min: '', max: '' }
                }
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
