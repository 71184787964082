import React, { Dispatch, SetStateAction } from 'react';
import { DBCustomerOrderItemResponse, DBSalesOrdersApi } from '@tyrio/dto';

export interface SalesOrderQty {
  supplier: Record<string, Record<string, number>>;
  stock: Record<string, Record<string, number>>;
}

export interface SalesOrdersFlowContextShape {
  customerOrderId: string;
  dirty: boolean;
  data: DBSalesOrdersApi['prepareOrder']['response'];
  /**
   * `supplierId => productId`
   */
  qty: {
    supplier: Record<string, Record<string, number>>;
    stock: Record<string, Record<string, number>>;
  };
  setQty: (
    supplierId: string,
    productId: string,
    qty: number,
    key: 'supplier' | 'stock',
    hardSet?: boolean
  ) => void;
  /**
   * `productId => supplierId`
   */
  selected: Record<string, string>;
  setSelected: (supplierId: string, productId: string) => void;
  setScreen: (screen: 'process_order' | 'purchase_order') => void;
  screen: 'process_order' | 'purchase_order';
  batchQtyUpdate: (val: SalesOrderQty) => void;
  isDropshipping: boolean;
  setIsDropshipping: (val: boolean) => void;
  codAmount: number;
  setCodAmount: (val: number) => void;
  customerOrder: DBCustomerOrderItemResponse | null;
  setCustomerOrder: Dispatch<
    SetStateAction<DBCustomerOrderItemResponse | null>
  >;
  isFillQtyDone: boolean;
  setIsFillQtyDone: (value: boolean) => void;
}
export const SalesOrdersFlowContext =
  React.createContext<SalesOrdersFlowContextShape>({
    customerOrderId: '',
    dirty: false,
    data: {
      orderRef: '',
      products: {},
      requested: {},
      suppliers: {},
      supplierQty: {},
      prices: {},
      deliveryDates: {},
      purchased: {},
      warehouses: {},
      warehouseQty: {},
      lineItems: [],
      historySupplier: {},
      clientSupplierConfigsMap: {},
    },
    selected: {},
    qty: { supplier: {}, stock: {} },
    setQty: (_supplierId: string, _productId: string, _qty: number) => null,
    setSelected: (_supplierId: string, _productId: string) => null,
    setScreen: (_screen: 'process_order' | 'purchase_order') => null,
    screen: 'process_order',
    batchQtyUpdate: () => null,
    isDropshipping: false,
    setIsDropshipping: () => false,
    codAmount: 0,
    setCodAmount: () => null,
    customerOrder: null,
    setCustomerOrder: () => null,
    isFillQtyDone: false,
    setIsFillQtyDone: () => null,
  });
