import styled from '@emotion/styled';
import { SearchOutlined } from '@mui/icons-material';
import { Divider, InputAdornment, TextField } from '@mui/material';
import { DBService } from '@prisma/client';
import { useContext } from 'react';
import { ServiceCard } from './ServiceCard';
import { POSDetailsContext } from '../../../../context/POSDetails';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';

export const Services = () => {
  const { servicesItems, searchService, setSearchService } =
    useContext(POSDetailsContext);

  const { recommended, services } = servicesItems;
  const renderServices = () => {
    return (
      <div>
        <Wrapper>
          {/* Recommended services */}
          {recommended?.length > 0 && (
            <div style={{ width: '100%' }}>
              {recommended.map((item: DBService, index: number) => (
                <ServiceCard key={index} service={item} />
              ))}
              <Divider style={{ width: '100%', border: '2px solid #DFE3E8' }} />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              width: '100%',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '50%',
              }}
              value={searchService}
              onChange={(e) => {
                setSearchService(e.target.value);
              }}
              disabled={recommended.length === 0 && searchService === ''}
            />
          </div>

          <Divider
            style={{
              width: '100%',
              border: '2px solid #DFE3E8',
            }}
          />
          {services.length > 0 ? (
            <ServicesWrapper>
              {services?.map((item: DBService, index: number) => (
                <ServiceCard key={index} service={item} />
              ))}
            </ServicesWrapper>
          ) : (
            <div>{searchService !== '' ? 'No result' : 'No data'}</div>
          )}
        </Wrapper>
      </div>
    );
  };

  return (
    <SideMenuLayout
      type="services"
      children={renderServices()}
      showSwitch={false}
      checked={false}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;
  width: 100%;
`;

const ServicesWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;
