import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import api from '@tyrio/api-factory';
import { DBServicesApi } from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioIcons,
} from '@tyrio/ui-library';
import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { CartFooter } from '../../components/Cart/Footer';
import { CartHeader } from '../../components/Cart/Header';
import { PageTemplateContent } from '../../components/PageTemplate/PageTemplate';
import {
  initializeInput,
  PosCartContext,
  StockType,
} from '../../context/PosCartContext';
import { POSContext } from '../../context/POSContext';
import { useWS } from '../../context/WSContext';
import { useCalculatePrice } from './steps/Cart/helpers/calculation';
import { usePosCartData } from './steps/Cart/helpers/cart-data';
import { Recommended } from './steps/Cart/Recommended/Recommended';
import { Table } from './steps/Cart/Table/Table';
import { useCurrentPOSCartData } from './steps/Cart/helpers/barcode';

const CartLanding = () => {
  const ws = useWS();
  const {
    setCartData,
    setRecommended,
    input,
    setInput,
    clearCustomerDetails,
    timer,
    clearVehicleForm,
    setPaymentDetails,
    deliveryPrice,
    scannedBarcode,
    setScannedBarcode,
  } = useContext(PosCartContext);

  const {
    setBadgeContent,
    selectedCustomer,
    selectedWarehouseId,
    isVatIncluded,
  } = useContext(POSContext);
  const { modalData } = usePosCartData();

  const { addExisting, isProductInCart, doesProductExist, addNew } =
    useCurrentPOSCartData();

  const { itemsPrice, servicesPrice, total, subTotalExlVat, grandTotal, vat } =
    useCalculatePrice(
      modalData,
      deliveryPrice,
      isVatIncluded,
      selectedCustomer?.rebateCalculation?.rebate,
      selectedCustomer?.rebateCalculation?.discount
    );

  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);

  const eans = useMemo(() => {
    return modalData
      .filter((item) => item.stockType !== ('SERVICES' as StockType))
      .map((item) => item.req.ean);
  }, [modalData]);

  const { data } = useQuery(
    [
      'get_recommended_services',
      { branchId: selectedWarehouseId?.toString(), eans },
    ],
    async () => {
      return await api.fetch<DBServicesApi['recommended']>(
        'get_recommended_services',
        { branchId: selectedWarehouseId?.toString(), eans }
      );
    },
    {
      onSuccess: (data) => {
        setRecommended(data);
      },
    }
  );

  const handleDelete = () => {
    setCartData([]);
    setBadgeContent((prevState) => ({
      BRANCHES: prevState.BRANCHES,
      SUPPLIERS: prevState.SUPPLIERS,
      CART: 0,
    }));
    timer?.clear();
    ws.socket?.emit('remove-all-pos-cart-items');
    if (!input.isTyrioCustomer || selectedCustomer === null) {
      setInput(initializeInput());
      clearCustomerDetails();
      clearVehicleForm();
      setPaymentDetails([]);
    }
  };

  const handleBarcode = () => {
    if (!doesProductExist()) {
      ToastHelper.showToast(
        'Wrong product',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        `Product ${scannedBarcode} does not exist!`
      );
      setScannedBarcode('');
      return;
    }
    isProductInCart() ? addExisting() : addNew();
  };

  return (
    <Container>
      {data && data?.length > 0 && (
        <PageTemplateContent
          style={{
            width: '100%',
            maxWidth: '200px',
            transition: 'all 0.3s ease',
            backgroundColor: 'transparent',
            maxHeight: 'calc(100svh - 44px)',
          }}
        >
          <Recommended />
        </PageTemplateContent>
      )}

      <PageTemplateContent
        style={{
          width: '200px',
          transition: 'all 0.3s ease',
        }}
      >
        <CartHeader onDelete={handleDelete} text="CART" />
        <Table />
        <VatText color={isVatIncluded ? '#1976D2' : 'red'}>
          {isVatIncluded ? 'prices inclusive VAT' : 'prices exclusive VAT'}
        </VatText>
        <FooterWrrapper>
          <BarcodeInput isOpen={isBarcodeOpen}>
            <TextField
              placeholder="scan barcode to add more items"
              sx={{
                cursor: 'pointer',
                '.MuiOutlinedInput-root': {
                  cursor: 'pointer',
                },
              }}
              InputProps={{
                startAdornment: (
                  <img
                    src={tyrioIcons.barcodeIcon}
                    alt="barcode-icon"
                    style={{
                      opacity: '0.7',
                      height: '30px',
                      marginRight: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsBarcodeOpen(true)}
                  />
                ),
              }}
              value={scannedBarcode}
              onChange={(e) => setScannedBarcode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleBarcode();
                }
              }}
              onClick={() => setIsBarcodeOpen(true)}
              autoFocus
            />
          </BarcodeInput>
          <CartFooter
            isVatIncluded={isVatIncluded}
            amount1={itemsPrice}
            amount2={servicesPrice}
            total={total}
            subTotalExlVat={subTotalExlVat}
            grandTotal={grandTotal}
            vat={vat}
          />
        </FooterWrrapper>
      </PageTemplateContent>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const FooterWrrapper = styled.div`
  position: sticky;
  top: 100%;
`;

const VatText = styled.div<{ color: string }>`
  width: 100%;
  font-family: Barlow;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: end;
  color: ${(props) => props.color};
  padding: 10px;
  padding-right: 40px;
`;

const BarcodeInput = styled.div<{ isOpen: boolean }>`
  padding: 10px;
  margin: 16px;
  width: ${(props) => (props.isOpen ? '95%' : '80px')};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export default CartLanding;
