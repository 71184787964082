import styled from '@emotion/styled';
import _ from 'lodash';
import { useContext } from 'react';
import {
  ColumnCardWrapper,
  HeaderText,
} from '../../../steps/Search/SelectStepper';
import { PosSearchContext } from '../SearchContext';
import { POSContext } from '../../../../../context/POSContext';
import { useGetPosFilters } from '../query/get-filter';
import { BRANDS } from '@tyrio/shared-vars';
import { InputOption } from '@tyrio/dto';

const brandClasses = [
  {
    label: 'Premium class',
    value: 'premium',
    color: '#1976D2',
  },
  { label: 'Mid class', value: 'mid', color: '#38A169' },
  { label: 'Economy class', value: 'economy', color: '#E75524' },
  { label: 'Budget class', value: 'budget', color: '#212B36' },
];

export const ClassSelector = () => {
  const { selectedSizes, setSupplierPage } = useContext(PosSearchContext);
  const { setSupplierStockItems, filterValues, setFilterValue, shouldRefetch } =
    useContext(POSContext);
  const { filtersPos } = useGetPosFilters(filterValues, shouldRefetch);

  const posBrands = Object.keys(BRANDS).filter((item) =>
    filtersPos?.['brands']?.includes(item as string & InputOption)
  );

  const getBrandsByClass = (classNumber: number) => {
    return posBrands
      .filter((item) => BRANDS[item].brandClass === classNumber)
      .map((item) => BRANDS[item].name);
  };
  const brandClassesOptions = {
    PREMIUM: getBrandsByClass(1).sort(),
    MID: getBrandsByClass(2).sort(),
    ECONOMY: getBrandsByClass(3).sort(),
    BUDGET: getBrandsByClass(4).sort(),
  };

  const getBrandsByClassName = (value: string) => {
    return brandClassesOptions?.[
      value as keyof typeof brandClassesOptions
    ] as string[];
  };
  const get = (path: string) => _.get(filterValues, path, []) as string[];

  const selectedBrandClasses: string[] = Array.isArray(get('brand'))
    ? get('brand')
    : Object.values(get('brand') as object);

  const handleChangeClassValue = (value: boolean, key: string) => {
    let classValues = selectedBrandClasses;
    if (value)
      classValues = [
        ...classValues,
        key.toUpperCase(),
        ...getBrandsByClassName(key.toUpperCase()),
      ];
    else
      classValues = selectedBrandClasses.filter(
        (el) =>
          el !== key.toUpperCase() &&
          ![...getBrandsByClassName(key.toUpperCase())].includes(el)
      );
    setFilterValue({
      ...filterValues,
      size: selectedSizes as string[],
      brand: [...new Set(classValues)] as string[],
    });

    setSupplierStockItems({ matchedSets: [], unmatchedItems: [] });
    setSupplierPage(0);
    // Scroll to the top of the table
    const posTable = document.getElementById('pos-table');
    posTable?.scroll({ top: 0, behavior: 'smooth' });
  };

  return (
    <ColumnCardWrapper
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        flexDirection: 'row',
        marginTop: '14px',
      }}
    >
      <HeaderText style={{ width: '100%', textAlign: 'center' }}>
        Filter brand class
      </HeaderText>
      {brandClasses.map((item) => (
        <BrandClassCard
          onClick={() =>
            handleChangeClassValue(
              !selectedBrandClasses?.includes(item.value.toUpperCase()),
              item.value
            )
          }
          selected={selectedBrandClasses?.includes(item.value.toUpperCase())}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <StyledCheckbox
              type="checkbox"
              checked={selectedBrandClasses?.includes(item.value.toUpperCase())}
              onChange={() => null}
            />
            {item.label}
          </div>
          <Divider background={item.color} />
        </BrandClassCard>
      ))}
    </ColumnCardWrapper>
  );
};

const BrandClassCard = styled.button<{ selected: boolean }>`
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-radius: 8px;
  margin-top: 16px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #212b36;
  cursor: pointer;
  background: ${(props) =>
    props.selected ? 'rgba(25, 118, 210, 0.15)' : 'none'};
  border: ${(props) =>
    props.selected ? '2px solid #1976d2' : '1px solid #dfe3e8'};

  &:hover {
    border: 2px solid #1976d2;
  }
`;

const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
`;

const Divider = styled.div<{ background?: string }>`
  height: 32px;
  width: 8px;
  background: ${(props) => props.background};
`;
