import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { NEWITEMS_RULESET } from './newitems/catalog';
import { TRule } from '../typings';

export const NEWITEMS_SUPPLIER: SupplierShape = {
  weight: 5,
  override: true,
  ruleset: NEWITEMS_RULESET,
  pricelistRuleset: [] as TRule[],
  stockRuleset: [] as TRule[],
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: true,
  preprocessKeys: {
    width: {
      ',': '.',
      '"': '',
      x: '',
      X: '',
    },
    color: {
      '�': 'a',
      'ÔøΩ': 'a',
    },
    Brand: {
      'A-PLUS': 'APLUS',
      'AANHANGER STAAL': 'AANHANGER',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      dataSource: 'NEWITEMS',
    },
    model: {
      dirtyFields: '{}',
      dataSource: 'NEWITEMS',
      // sidewall: 'WW',
    },
  },

  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return json;
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    // console.log(`** Attempting to import ${json.length} lines.`);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
