import {
  DBOrderStatus,
  DBStockInItemStatus,
  WorkOrdersStatus,
} from '@prisma/client';
import {
  DBCustomerOrderStatusEnum,
  DBTransferOrderStatusEnum,
} from '@tyrio/dto';

export interface OrderLineItem {
  backorder: number;
  confirmed: number;
  ean: string;
  ipc: string;
  orderTimestamp: Date;
  price: number;
  productName: string;
  sku: string;
  total: number | null;
  uid: string;
  qty: number;
}

export interface tempOrderItem {
  item: {
    productName: string;
    ean: string;
    ipc: string;
    sku: string;
  };
  price: number;
  qty: number;
  total: number;
  ordered: number;
  confirmed: number;
  backorder: number;
  received: number;
  status: {
    type: DBOrderStatus;
    date: string;
  };
}

export const getOrderStatusColor = (status: DBOrderStatus) => {
  if (status === DBOrderStatus.BACKORDER) return 'lightblue';
  if (status === DBOrderStatus.CANCELED) return '#E22A2A';
  if (status === DBOrderStatus.DELIVERED) return '#3EB274';
  if (status === DBOrderStatus.DELIVERY_LATE) return '#994256';
  if (status === DBOrderStatus.DRAFT) return '#2196f3';
  if (status === DBOrderStatus.IN_TRANSIT) return '#b2a429';
  if (status === DBOrderStatus.MISSING) return 'darkred';
  if (status === DBOrderStatus.ORDERED) return 'grey';
  if (status === DBOrderStatus.PROCESSING) return '#b26a00';
  if (status === DBOrderStatus.FINISHED) return 'green';
  if (status === DBOrderStatus.STORNED) return 'red';
  if (status === DBOrderStatus.CONFIRMED) return 'green';
  return 'white';
};

export const getTransferOrderStatusColor: Record<
  DBTransferOrderStatusEnum,
  string
> = {
  IN_PROGRESS: '#FFCC4D',
  NEW: '#FFFFFF',
  IN_PROCESS: '#1976D2',
  ON_HOLD: '#FFA500',
  SHIPPED: '#38A169',
  COLLECTED: '#38A169',
  DELIVERED: '#38A169',
  CANCELLED: '#FF0000',
  COMPLETED: '#38A169',
};

export const getStockInOrderStatusColor: Record<DBStockInItemStatus, string> = {
  COMPLETED: '#3EB274',
  CANCELLED: '#DB1E1E',
  UNMATCHED: '#FFCC4D',
  UNCONFIRMED: '#FFCC4D',
  CONFIRMED: '#1976D2',
  // TODO: add unconfirmed and confirmed
};

export const CustomerOrderStatusColors: Record<
  DBCustomerOrderStatusEnum,
  string
> = {
  PENDING: '#FFD700',
  NEW: '#FFFFFF',
  ORDERED: '#9370DB',
  IN_PROCESS: '#1976D2',
  ON_HOLD: '#FFD700',
  SHIPPED: '#A3DFBF',
  DELIVERED: '#38A169',
  COLLECTED: '#38A169',
  FITTED: '#38A169',
  CANCELLED: '#FF0000',
  RETURN_REQUESTED: '#FFA500',
  RETURNED: '#38A169',
  EXCHANGE_REQUESTED: '#FFA500',
  EXCHANGED: '#38A169',
  DROPSHIPPING: '#38A169',
};

export const WorkOrderStatusColors: Record<WorkOrdersStatus, string> = {
  NEW: '#FFFFFF',
  CONFIRMED: 'black',
  COMPLETED: '#3EB274',
  CANCELLED: '#FF0000',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generateNumberColor = (key: string, order: OrderLineItem) => {
  // if (order.status.type === DBOrderStatus.MISSING) return '#E64B4B';
  // if (key === 'received' && order.status.type === DBOrderStatus.DELIVERED)
  //   return '#65C994';
  // if (key === 'backorder' && order.backorder > 0) return '#454F5B';
  // if (key === 'confirmed' && order.confirmed < order.ordered) return '#FFE9B3';
  // else
  return 'white';
};

export const data: tempOrderItem[] = [
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '0000000000000',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 0,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.MISSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 4,
    status: {
      type: DBOrderStatus.DELIVERED,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '0000000000001',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.ORDERED,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 200/30 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 200/30 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 4,
    backorder: 0,
    received: 0,
    status: {
      type: DBOrderStatus.DRAFT,
      date: '25.11.2022',
    },
  },
  {
    item: {
      productName: 'CONTINENTAL 195/65 R 15 91H WinterContact TS 870 M+S',
      ean: '4019238038217',
      ipc: '123456789',
      sku: '1234567',
    },
    price: 25,
    qty: 4,
    total: 100,
    ordered: 4,
    confirmed: 3,
    backorder: 1,
    received: 0,
    status: {
      type: DBOrderStatus.PROCESSING,
      date: '25.11.2022',
    },
  },
];
