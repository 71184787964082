import { InboundInvoiceItem, InboundLineItem, VatChargeType } from '@tyrio/dto';
import _ from 'lodash';

// table item calculation
export const useCalculatePricePerRow = (
  item: InboundLineItem,
  vatChargeType: VatChargeType,
  changingSellingPrice: boolean
) => {
  const vat = 0;
  let itemsPrice = 0;
  let grandTotal = 0;

  const customsPrice = vatChargeType === 'V05D' ? item.customsPrice : 0;

  const price =
    item.invoicePrice && item.invoicePrice !== 0
      ? item.invoicePrice
      : item.purchasePrice;

  const purchasePrice = item.quantity * price;

  const discountAmount = (purchasePrice * item.rebate) / 100;

  const priceWithRebate = purchasePrice - discountAmount;

  itemsPrice = priceWithRebate + item.variableCost + customsPrice;

  const totalRevenue = itemsPrice / item.quantity;

  item.sellingPrice = changingSellingPrice
    ? item.sellingPrice
    : totalRevenue + (item.margin / 100) * totalRevenue;

  item.margin = !changingSellingPrice
    ? item.margin
    : Math.round(
        Math.abs((totalRevenue - item.sellingPrice) / totalRevenue) * 100
      );

  grandTotal = item.sellingPrice * item.quantity;

  return {
    itemsPrice,
    grandTotal,
    vat,
  };
};

// footer calculation
export const useCalculatePrice = (
  items?: InboundInvoiceItem[],
  vatChargeType?: VatChargeType
) => {
  const vat = 25;
  let itemsPrice = 0;
  let totalQty = 0;
  let variableCost = 0;
  let customsTotal = 0;
  let subTotalExlVat = 0;
  let grandTotal = 0;
  let vatAmount = 0;
  let rounded = 0;

  if (items && items?.length > 0) {
    items.forEach((item) => {
      //quantity
      const itemQty = item.lineItems.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      // price
      const itemPrice = item.lineItems.reduce((acc, curr) => {
        const price =
          curr.invoicePrice && curr.invoicePrice !== 0
            ? curr.invoicePrice
            : curr.purchasePrice;

        const purchasePrice = curr.quantity * price;

        const discountAmount = (purchasePrice * curr.rebate) / 100;

        const priceWithRebate = _.round(purchasePrice - discountAmount, 2);

        return acc + priceWithRebate;
      }, 0);
      // variable cost
      const varCost = item.lineItems.reduce(
        (acc, curr) => acc + curr.variableCost,
        0
      );
      // customs
      const customs =
        vatChargeType !== 'V05D'
          ? 0
          : item.lineItems.reduce((acc, curr) => acc + curr.customsPrice, 0);
      totalQty += itemQty;
      itemsPrice += itemPrice;
      variableCost += varCost;
      customsTotal += customs;
    });

    subTotalExlVat = _.round(itemsPrice + variableCost, 2);

    vatAmount = (vat / 100) * subTotalExlVat;

    grandTotal =
      vatChargeType !== 'V05' ? subTotalExlVat : subTotalExlVat + vatAmount;

    rounded = Math.round(vatAmount * 100) / 100;
  }

  return {
    itemsPrice,
    subTotalExlVat,
    grandTotal: _.round(grandTotal, 2),
    vat,
    totalQty,
    variableCost,
    customsTotal,
    vatAmount: rounded,
  };
};
