import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { ZBR_CATALOG_RULESET } from './zbr/catalog';
import { ZBR_PRICELIST_RULESET } from './zbr/pricelist';
import { ZBR_STOCKLIST_RULESET } from './zbr/stocklist';

export const ZBR_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: ZBR_CATALOG_RULESET,
  pricelistRuleset: ZBR_PRICELIST_RULESET,
  stockRuleset: ZBR_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  preprocessKeys: {
    Hersteller: {
      'Cheng Shin': 'CST',
      'DUNLOP RENNSPORT': 'DUNLOP',
      'Heidenau Racing': 'HEIDENAU',
    },
    'TL/TT': {
      'TL*': 'TL',
      CTL: 'TL',
      'TT/TL': 'TL/TT',
    },
    'Made in': {
      YU: 'RS',
    },
    Profil: {
      'WEI�WAND': 'WEISSWAND',
      'L�NGE': 'LANGE',
      'AU�ERMIIG' : 'AUSSERMIIG',
      '�' : '',
    },
    'EK-Preis': {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  stockDefaults: {
    model: {},
    product: {},
  },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productMeta: '{}',
      dirtyFields: '{}',
      dataSource: 'ZBR',
    },
    model: {
      dirtyFields: '{}',
      season: '',
      usage: null,
      dataSource: 'ZBR',
    },
  },

  async preprocessPricelist(file: string) {
    const headers = [
      'Artnr',
      'EAN',
      'IPC',
      'Breite',
      'Dimension',
      'Trenner',
      'Zoll',
      'Load',
      'Speed',
      'Profil',
      'Hersteller',
      'Bestand',
      'KB-Preis',
      'EK-Preis',
      'Zusatz',
      'Gruppe',
      'TL/TT',
      'Front/Rear',
      'Made in',
      'weight',
      'Ident number',
      'Delivery date',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    // console.log('READ FILE', fileWithHeader);
    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !['Schnapper', 'Ventile', 'Werbematerial', 'Zubehör'].includes(
            item['Gruppe']
          ) &&
          (item['IPC'] || item['Artnr']) &&
          (!item['Zusatz'] ||
            (item['Zusatz'] && !item['Zusatz'].includes('DOT'))) &&
          item['Profil'] &&
          !item['Profil'].includes('DOT') &&
          (!item['Zoll'] || item['Zoll'].slice(-1) !== 'C')
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const headers = [
      'Artnr',
      'EAN',
      'IPC',
      'Breite',
      'Dimension',
      'Trenner',
      'Zoll',
      'Load',
      'Speed',
      'Profil',
      'Hersteller',
      'Bestand',
      'KB-Preis',
      'EK-Preis',
      'Zusatz',
      'Gruppe',
      'TL/TT',
      'Front/Rear',
      'Made in',
      'weight',
      'Ident number',
      'Delivery date',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    // console.log('READ FILE', fileWithHeader);
    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !['Schnapper', 'Ventile', 'Werbematerial', 'Zubehör'].includes(
            item['Gruppe']
          ) &&
          (item['IPC'] || item['Artnr']) &&
          (!item['Zusatz'] ||
            (item['Zusatz'] && !item['Zusatz'].includes('DOT'))) &&
          item['Profil'] &&
          !item['Profil'].includes('DOT') &&
          (!item['Zoll'] || item['Zoll'].slice(-1) !== 'C')
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const headers = [
      'Artnr',
      'EAN',
      'IPC',
      'Breite',
      'Dimension',
      'Trenner',
      'Zoll',
      'Load',
      'Speed',
      'Profil',
      'Hersteller',
      'Bestand',
      'KB-Preis',
      'EK-Preis',
      'Zusatz',
      'Gruppe',
      'TL/TT',
      'Front/Rear',
      'Made in',
      'weight',
      'Ident number',
      'Delivery date',
    ];
    const fileWithHeader = `${headers.join(';')}\n${file}`;

    // console.log('READ FILE', fileWithHeader);
    const json = await csv2json({ delimiter: ';' }).fromString(fileWithHeader);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          !['Schnapper', 'Ventile', 'Werbematerial', 'Zubehör'].includes(
            item['Gruppe']
          ) &&
          (item['IPC'] || item['Artnr']) &&
          (!item['Zusatz'] ||
            (item['Zusatz'] && !item['Zusatz'].includes('DOT'))) &&
          item['Profil'] &&
          !item['Profil'].includes('DOT') &&
          (!item['Zoll'] || item['Zoll'].slice(-1) !== 'C')
      ),
      this.preprocessKeys
    );
  },
};
