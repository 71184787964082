import api from '@tyrio/api-factory';
import { DBStockCountApi, DBStockCountGetByIdRes } from '@tyrio/dto';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { StockCountContext } from '../../../context/StockCountContext';

export const useGetStockCountById = (id: string | undefined) => {
  const { setSelectedStockCountData } = useContext(StockCountContext);

  const { data, isLoading } = useQuery(
    ['get_stock_count_by_id', { id }],
    () =>
      api.fetch<DBStockCountApi['id']>('get_stock_count_by_id', {
        id: id ?? '',
      }),
    {
      enabled: !!id,
      onSuccess: (d: DBStockCountGetByIdRes | null) => {
        if (d) setSelectedStockCountData(d);
      },
    }
  );

  return { data, isLoading };
};
