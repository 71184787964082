import styled from '@emotion/styled/macro';
import {
  ClientSettings,
  DotTrackingOptions,
  PriceCalculationRoundingType,
} from '@tyrio/dto';
import SourceContainer from '@tyrio/forms';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import _ from 'lodash';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from '../../../context/AuthContext';
import BlackListSettings from './components/BlackListSettings';
import DotTrackingSettings from './components/DotTrackingSettings';
import PriceCalculationSettings from './components/PriceCalculationSettings';
import { RitamApiSettings } from './components/RitamApiSettings';
import { useSettings } from './queries/useSettings';
import AppointmentsCron from './components/AppointmentsCron';
import { AutomaticOrders } from './components/AutomaticOrders';
import { AutomaticStockOut } from './components/AutomaticStockOut';

const General = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { set } = useSettings(queryClient);

  const [price, setPrice] = useState<PriceCalculationRoundingType | ''>(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .priceCalculationRoundingType
  );
  const [pricePerHour, setPricePerHour] = useState<number | null>(
    (user?.client?.generalSettings as unknown as ClientSettings).pricePerHour
  );

  const [defaultDeliveryType, setDefaultDeliveryType] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .defaultDeliveryType
  );

  const [dotTracking, setDotTracking] = useState<DotTrackingOptions | ''>(
    (user?.client?.generalSettings as unknown as ClientSettings).dotTracking ||
      ''
  );

  const [isDotTrackingMandatory, setIsDotTrackingMandatory] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .isDotTrackingMandatory
  );

  const [blackList, setBlackList] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings).blackList ?? []
  );

  const [ritamUsername, setRitamUsername] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ritamApiUsername
  );
  const [ritamPassword, setRitamPassword] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ritamApiPassword
  );

  const [appointmentsCron, setAppointmentsCron] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .appointmentsCron ?? false
  );

  const [automaticOrders, setAutomaticOrders] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .automaticOrders ?? false
  );

  const [ac_shippingStockOut, set_ac_shippingStockOut] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ac_shippingStockOut ?? false
  );

  const [ac_dispatchStockOut, set_ac_dispatchStockOut] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ac_dispatchStockOut ?? false
  );

  const [ac_collectionStockOut, set_ac_collectionStockOut] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ac_collectionStockOut ?? false
  );

  const [ac_fittingStockOut, set_ac_fittingStockOut] = useState(
    (user?.client?.generalSettings as unknown as ClientSettings)
      .ac_fittingStockOut ?? false
  );

  return (
    <Container>
      <PriceCalculationSettings
        price={price}
        setPrice={setPrice}
        pricePerHour={pricePerHour}
        setPricePerHour={setPricePerHour}
        defaultDeliveryType={defaultDeliveryType}
        setDefaultDeliveryType={setDefaultDeliveryType}
      />

      <DotTrackingSettings
        dotTracking={dotTracking}
        isDotTrackingMandatory={isDotTrackingMandatory}
        setDotTracking={setDotTracking}
        setIsDotTrackingMandatory={setIsDotTrackingMandatory}
      />

      <BlackListSettings blackList={blackList} setBlackList={setBlackList} />

      <RitamApiSettings
        ritamUsername={ritamUsername}
        setRitamUsername={setRitamUsername}
        ritamPassword={ritamPassword}
        setRitamPassword={setRitamPassword}
      />

      <AppointmentsCron
        appointmentsCron={appointmentsCron}
        setAppointmentsCron={setAppointmentsCron}
      />
      <AutomaticOrders
        automaticOrders={automaticOrders}
        setAutomaticOrders={setAutomaticOrders}
      />

      <AutomaticStockOut
        props={{
          ac_shippingStockOut,
          set_ac_shippingStockOut,
          ac_dispatchStockOut,
          set_ac_dispatchStockOut,
          ac_collectionStockOut,
          set_ac_collectionStockOut,
          ac_fittingStockOut,
          set_ac_fittingStockOut,
        }}
      />

      <SourceContainerDivider>
        <SourceContainer
          data={{
            data_source: 'source',
            created: 'created',
            last_edited: 'edited',
          }}
          onCancel={() => console.log('cancel')}
          disabledDelete
          disabledCancel
          onDelete={() => console.log('delete')}
          onSubmit={() => {
            if (_.isEmpty(price)) {
              ToastHelper.showToast(
                'Transfer order',
                ToastType.INFO,
                ToastMessageType.ERROR,
                'You need to set up price calculation settings!'
              );
              return;
            }

            set.mutate({
              priceCalculationRoundingType:
                price as PriceCalculationRoundingType,
              pricePerHour: pricePerHour as number,
              defaultDeliveryType,
              dotTracking: dotTracking === '' ? undefined : dotTracking,
              isDotTrackingMandatory,
              blackList,
              ritamApiPassword: ritamPassword,
              ritamApiUsername: ritamUsername,
              appointmentsCron: appointmentsCron,
              automaticOrders: automaticOrders,
              ac_shippingStockOut: ac_shippingStockOut,
              ac_dispatchStockOut: ac_dispatchStockOut,
              ac_collectionStockOut: ac_collectionStockOut,
              ac_fittingStockOut: ac_fittingStockOut,
            });
          }}
        />
      </SourceContainerDivider>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const SourceContainerDivider = styled.div`
  position: relative;
  margin-top: 100px;
  width: 100%;

  @media (max-width: 1000px) {
    margin-top: 240px;
  }

  @media (max-width: 880px) {
    margin-top: 300px;
  }
`;

export default General;
