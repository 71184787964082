import { CircularProgress } from '@mui/material';
import { DBProductApi } from '@tyrio/dto';
import { ProductDetailsGenerator } from '@tyrio/products';
import { tyrioIcons } from '@tyrio/ui-library';
import { UnselectedRowWarning, wmsIcons } from '@tyrio/wms-ui-library';
import { useContext, useEffect, useState } from 'react';
import { LoaderWrapper } from '../../../../components/PageTemplate/PageTemplate';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { usePosCartCtx } from '../../../../context/PosCartContext';
import { POSContext } from '../../../../context/POSContext';
import { AddItemsFooter } from '../../components/AddItemsFooter';
import { AdImg, AdWrapper, ContentWrapper, Wrapper } from './Details.style';
import DetailsStock from './DetailsStock';
import { BarcodeModal } from '../../../../components/BarcodeModal/ModalContent';
import { POSDetailsContext } from '../../../../context/POSDetails';

export const Details = () => {
  const { setActiveDrawer, selectedTableRow, shouldRefetch } =
    useContext(POSContext);
  const { isFetchingBranchStock } = useContext(POSDetailsContext);
  const { cartData } = usePosCartCtx();
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (shouldRefetch) setIsImageLoading(true);
    setTimeout(() => setIsImageLoading(false), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTableRow]);

  const isLoading = isFetchingBranchStock;

  const renderDetails = () => {
    return (
      <Wrapper>
        <BarcodeModal
          open={openPrintModal}
          handleClose={() => setOpenPrintModal(false)}
          items={
            selectedTableRow?.product
              ? [
                  {
                    ean: selectedTableRow?.product?.ean,
                    name: selectedTableRow?.product?.productName,
                    qty: 1,
                  },
                ]
              : []
          }
        />

        {selectedTableRow && selectedTableRow.product && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <ProductDetailsGenerator
              product={
                selectedTableRow.product as unknown as DBProductApi['getOne']['response']
              }
              stockListItemId={selectedTableRow.id ?? ''}
              isFavoriteByUser={selectedTableRow.isFavorite}
              setActiveDrawer={setActiveDrawer}
              selectedTableRow={selectedTableRow}
              setOpenPrintModal={setOpenPrintModal}
              isLoading={isImageLoading}
            />

            <AdWrapper>
              <AdImg src={tyrioIcons.smallContiAd} alt="continental-ad" />
            </AdWrapper>

            <ContentWrapper>
              <DetailsStock />
            </ContentWrapper>
          </div>
        )}

        {isLoading && (
          <div style={{ marginTop: '20px' }}>
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          </div>
        )}

        {!selectedTableRow && (
          <UnselectedRowWarning
            icon={wmsIcons.details}
            text="product details"
          />
        )}
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posDetails"
      children={renderDetails()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={false}
      footer={selectedTableRow && cartData.length > 0 && <AddItemsFooter />}
    />
  );
};
