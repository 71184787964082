/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Grid } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  Content,
  OrderSupplierBoxHeader,
  SummaryWrapper,
  Wrapper,
} from '../OrderComponents/OrderSupplierBox';
import { WorkOrderLineItem } from './WorkOrderItem';
import { sum } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { WorkOrderDataType } from '../../../../context/WorkOrderContext';

import {
  DescriptionWrapper,
  DisplayText,
  InfoWrapper,
  InfoWrapperSubheader,
  SuccessIcon,
  SuccessIconWrapper,
} from '../OrderComponents/OrderSentSuccess';
import { BranchesDropdown } from '../OrderComponents/OrderBranchSelector';
import { DBBranchResponse } from '@tyrio/dto';
import { DBBranchType } from '@prisma/client';
import { PriceComparisonContext } from '../context';

export const WorkOrderBox = ({
  workOrder,
  branches,
  branchErpId,
}: {
  workOrder: WorkOrderDataType;
  branches: DBBranchResponse[];
  branchErpId: Record<number, string | null>;
}) => {
  const [open, setOpen] = useState(true);
  const ctx = useContext(PriceComparisonContext);
  const branchId = workOrder.branchId;

  const subtotal = sum(
    workOrder.lineItems.map((m) => m.quantity * m.sellingPrice)
  );
  const ecoTax = 0;
  const delivery = 0;
  const total = subtotal;

  const warehouseBranches = useMemo(() => {
    const filteredBranches = branches?.filter((b) =>
      b.branchType.includes(DBBranchType.WAREHOUSE)
    );
    return (
      filteredBranches?.map((branch) => ({
        label: branch.branchName,
        value: branch.id,
      })) ?? []
    );
  }, [branches]);

  const serviceAddressBranch = useMemo(() => {
    return (ctx.data.workOrderData?.serviceAddressBranches ?? {})[branchId];
  }, [ctx.data.workOrderData?.serviceAddressBranches, branchId]);

  const orderIsPlaced = ctx.data.workOrderData?.orderResponse?.[branchId];

  return (
    <Wrapper id="order_supplier_box">
      <OrderSupplierBoxHeader
        open={open}
        onClick={() => setOpen((val) => !val)}
      >
        <h3>WORK ORDER - {workOrder.branchName}</h3>

        <div>
          <Chip label={'INTERNO'} variant="outlined" />
        </div>
      </OrderSupplierBoxHeader>
      {open && (
        <Content>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{ position: 'relative' }}>
              <Scrollbars
                style={{
                  width: '100%',
                  height: 340,
                  paddingRight: 16,
                  marginRight: 16,
                }}
              >
                {workOrder.lineItems?.map((line) => (
                  <WorkOrderLineItem
                    data={line}
                    isScrolling={workOrder.lineItems.length > 5}
                    key={line.ean}
                  />
                ))}
              </Scrollbars>

              {orderIsPlaced && (
                <OrderSentSuccess
                  orderNumber={orderIsPlaced[0]?.orderNumber ?? ''}
                  ritamOrderResponse={''}
                />
              )}

              {!orderIsPlaced && (
                <Grid item xs={4}>
                  <BranchesDropdown
                    label="Service address"
                    id={`service-address-${1}`}
                    branches={warehouseBranches}
                    selectedBranch={serviceAddressBranch ?? ''}
                    onChange={(e) => {
                      const val = Number(e.target.value);
                      const erpId = branchErpId[val];

                      const newOrder = {
                        ...(ctx.data?.workOrderData?.order?.[branchId] ?? {}),
                        serviceErpBranchId: erpId,
                      };

                      ctx.setInputValue({
                        workOrderData: {
                          ...(ctx.data?.workOrderData ?? {}),
                          order: {
                            ...((ctx.data?.workOrderData?.order ?? {}) as any),
                            [branchId]: newOrder,
                          },
                          serviceAddressBranches: {
                            ...(ctx.data.workOrderData
                              ?.serviceAddressBranches ?? {}),
                            [workOrder.branchId]: Number(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={4}>
              <SummaryWrapper>
                <div>
                  <span>Subtotal:</span>
                  <p>
                    {subtotal.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                <div>
                  <span>Delivery:</span>
                  <p>
                    {delivery.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                <div>
                  <span>Eco tax:</span>
                  <p>
                    {ecoTax.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>

                <div className="strong">
                  <span>Total:</span>
                  <p>
                    {total.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </p>
                </div>
                {/* <Button
                  disabled={
                    isSuccess ||
                    isPressed ||
                    orderIsPlaced !== undefined ||
                    !serviceAddressBranch
                  }
                  fullWidth
                  variant="contained"
                  color="info"
                  onClick={handleCreateWorkOrder}
                  disableElevation
                >
                  {isPressed && <CircularProgress size={15} />}
                  Order
                </Button> */}
              </SummaryWrapper>
            </Grid>
          </Grid>
        </Content>
      )}
    </Wrapper>
  );
};

export const OrderSentSuccess = ({
  orderNumber,
  ritamOrderResponse,
}: {
  orderNumber: string;
  ritamOrderResponse: string;
}) => {
  return (
    <DescriptionWrapper>
      <InfoWrapper>
        <SuccessIconWrapper>
          <SuccessIcon />
        </SuccessIconWrapper>
        <DisplayText>Order sent {orderNumber}</DisplayText>
      </InfoWrapper>
      <InfoWrapperSubheader style={{ gap: '3px', textAlign: 'center' }}>
        {ritamOrderResponse}
        {/* Work order nr.: <b> {ritamOrderNr}</b> */}
      </InfoWrapperSubheader>
    </DescriptionWrapper>
  );
};
