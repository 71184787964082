import { DBServicesApi } from '@tyrio/dto';
import { useDebounce } from '../../../hooks/useDebounce';
import api from '@tyrio/api-factory';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { DBService, DBServiceCategory } from '@prisma/client';

export const useGetServices = (
  ean: string,
  warehouseId: number,
  search: string,
  modalData: any[]
) => {
  const debouncedSearch = useDebounce(search, 500);
  const { data: recommendedServices } = useQuery(
    [
      'get_recommended_services',
      {
        branchId: warehouseId?.toString(),
        eans: [ean],
      },
    ],
    async () => {
      return await api.fetch<DBServicesApi['recommended']>(
        'get_recommended_services',
        {
          branchId: warehouseId?.toString(),
          eans: [ean ?? ''],
        }
      );
    },
    {
      enabled: !isEmpty(ean) && modalData.length > 0,
    }
  );
  const { data: service } = useQuery(
    [
      'get_services_by_branch_id',
      warehouseId?.toString(),
      { search: debouncedSearch },
    ],
    async () => {
      return await api.fetch<DBServicesApi['list']>(
        'get_services_by_branch_id',
        {
          branchId: warehouseId?.toString(),
          productEans: ean ?? '',
          search: debouncedSearch,
        }
      );
    },
    {
      enabled: !isEmpty(ean),
    }
  );

  const filtredServices = service?.reduce(
    (result: DBService[], value: DBService) => {
      if (
        !recommendedServices?.includes(
          value as DBService & { serviceCategory: DBServiceCategory }
        )
      ) {
        result.push(value);
      }
      return result;
    },
    []
  );
  return {
    recommendedServices,
    filtredServices,
  };
};
