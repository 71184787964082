/* eslint-disable @typescript-eslint/no-unused-vars */
import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useVehicleCtx } from '../../../../context/VehicleContext';
import { StockInfo } from '../../components/StockInfo/StockInfo';
import { StockWrapper } from './Details.style';
import { POSDetailsContext } from '../../../../context/POSDetails';
import { POSContext } from '../../../../context/POSContext';

interface DetailsStockProps {
  stockType?: 'CURRENT' | 'BRANCHES' | 'SUPPLIER';
  isWheels?: boolean;
}

const DetailsStock = ({ stockType, isWheels = false }: DetailsStockProps) => {
  const { selectedTableRow } = useContext(POSContext);

  const stockTypeProp = stockType ? stockType : selectedTableRow?.stockType;
  const {
    upcomingStockItems,
    branchesStock,
    onStockItems,
    suppliersStockItems,
    isFetchingBranchStock,
    isFetchingSupplierStock,
  } = useContext(POSDetailsContext);
  const isLoaderVisible = isFetchingBranchStock || isFetchingSupplierStock;

  return (
    <div>
      {isLoaderVisible ? (
        <StockWrapper style={{ alignItems: 'center', padding: '70px 0' }}>
          <CircularProgress />
        </StockWrapper>
      ) : stockTypeProp === 'CURRENT' ? (
        <div>
          {onStockItems && onStockItems?.length > 0 ? (
            <StockWrapper>
              <StockInfo data={onStockItems} text="ON STOCK" />
            </StockWrapper>
          ) : branchesStock && branchesStock.length > 0 ? (
            <StockWrapper>
              <StockInfo data={branchesStock} text="BRANCHES STOCK" />
            </StockWrapper>
          ) : (
            //   TODO: fix this, this should show supplier with lowest price
            suppliersStockItems &&
            suppliersStockItems.length > 0 &&
            !isFetchingSupplierStock && (
              <StockWrapper>
                <StockInfo
                  data={suppliersStockItems}
                  text="SUPPLIERS STOCK"
                  showVisibility={true}
                />
              </StockWrapper>
            )
          )}
          {upcomingStockItems && upcomingStockItems?.length > 0 && (
            <StockWrapper style={{ marginTop: '30px' }}>
              <StockInfo data={upcomingStockItems} text="UPCOMING ON STOCK" />
            </StockWrapper>
          )}
        </div>
      ) : stockTypeProp === 'BRANCHES' ? (
        <div>
          {branchesStock && branchesStock.length > 0 && (
            <StockWrapper>
              <StockInfo data={branchesStock} text="BRANCHES STOCK" />
            </StockWrapper>
          )}
        </div>
      ) : (
        <div>
          {suppliersStockItems && (
            <StockWrapper>
              <StockInfo
                data={suppliersStockItems}
                text="SUPPLIERS STOCK"
                showVisibility={true}
              />
            </StockWrapper>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsStock;
