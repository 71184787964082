import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled/macro';
import {
  Alert,
  AlertTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DBBranchType } from '@prisma/client';
import {
  BranchesEdiShape,
  DBBranchResponse,
  DBSupplierClientSettingsPayload,
  PhoneNumberDetails,
} from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';
import _ from 'lodash';
import { SalesOrdersFlowContext } from '../../../../features/sales-orders/SalesOrdersFlowContext';
import { formatCurrencyWithoutSymbol } from '../../../../helpers/currency-format';
import { PriceComparisonContext } from '../context';

interface OrderBranchSelectorProps {
  clientSupplier: DBSupplierClientSettingsPayload;
  branches?: DBBranchResponse[];
  clientSupplierId: string;
  defaultBranchErpId?: string;
  shouldSendCodAmount?: boolean;
}

export const OrderBranchSelector = (props: OrderBranchSelectorProps) => {
  const ctx = useContext(PriceComparisonContext);
  const soctx = useContext(SalesOrdersFlowContext);
  const branches = props.branches;

  const [branchesData, setBranchesData] = useState<TyrioSelectInputOption[]>(
    props.branches?.map((branch) => ({
      label: branch.branchName,
      value: branch.id,
    })) ?? []
  );

  const selectedBranch = (ctx.data.orderDeliveryBranches ?? {})[
    props.clientSupplierId
  ];

  const erpToBranchId = useCallback(
    (erpBranchId: string) => {
      return (branches ?? []).find((b) => b.erpId === erpBranchId)?.id;
    },
    [branches]
  );

  const serviceAddressBranch = useMemo(() => {
    let branchId = undefined;
    if (props.defaultBranchErpId)
      branchId = erpToBranchId(props.defaultBranchErpId);

    return (
      (ctx.data.serviceAddressBranches ?? {})[props.clientSupplierId] ??
      branchId
    );
  }, [
    ctx.data.serviceAddressBranches,
    erpToBranchId,
    props.clientSupplierId,
    props.defaultBranchErpId,
  ]);

  const branchDetails = useMemo(() => {
    if (selectedBranch !== 500)
      return props.branches?.find((b) => b.id === selectedBranch);
    else {
      const deliveryAddress = soctx.customerOrder?.deliveryAddress;
      const phoneNumberDetails =
        deliveryAddress?.phoneNumberDetails as unknown as PhoneNumberDetails;

      return {
        branchName: `${deliveryAddress?.firstName} ${deliveryAddress?.lastName}`,
        address: deliveryAddress?.address ?? '',
        zipCode: deliveryAddress?.zip ?? '',
        city: deliveryAddress?.city ?? '',
        country: { name: deliveryAddress?.country },
        businessPhone: `${phoneNumberDetails?.countryCode ?? ''} ${
          phoneNumberDetails?.phoneNumber ?? ''
        }`,
        email: '',
      };
    }
  }, [props.branches, selectedBranch, soctx.customerOrder?.deliveryAddress]);

  const hasConfiguredBranches = !!(branches?.length && branches?.length > 0);

  const warehouseBranches = useMemo(() => {
    const filteredBranches = branches?.filter((b) =>
      b.branchType.includes(DBBranchType.WAREHOUSE)
    );
    return (
      filteredBranches?.map((branch) => ({
        label: branch.branchName,
        value: branch.id,
      })) ?? []
    );
  }, [branches]);

  const setSelectedBranch = useCallback(
    (
      key: 'orderDeliveryBranches' | 'serviceAddressBranches',
      value: number
    ) => {
      ctx.setInputValue({
        ...ctx.data,
        [key]: {
          ...(ctx.data?.[key] ?? {}),
          [props.clientSupplierId]: Number(value),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.clientSupplierId, ctx.data]
  );

  useEffect(() => {
    if (props.defaultBranchErpId) {
      const branchId = erpToBranchId(props.defaultBranchErpId);
      if (branchId) setSelectedBranch('serviceAddressBranches', branchId);
    }
  }, [erpToBranchId, props.defaultBranchErpId, setSelectedBranch]);

  useEffect(() => {
    setBranchesData(
      branches?.map((branch) => ({
        label: branch.branchName,
        value: branch.id,
      })) ?? []
    );
  }, [branches]);

  useEffect(() => {
    if (
      soctx.isDropshipping &&
      branchesData.find((item) => item.value === '500') === undefined
    ) {
      const dropdownItems = branchesData.filter((item) => {
        return (
          Object.keys(
            props.clientSupplier.branchEdiConfiguration ?? {}
          ).includes(item.value.toString()) &&
          (
            _.get(
              props.clientSupplier,
              `branchEdiConfiguration.${item.value}`
            ) as unknown as BranchesEdiShape
          ).active
        );
      });
      dropdownItems.push({
        label: 'DROPSHIPPING - Krajnji kupac',
        value: '500',
      });
      setBranchesData(dropdownItems);
    }
  }, [branches, branchesData, props.clientSupplier, soctx.isDropshipping]);

  return (
    <Grid container spacing={2} sx={{ paddingTop: 2, marginBottom: 2 }}>
      <Grid item xs={4}>
        {hasConfiguredBranches && (
          <DropdownWrapper>
            <BranchesDropdown
              label="Delivery address"
              id={`delivery-address-${props.clientSupplierId}`}
              branches={
                !soctx.isDropshipping
                  ? branchesData?.filter((item) => {
                      return (
                        Object.keys(
                          props.clientSupplier.branchEdiConfiguration ?? {}
                        ).includes(item.value.toString()) &&
                        (
                          _.get(
                            props.clientSupplier,
                            `branchEdiConfiguration.${item.value}`
                          ) as unknown as BranchesEdiShape
                        ).active
                      );
                    })
                  : branchesData
              }
              selectedBranch={selectedBranch}
              onChange={(e) => {
                if (
                  selectedBranch === undefined &&
                  props.defaultBranchErpId === undefined
                ) {
                  setSelectedBranch(
                    'serviceAddressBranches',
                    Number(e.target.value)
                  );
                }

                setSelectedBranch(
                  'orderDeliveryBranches',
                  Number(e.target.value)
                );
              }}
            />
            {selectedBranch !== 500 && (
              <BranchesDropdown
                label="Service address"
                id={`service-address-${props.clientSupplierId}`}
                branches={warehouseBranches ?? []}
                selectedBranch={serviceAddressBranch}
                onChange={(e) => {
                  setSelectedBranch(
                    'serviceAddressBranches',
                    Number(e.target.value)
                  );
                }}
              />
            )}

            {soctx.isDropshipping && props.shouldSendCodAmount && (
              <Grid item xs={12}>
                <TextField
                  label="Cash on delivery amount"
                  InputLabelProps={{ shrink: true }}
                  value={formatCurrencyWithoutSymbol(soctx.codAmount)}
                  onChange={(e) => {
                    soctx.setCodAmount(
                      Number(e.target.value.replace(',', '.'))
                    );
                  }}
                />
              </Grid>
            )}
          </DropdownWrapper>
        )}
        {!hasConfiguredBranches && (
          <Alert severity="warning">
            <AlertTitle>Please configure delivery branches.</AlertTitle>
            Before you can place an order with a supplier, you must configure
            the branches with your supplier settings. First, you need to create
            branches under <strong>{'Settings > Branches'}</strong>, and then
            for suppliers which support EDI orders, please configure your EDI
            keys in <strong>{'Settings > EDI Order configuration'}</strong>.
          </Alert>
        )}
      </Grid>
      <Grid item xs={6}>
        {branchDetails && (
          <AddressContainer>
            {branchDetails?.branchName && <h2>{branchDetails.branchName}</h2>}
            {branchDetails?.address && <p>{branchDetails.address}</p>}
            {branchDetails?.zipCode && (
              <p>
                {branchDetails.zipCode}
                {branchDetails?.city && <>, {branchDetails.city}</>}
                {branchDetails?.country && <>, {branchDetails.country.name}</>}
              </p>
            )}
            {branchDetails?.businessPhone && (
              <p>{branchDetails.businessPhone}</p>
            )}
            {branchDetails?.email && <p>{branchDetails.email}</p>}
          </AddressContainer>
        )}
        {!!(!branchDetails && hasConfiguredBranches) && (
          <Alert severity="info">
            <AlertTitle>Please select a branch for delivery.</AlertTitle>
            Before the app can inquire for prices and dates, you must select the
            exact branch you want the products delivered to. Prices and dates
            may vary between branches, which depends completely on suppliers.
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

export const BranchesDropdown = ({
  label,
  id,
  branches,
  selectedBranch,
  onChange,
}: {
  label: string;
  id: string;
  branches: TyrioSelectInputOption[];
  selectedBranch: number;
  onChange: (val: SelectChangeEvent<number>) => void;
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}`}>{label}</InputLabel>
      <Select
        labelId={`${id}`}
        id={`${id}`}
        value={selectedBranch ?? ''}
        label={label}
        disabled={branches?.length === 0}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {branches?.map((branch) => (
          <MenuItem value={branch.value} key={branch.value}>
            {branch.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AddressContainer = styled.div`
  padding: 16px;

  background: #f4f6f8;
  border-radius: 16px;
  min-height: 130px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px 0;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #212b36;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0px 0;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    /* Primary/Black/black-100 */

    color: #212b36;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
