export type InputProps = {
  id: string;
  placeholder: string;
  disabled: boolean;
  customWidth: string;
  required?: boolean;
  helperText?: string;
};

export type ArrayType = Array<Array<InputProps>>;

export const customerInputFields: ArrayType = [
  [
    {
      id: 'first_name',
      placeholder: 'First name',
      disabled: false,
      customWidth: '49%',
      helperText: '',
      required: true,
    },
    {
      id: 'last_name',
      placeholder: 'Last name',
      disabled: false,
      customWidth: '49%',
      helperText: '',
      required: true,
    },
  ],
  [
    {
      id: 'country',
      placeholder: 'Country',
      disabled: false,
      customWidth: '42%',
      helperText: '',
    },
  ],
  [
    {
      id: 'address',
      placeholder: 'Address',
      disabled: false,
      customWidth: '100%',
      helperText: '',
      required: true,
    },
  ],
  [
    {
      id: 'zip_code',
      placeholder: 'Zip code',
      disabled: false,
      customWidth: '33%',
      helperText: '',
      required: true,
    },
    {
      id: 'city',
      placeholder: 'City',
      disabled: false,
      customWidth: '65%',
      helperText: '',
      required: true,
    },
  ],
  [
    {
      id: 'mobile_phone',
      placeholder: 'Mobile phone*',
      disabled: false,
      customWidth: '77%',
      helperText: '',
      required: true,
    },
  ],
  [
    {
      id: 'email',
      placeholder: 'Email',
      disabled: false,
      customWidth: '100%',
      helperText: '',
    },
  ],
  [
    {
      id: 'passport',
      placeholder: 'Passport or ID number',
      disabled: false,
      customWidth: '100%',
      helperText: 'Only use for TAX FREE purposes',
    },
  ],
  [
    {
      id: 'customer_remark',
      placeholder: 'Customer remark',
      disabled: false,
      customWidth: '100%',
      helperText: '',
    },
  ],
];

export const companyInputFields: ArrayType = [
  [
    {
      id: 'generateVat',
      placeholder: 'Find company by vat',
      disabled: false,
      customWidth: '100%',
      helperText: '',
    },
  ],
  [
    {
      id: 'vat',
      placeholder: 'Vat',
      disabled: true,
      customWidth: '100%',
      helperText: '',
    },
  ],
  [
    {
      id: 'company_name',
      placeholder: 'Company name',
      disabled: true,
      customWidth: '100%',
      helperText: '',
    },
  ],
  [
    {
      id: 'first_name',
      placeholder: 'First name',
      disabled: false,
      customWidth: '49%',
      required: false,
      helperText: '',
    },
    {
      id: 'last_name',
      placeholder: 'Last name',
      disabled: false,
      customWidth: '49%',
      required: false,
      helperText: '',
    },
  ],
  [
    {
      id: 'country',
      placeholder: 'Country',
      disabled: false,
      customWidth: '42%',
      required: true,
      helperText: '',
    },
  ],
  [
    {
      id: 'address',
      placeholder: 'Address',
      disabled: true,
      customWidth: '100%',
      required: true,
      helperText: '',
    },
  ],
  [
    {
      id: 'zip_code',
      placeholder: 'Zip code',
      disabled: true,
      customWidth: '33%',
      required: true,
      helperText: '',
    },
    {
      id: 'city',
      placeholder: 'City',
      disabled: true,
      customWidth: '65%',
      required: true,
      helperText: '',
    },
  ],
  [
    {
      id: 'mobile_phone',
      placeholder: 'Mobile phone',
      disabled: false,
      customWidth: '77%',
      helperText: '',
    },
  ],
  [
    {
      id: 'email',
      placeholder: 'Email',
      disabled: false,
      customWidth: '100%',
      helperText: '',
    },
  ],
  [
    {
      id: 'internal_remark',
      placeholder: 'Internal remark',
      disabled: false,
      customWidth: '100%',
      helperText: '',
    },
  ],
];
