import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function globalGetNoweGumySpecialMarkings(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetNoweGumySpecialMarkings requires rule to be of type TOperateRule'
    );

  const rawProductInfo = input['name'];

  const data = genericGenerateSpecialMarkings(
    rawProductInfo,
    () => input['name'].includes('RFT') || input['name'].includes('Run-Flat'),
    false
  );

  return data;
}

export function getNoweGumyCategory(
  input: RawImport,
  _rule: TRule,
  data: UnfinishedData
) {
  let category = 0;

  switch (input['type']) {
    case 'quads':
      if (input['model'].toUpperCase().includes('MUD')) {
        category = 66;
      } else {
        category = 48;
      }
      break;
    case 'industrial':
      category = 71;
      break;
    case 'passenger cars':
      category = 30;
      break;
    case 'light trucks':
      category = 32;
      break;
    case 'AGR':
      if (input['model'].toUpperCase().includes('TURF')) {
        category = 66;
      } else {
        category = 65;
      }
      break;
    case '4x4':
      category = 31;
      break;
    case 'motorcycles':
      if (
        ['ODO', 'OD2', 'OD3', 'OS-', 'OS1', 'OS4'].includes(
          input['kod_kategoria']
        )
      ) {
        category = 37;
      }
      if (
        [
          'ODC',
          'OF0',
          'OF1',
          'OF2',
          'OF4',
          'OF6',
          'OF7',
          'OF8',
          'OF9',
          'OMC',
          'OMM',
          'OMS',
          'OS2',
          'OS5',
          'OS6',
          'OS8',
          'OSQ',
        ].includes(input['kod_kategoria'])
      ) {
        category = 36;
      }
      if (['OSM'].includes(input['kod_kategoria'])) {
        category = 40;
      }
      break;
  }

  data.model['category_id'] = category;

  return data;
}

export function generateNoweGumySize(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateNoweGumySize requires rule to be of type TOperateRule'
    );

  let width;
  let height;
  let rim;
  let construction;
  let size2;
  // let size1
  if (input['type'] === 'quads' || input['type'] === 'industrial') {
    // ATV
    // if (input['name'].toUpperCase().includes(input['size'].toUpperCase())) {
    //   construction = '-';
    // } else {
    //   construction = 'R';
    // }
    if (input['size'].includes('-')) {
      construction = '-';
    } else if (input['size'].includes('R')) {
      construction = 'R';
    }

    size2 = input['size'].replace('-', ' - ').replace('R', ' R ');
    //  replace - with R to avoid multiple cases
    const sizetemp = input['size']
      .replace('/', 'x')
      .replace('-', 'R')
      .toUpperCase();
    if (sizetemp.includes('X')) {
      const sizeparts = sizetemp.split('X');
      width = sizeparts[0];
      const sizepart2 = sizeparts[1].split('R');
      height = sizepart2[0];
      rim = sizepart2[1];
      if (width.length === 1) {
        width = width + '.00';
      }
    } else if (!input['height']) {
      width = input['width'];
      height = '-';
      rim = input['rim_size'];
    } else {
      width = input['height'];
      height = input['width'];
      rim = input['rim_size'];
    }
  } else {
    width = input['width'];
    height = input['height'];
    rim = input['rim_size'];
    construction = 'R';
    if (input['type'] === '4x4' && parseFloat(input['width']) < 20) {
      width = input['height'];
      height = input['width'];
    }
    if (input['type'] === 'motocycles') {
      if (input['size'].includes('-')) {
        construction = '-';
      } else if (input['size'].includes('ZR')) {
        construction = 'ZR';
      }
    }
  }

  const data = {
    product: {
      width: width,
      height: height,
      rimDiameter: rim,
      construction: construction,
      size2: size2,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}

export function generateNoweGumyModelName(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateNoweGumyModelName requires rule to be of type TOperateRule'
    );
  const modelName = input['model']
    .replace('FRONT REAR', '')
    .replace('Front Rear', '')
    .replace('front rear', '')
    .replace('FRONT', '')
    .replace('Front', '')
    .replace('front', '')
    .replace('REAR', '')
    .replace('Rear', '')
    .replace('rear', '')
    .replace('WWW', '')
    .replace('WW', '')
    .replace('www', '')
    .replace('ww', '')
    .replace('Run-Flat', '');

  const data = {
    product: {},
    model: {
      modelName,
    },
  } as Partial<UnfinishedData>;

  return data;
}

export function getNoweGumyAxle(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateNoweGumyAxle requires rule to be of type TOperateRule'
    );

  let axle = '';
  input['model'].toLowerCase().includes('front rear')
    ? (axle = 'front/rear')
    : input['model'].toLowerCase().includes('front')
    ? (axle = 'front')
    : input['model'].toLowerCase().includes('rear')
    ? (axle = 'rear')
    : (axle = '');

  const data = {
    product: {},
    model: {
      wheelPosition: axle,
    },
  } as Partial<UnfinishedData>;

  return data;
}

export function getNoweGumyEprel(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'generateNoweGumyEprel requires rule to be of type TOperateRule'
    );

  let rollingResistance = '';
  let wetGrip = '';
  let noisePerformance = 0;
  let noiseClassType = '';
  let euDirectiveNumber = '';
  let threepmfs = false;
  let iceGrip = false;

  if (input['eprel_id']) {
    rollingResistance = input['fuel_efficiency_class'];
    wetGrip = input['wet_grip_class'];
    noisePerformance = parseFloat(input['external_rolling_noise_value']);
    noiseClassType = input['external_rolling_noise_class'];
    euDirectiveNumber = '2020/740';
    threepmfs =
      input['snow_grip'] && input['snow_grip'].toUpperCase() === 'YES'
        ? true
        : false;
    iceGrip =
      input['ice_grip'] && input['ice_grip'].toUpperCase() === 'YES'
        ? true
        : false;
  } else {
    rollingResistance = input['label_fuel'];
    wetGrip = input['label_braking'];
    noisePerformance = parseFloat(input['label_noise']);
    noiseClassType = input['label_noise_symbol'].length.toString();
    euDirectiveNumber = '1222/2009';
  }

  noisePerformance = noisePerformance ? noisePerformance : 0;

  const data = {
    product: {
      rollingResistance,
      wetGrip,
      noisePerformance,
      noiseClassType,
      threepmfs,
      iceGrip,
      euDirectiveNumber,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}

export function getNoweGumyPR(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate')
    throw new Error('getNoweGumyPR requires rule to be of type TOperateRule');

  let pr = '';

  const regex = / (\d+)PR /g; // Regular expression to match number followed by "PR" with spaces around it
  const match = regex.exec(input['name']);
  if (match) {
    pr = match[0].replace('PR', ''); // Returns the matched pattern
  }

  const data = {
    product: {
      pr,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}
