import styled from '@emotion/styled';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { backIcon } from '@tyrio/ui-library';
import { useContext } from 'react';
import * as XLSX from 'xlsx';
import { StockCountContext } from '../../../context/StockCountContext';
import _ from 'lodash';
import moment from 'moment';
import { StockCountProducts } from '../components/ProductsTable';

export const StockCountDetails = () => {
  const {
    selectedStockCountData,
    setSelectedStockCount,
    view,
    setView,
    showDifferences,
    setShowDifferences,
  } = useContext(StockCountContext);

  return (
    <Container>
      <HeaderWrapperTop>
        <Grid>
          <BackButton onClick={() => setSelectedStockCount(null)}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>{selectedStockCountData?.docNumber}</HeaderText>
        </Grid>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Button
            variant="text"
            color="info"
            endIcon={<UploadIcon />}
            onClick={() => {
              const productValues = _.get(selectedStockCountData, 'products');

              const products = Object.entries(productValues ?? {});

              const data = products.map((item) => {
                const key = item[0];

                const product = item[1].product;

                const splitted = key.split('_');

                const ean = splitted[0];

                const dot = splitted[1];

                const locations = Object.values(item[1].locations);

                const expected = _.sumBy(_.values(locations), 'expected');
                const scanned = _.sumBy(_.values(locations), 'scanned');

                return {
                  EAN: product?.ean ?? ean,
                  SKU: product?.sku ?? '',
                  MANUFACTURER_CODE: product?.manufacturerCode ?? '',
                  DOT: dot ?? '',
                  PRODUCT: product?.productName ?? '',
                  BRAND: product?.model?.brand ?? '',
                  CATEGORY: `${
                    product?.model?.category.parent_category_name ?? ''
                  } - ${product?.model?.category.name ?? ''}`,
                  SEASON: product?.model?.season ?? '',
                  EXPECTED: expected,
                  COUNTED: scanned,
                  DIFF: scanned - expected,
                };
              });

              const worksheet = XLSX.utils.json_to_sheet(data);
              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, 'Podaci');

              const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
              });

              const blob = new Blob([excelBuffer], {
                type: 'application/octet-stream',
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = `${
                selectedStockCountData?.docNumber
              }_${moment()}.xlsx`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            export TO XLSX
          </Button>
          <FormControlLabel
            control={
              <Switch
                data-cy="switch"
                color="info"
                onChange={(e) => {
                  setShowDifferences(e.target.checked);
                }}
                checked={showDifferences}
                disabled={false}
              />
            }
            label={'Show only differences'}
          />
          <FormControlLabel
            control={
              <Switch
                data-cy="switch"
                color="info"
                onChange={(e) => {
                  if (e.target.checked) setView('SHELF');
                  else setView('PRODUCT');
                }}
                checked={view === 'SHELF'}
                disabled={false}
              />
            }
            label={'Shelf view'}
          />
        </Grid>
      </HeaderWrapperTop>
      <Grid>
        {view === 'PRODUCT' ? (
          <StockCountProducts
            onOpenSidebarFilters={() => {
              console.log('OPEN SIDEBAR FILTERS');
            }}
          />
        ) : (
          <Typography>SHELF VIEW</Typography>
        )}
      </Grid>
    </Container>
  );
};

export const Container = styled.div`
  background: white;
  width: 100%;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  padding: 16px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
