import styled from '@emotion/styled';
import HttpsIcon from '@mui/icons-material/Https';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import { DBTransferOrderItem } from '@prisma/client';
import {
  DBTransferOrderBranchDetails,
  DBTransferOrderItemResponse,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { startCase } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import PopoverComponent from '../../components/Popover/Popover';
import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';
import {
  SelectedProductStatuses,
  StyledTableCell,
  StyledTableRow,
} from '../purchase-orders/PurchaseOrderTable';
import { getTransferOrderStatusColor } from '../purchase-orders/helpers/generate-color';
import { parseStatus } from './helper/status-helper';

interface TransferOrderTableProps {
  setSelectedOrder: Dispatch<SetStateAction<DBTransferOrderItem | null>>;
  selectedOrder: DBTransferOrderItem | null;
  data: DBTransferOrderItemResponse[];
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isUploadInProcess: boolean;
}

const TransferOrderTable = ({
  data,
  selectedOrder,
  setSelectedOrder,
  isDirty,
  setIsDirty,
  isUploadInProcess,
}: TransferOrderTableProps) => {
  const { user } = useAuth();
  const { state } = useWS();
  const [isOrderDesc, setIsOrderDesc] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openOrderDetails = (order: DBTransferOrderItem) => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrder(order);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't switch orders while uploading documents!`);
    } else setSelectedOrder(order);
  };

  const handleOrderSort = () => {
    setIsOrderDesc((prevState) => !prevState);
    return data.sort((a, b) => {
      if (
        a.order &&
        b.order &&
        a.order?.orderNumber !== null &&
        b.order?.orderNumber !== null
      ) {
        return !isOrderDesc
          ? a.order.orderNumber < b.order.orderNumber
            ? 1
            : -1
          : a.order.orderNumber > b.order.orderNumber
          ? 1
          : -1;
      } else return 0;
    });
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '32px',
        borderRadius: 0,
      }}
    >
      <StyledTable aria-label="simple table">
        <StyledTableHead>
          <StyledTableRowHeader>
            <CellWithSort>Status</CellWithSort>
            <Tooltip
              title={
                isOrderDesc
                  ? 'Click to sort ascending'
                  : 'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <TableCell>
                <TableSortLabel
                  IconComponent={UnfoldMoreIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                  onClick={handleOrderSort}
                >
                  Order number
                </TableSortLabel>
              </TableCell>
            </Tooltip>
            <TableCell>Reference</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Remark</TableCell>
            <TableCell>ERP ID</TableCell>
            <TableCell></TableCell>
          </StyledTableRowHeader>
        </StyledTableHead>
        <TableBody>
          {data.map((order: DBTransferOrderItemResponse) => {
            const isFavorite = () => {
              if (user) return order?.favoritedBy.includes(user.id);
              return false;
            };

            const getTableRowColor = () => {
              if (selectedOrder === order) return '#E4F5FE';
              if (isFavorite()) return '#fffbf2';
              return '#fff';
            };

            const lockEntity =
              state.locks &&
              state.locks[`${SSE_ENTITY_KEYS.TRANSFER_ORDER}/${order.id}`];

            const status =
              selectedOrder?.id === order.id
                ? SelectedProductStatuses.SELECTED
                : isFavorite()
                ? SelectedProductStatuses.FAVORITE
                : SelectedProductStatuses.NORMAL;

            const shouldSetOpacity =
              lockEntity !== undefined && lockEntity !== null;

            return (
              <StyledTableRow
                sx={{
                  background: getTableRowColor(),
                  '&:hover': {
                    background: isFavorite() ? '#fef8e9' : '#F9FAFB',
                    borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                  },
                  position: 'relative',
                }}
                onClick={() => openOrderDetails(order)}
                key={order.id}
                status={status}
              >
                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  <CenterStatus>
                    {order.orderStatus && (
                      <>
                        <OrderStatus
                          color={
                            getTransferOrderStatusColor[
                              parseStatus(order.orderStatus).status
                            ]
                          }
                        >
                          {startCase(parseStatus(order.orderStatus).status)}
                        </OrderStatus>
                        <SubStatus>
                          {
                            JSON.parse(JSON.stringify(order?.orderStatus))
                              ?.subStatus
                          }
                        </SubStatus>
                      </>
                    )}
                  </CenterStatus>
                </StyledTableCell>

                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  {order.orderNumber}
                  <CellDetails>
                    {`${moment(order.createdAt).format('DD.MM - HH:mm')}`}
                  </CellDetails>
                </StyledTableCell>

                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  <div>{order.reference}</div>
                </StyledTableCell>

                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  {order.createdBy &&
                    order.createdBy?.firstName +
                      ' ' +
                      order.createdBy?.lastName}
                </StyledTableCell>
                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  {order.type === 'OUTBOUND'
                    ? (
                        order.inboundBranchDetails as unknown as DBTransferOrderBranchDetails
                      ).branchName
                    : (
                        order.outboundBranchDetails as unknown as DBTransferOrderBranchDetails
                      ).branchName}
                </StyledTableCell>
                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  {order.type === 'OUTBOUND'
                    ? (
                        order.outboundBranchDetails as unknown as DBTransferOrderBranchDetails
                      ).branchName
                    : (
                        order.inboundBranchDetails as unknown as DBTransferOrderBranchDetails
                      ).branchName}
                </StyledTableCell>

                <StyledTableCell status={status} opacity={shouldSetOpacity}>
                  <RemarkWrapper>{order.remark}</RemarkWrapper>
                </StyledTableCell>
                <StyledTableCell status={status}>
                  {order?.erpOrderId ?? ''}
                  <CellDetails>{order?.erpDocNumber ?? ''}</CellDetails>
                </StyledTableCell>
                <StyledTableCell status={status}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    style={{ marginRight: selectedOrder ? '60px' : '30px' }}
                  >
                    {lockEntity && (
                      <Tooltip
                        title={`Locked by ${lockEntity?.firstName ?? ''}  ${
                          lockEntity?.lastName ?? ''
                        }`}
                      >
                        <HttpsIcon sx={{ height: '20px' }} color="error" />
                      </Tooltip>
                    )}
                  </Stack>
                </StyledTableCell>

                <PopoverCell opacity={shouldSetOpacity}>
                  <div onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      aria-describedby={order.orderId}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    {anchorEl && (
                      <PopoverComponent
                        id={order.orderId}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                      />
                    )}
                  </div>
                </PopoverCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  white-space: nowrap;
`;

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledTableRowHeader = styled(TableRow)`
  background: #ecf0f4;
`;

export const OrderStatus = styled.div<{
  color: string;
}>`
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) => (props.color !== '#FFFFFF' ? 'white' : 'gray')};
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 24px;
  background: ${(prop) => prop.color};
  border: ${(props) =>
    props.color !== '#FFFFFF' ? 'none' : '1px solid #DFE3E8'};
`;

const PopoverCell = styled(TableCell)<{ opacity?: boolean }>`
  position: absolute;
  right: 0;
  border: 0;
  opacity: ${(props) => (props.opacity ? '0.3' : '1')};
`;

const CellWithSort = styled(TableCell)`
  text-align: center;
`;

const CellDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const CenterStatus = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const RemarkWrapper = styled.div`
  color: #919eab;
  margin-right: 10px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SubStatus = styled.p`
  margin: 5px 0 0 0;
  font-size: 12px;
  color: gray;
`;

export default TransferOrderTable;
