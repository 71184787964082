import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  CircularProgress,
} from '@mui/material';

const tableCellWidths = ['100px', '100px', '380px', '140px'];

const PosLoading = ({
  isSkeleton,
  isSupplierStock = false,
}: {
  isSkeleton?: boolean;
  isSupplierStock?: boolean;
}) => {
  const numberOfRows = isSupplierStock ? 20 : 10;
  return isSkeleton ? (
    <Table>
      <TableBody>
        {Array.from(new Array(numberOfRows)).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {tableCellWidths.map((item: string) => (
              <TableCell sx={{ width: `${item}` }}>
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={50}
                  sx={{ bgcolor: 'grey.100' }}
                  animation={'wave'}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Container>
      <CircularProgress />
    </Container>
  );
};

const Container = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PosLoading;
