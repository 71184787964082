import styled from '@emotion/styled/macro';
import api from '@tyrio/api-factory';
import { AppointmentReq, DBDeliveryTypesApi, DBServicesApi } from '@tyrio/dto';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SideMenuLayout } from '../../../../components/SideMenuLayout/SideMenuLayout';
import { useAuth } from '../../../../context/AuthContext';
import {
  ActiveInfo,
  initializeInput,
  PosCartContext,
} from '../../../../context/PosCartContext';
import { POSContext } from '../../../../context/POSContext';
import { useCalculatePrice } from './helpers/calculation';
import { usePosCartData } from './helpers/cart-data';
import { informationList } from './InformationList/information-list';
import { ListItem } from './InformationList/ListItem';
import { OldTyresDisposal } from './OldTyres/OldTyresDisposal';
import { useGetServiceDetails } from './Steps/Appointment/queries/get-service-details';
import { AddressCard } from './Steps/DeliveryMethod/components/AddressCard';
import { InfoFooter } from './Steps/Footer';
import { InfoSteps } from './Steps/InfoSteps';
import { CancelModal } from '@tyrio/ui-library';

export const Cart = () => {
  const { client } = useAuth();

  useGetServiceDetails();

  const { modalData, servicesData } = usePosCartData();

  const {
    selectedCustomer,
    selectedWarehouseId,
    isVatIncluded,
    setIsPosCheckout,
    shouldRefetch,
  } = useContext(POSContext);
  const {
    activeInfo,
    setActiveInfo,
    input,
    setInput,
    customerDetails,
    setCustomerDetails,
    appointmentDetails,
    deliveryAddress,
    setDeliveryAddress,
    setDeliveryPrice,
    deliveryPrice,
    setIsRecommendedOpen,
    useCashPrice,
  } = useContext(PosCartContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { vat } = useCalculatePrice(
    modalData,
    deliveryPrice,
    isVatIncluded,
    selectedCustomer?.rebateCalculation?.rebate ?? 0,
    selectedCustomer?.rebateCalculation?.discount ?? 0,
    useCashPrice
  );

  const [oldTyresDisposalServices, setOldTyresDisposalServices] = useState<
    DBServicesApi['recommended']['response'] | []
  >([]);

  const { data: activeDeliveries } = useQuery(
    ['get_deliveries_by_branch', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBDeliveryTypesApi['list']>(
        'get_deliveries_by_branch',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    },
    { enabled: shouldRefetch }
  );

  useQuery(
    ['get_services_by_branch_id', selectedWarehouseId?.toString()],
    async () => {
      return await api.fetch<DBServicesApi['list']>(
        'get_services_by_branch_id',
        {
          branchId: selectedWarehouseId?.toString(),
        }
      );
    },
    {
      onSuccess: (data) => {
        const filtered: DBServicesApi['recommended']['response'] =
          data.filter((item) => item.serviceCategoryId === '13204') ?? [];
        setOldTyresDisposalServices(filtered);
      },
      enabled: shouldRefetch,
    }
  );

  // TODO: refactor this method to be more generic
  const getDetails = (key: string) => {
    if (key === 'customer' && !_.isEmpty(input.customer))
      return `${input.customer['company_name'] ?? ''}   ${
        input.customer['first_name'] ?? ''
      } ${input.customer['last_name'] ?? ''}   ${
        input.customer['zip_code'] ?? ''
      } ${input.customer['city'] ?? ''}`;
    else if (
      key === 'delivery_method' &&
      !_.isEmpty(input.delivery_method['deliveryType'])
    )
      return `${input.delivery_method['deliveryType']?.delivery}`;
    else if (key === 'vehicle' && !_.isEmpty(input.vehicle) && input.vehicle)
      return `${input.vehicle['brand']?.toUpperCase() ?? ''} ${
        input.vehicle['model']?.toUpperCase() ?? ''
      }`;
    else if (
      key === 'payment' &&
      !_.isEmpty(input.payment) &&
      input.payment &&
      input.payment.length > 0
    ) {
      let paymentInfo = '';
      input.payment.map((payment, index) => {
        paymentInfo += payment.selectedMethod;
        if (index < input.payment.length - 1) paymentInfo += ', ';
        return paymentInfo;
      });
      return paymentInfo;
    }
    return '';
  };

  const generateKey = (text: string) => {
    return text !== 'Delivery method'
      ? (text.toLowerCase() as unknown as ActiveInfo)
      : 'delivery_method';
  };

  useEffect(
    function updateDetails() {
      if (selectedCustomer === null && input.isTyrioCustomer) {
        setCustomerDetails({});
        setDeliveryAddress([]);
        setInput(initializeInput());
      } else if (selectedCustomer !== null && _.isEmpty(input.customer)) {
        setCustomerDetails({
          first_name: selectedCustomer?.firstName ?? '',
          last_name: selectedCustomer?.lastName ?? '',
          country:
            selectedCustomer?.countryId ?? client?.address?.countryId ?? '',
          address: selectedCustomer?.address ?? '',
          zip_code: selectedCustomer?.zipCode ?? '',
          city: selectedCustomer?.city ?? '',
          mobile_phone: selectedCustomer?.mobilePhone ?? '',
          email: selectedCustomer?.email ?? '',
          passport: '',
          customer_remark: selectedCustomer?.remark ?? '',
          internal_remark: '',
          company_name: selectedCustomer?.companyDisplayName
            ? selectedCustomer?.companyDisplayName
            : selectedCustomer?.companyOfficialName ?? '',
          vat: selectedCustomer?.viesVatNumber ?? '',
        });

        setDeliveryAddress([
          {
            address: selectedCustomer?.address ?? '',
            zip: selectedCustomer?.zipCode ?? '',
            city: selectedCustomer?.city ?? '',
            country:
              selectedCustomer?.countryId ?? client?.address?.countryId ?? '',
            firstName: selectedCustomer?.firstName ?? '',
            lastName: selectedCustomer?.lastName ?? '',
            mobilePhone: selectedCustomer?.mobilePhone ?? '',
            email: selectedCustomer?.email ?? '',
            companyName: selectedCustomer?.companyDisplayName
              ? selectedCustomer?.companyDisplayName
              : selectedCustomer?.companyOfficialName ?? '',
            fromCustomer: true,
          },
        ]);
        setInput((prevState) => ({
          ...prevState,
          isTyrioCustomer: true,
        }));
      }
    },
    [
      client?.address?.countryId,
      input,
      input.customer,
      input.isTyrioCustomer,
      selectedCustomer,
      setCustomerDetails,
      setDeliveryAddress,
      setInput,
    ]
  );

  useEffect(
    function disableDelivery() {
      if (servicesData.length > 0) {
        informationList[1].disabled = true;
        setDeliveryPrice(0);
        if (activeInfo === 'DELIVERY_METHOD') setActiveInfo('');
      } else informationList[1].disabled = false;
    },
    [activeInfo, servicesData.length, setActiveInfo, setDeliveryPrice]
  );

  useEffect(
    function updateDeliveryAddress() {
      if (!_.isEmpty(customerDetails))
        setDeliveryAddress([
          {
            address: customerDetails['address'],
            zip: customerDetails['zip_code'],
            city: customerDetails['city'],
            country: customerDetails['country'] ?? '',
            firstName: customerDetails['first_name'] ?? '',
            lastName: customerDetails['last_name'] ?? '',
            mobilePhone: customerDetails['mobile_phone'] ?? '',
            email: customerDetails['email'] ?? '',
            companyName: customerDetails['company_name'],
            fromCustomer: true,
          },
        ]);
    },
    [customerDetails, setDeliveryAddress]
  );

  useEffect(
    function updateLocaleStorage() {
      if (
        !_.isEqual(customerDetails, input.customer) &&
        input.isTyrioCustomer === true
      )
        setInput({
          customer: customerDetails,
          appointment: appointmentDetails,
          isCustomerEditDisabled: true,
          isCustomerSwitchActive: true,
          isTyrioCustomer: true,
          isSudRegCustomer: false,
          delivery_method: { address: deliveryAddress },
          vehicle: {},
          payment: [],
        });
    },
    [
      appointmentDetails,
      customerDetails,
      deliveryAddress,
      input.customer,
      input.isTyrioCustomer,
      setInput,
    ]
  );

  const hasAppointments = () => {
    if (servicesData.length > 0) {
      const appointmentsObj = _.get(appointmentDetails, 'appointments') ?? {};
      const appointments = Object.values(
        appointmentsObj
      ) as unknown as AppointmentReq[];
      return (
        appointments?.some((item) => _.has(item, 'appointmentDateTo')) ?? false
      );
    }
    return true;
  };

  const renderCart = () => {
    return (
      <Wrapper>
        {isModalOpen && (
          <CancelModal
            LBAction={() => setIsModalOpen(false)}
            RBAction={() => {
              setIsModalOpen(false);
              setIsRecommendedOpen(false);
              setIsPosCheckout(true);
            }}
            text={`You didn't add the appointment`}
          />
        )}
        {activeInfo === '' ? (
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <ListWrapper>
              {informationList.map((item) => (
                <div
                  key={item.text}
                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                >
                  {getDetails(generateKey(item.text)) === '' ? (
                    <ListItem
                      key={item.text}
                      icon={item.icon}
                      text={item.text}
                      disabled={
                        item.disabled ||
                        (item.text === 'Delivery method' &&
                          activeDeliveries !== undefined &&
                          activeDeliveries?.length === 0)
                      }
                      onClick={() =>
                        setActiveInfo(
                          item.text !== 'Delivery method'
                            ? (item.text.toUpperCase() as unknown as ActiveInfo)
                            : 'DELIVERY_METHOD'
                        )
                      }
                    />
                  ) : (
                    <AddressCard
                      key={item.text}
                      icon={item.icon}
                      text={item.text}
                      details={getDetails(generateKey(item.text))}
                      onClick={() =>
                        setActiveInfo(
                          item.text !== 'Delivery method'
                            ? (item.text.toUpperCase() as unknown as ActiveInfo)
                            : 'DELIVERY_METHOD'
                        )
                      }
                      disabled={
                        item.disabled ||
                        (item.text === 'Delivery method' &&
                          activeDeliveries !== undefined &&
                          activeDeliveries?.length === 0)
                      }
                      deliveryIcon={
                        item.text === 'Delivery method'
                          ? input.delivery_method['deliveryType']?.icon
                          : ''
                      }
                      price={
                        item.text === 'Delivery method'
                          ? !isVatIncluded &&
                            input.delivery_method['deliveryType']?.price
                            ? input.delivery_method['deliveryType']?.price
                            : input.delivery_method['deliveryType']?.price
                            ? input?.delivery_method['deliveryType']?.price +
                              (input?.delivery_method['deliveryType']?.price *
                                vat) /
                                100
                            : 0
                          : 0
                      }
                      showDeliveryDetails={
                        item.text === 'Delivery method' ||
                        item.text === 'Vehicle' ||
                        item.text === 'Payment'
                      }
                      detailsText={
                        item.text === 'Vehicle' &&
                        input.vehicle?.['licence_plate']
                          ? `Licence plate:  ${input.vehicle?.['licence_plate']}`
                          : ''
                      }
                      disableMaxWidth={true}
                    />
                  )}
                </div>
              ))}
            </ListWrapper>
            {/* Here we are rendering only disposal tyres */}
            {oldTyresDisposalServices.length > 0 && (
              <OldTyresDisposal items={oldTyresDisposalServices} />
            )}
          </div>
        ) : (
          <div style={{ height: '100%' }}>
            {activeInfo && InfoSteps(activeInfo)}
          </div>
        )}
      </Wrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posCart"
      children={renderCart()}
      showSwitch={false}
      checked={false}
      shouldShowTitle={false}
      footer={
        activeInfo === '' && (
          <InfoFooter
            text1={'OFFER'}
            text2={'CHECKOUT'}
            onCancel={() => setActiveInfo('')}
            onSubmit={() => {
              if (!hasAppointments()) setIsModalOpen(true);
              else {
                setIsRecommendedOpen(false);
                setIsPosCheckout(true);
              }
            }}
            shouldShowAlert={modalData.length > 0}
            disableCancel={true}
            disableSubmit={false}
          />
        )
      }
    />
  );
};

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: white;
  height: 100%;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;
