import styled from '@emotion/styled';
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { PosItem, PosItemResponse, PosItemSet } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import _, { debounce } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { POSContext } from '../../../../context/POSContext';
import NoData from '../../components/DataIndicators/NoData';
import SidebarFiltersList from '../../components/FilterSidebarList';
import { useAccordion } from '../../helpers/accordion';
import { useGetPosData } from '../../query/get-pos-data';
import PosLoading from '../DataIndicators/Loading';
import { PosRowCell } from './Row';
import {
  NumberWrapper,
  RowItem,
  PriceWrapper,
  QtyPriceWrapper,
  StyledTableCell,
} from './RowItem';
import { formatCurrencyWithoutSymbol } from '../../../../helpers/currency-format';

import banner from '../../../../../../assets/Banner.png';
import { PosSearchContext } from '../SearchPOS/SearchContext';

interface PosTableProps {
  onOpenSidebarFilters: () => void;
}

type StockType = 'current' | 'branches' | 'suppliers';

export const PosTable = ({ onOpenSidebarFilters }: PosTableProps) => {
  const { PosTableItems } = useAccordion();
  const [shouldLoadMore, setShouldLoadMore] = useState(true);
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const { supplierPage, setSupplierPage, selectedSizes } =
    useContext(PosSearchContext);

  const {
    myStockItems,
    branchesStockItems,
    supplierStockItems,
    filterValues,
    isVatIncluded,
    setIsBranchesStockExpanded,
    setIsSupplierStockExpanded,
  } = useContext(POSContext);

  const { showMyStockLoader, showBranchesLoader, showSuppliersLoader } =
    useGetPosData(filterValues);

  const lastPostElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (hideLoadMore && !shouldLoadMore) return;
      const debouncedLoadMore = debounce(() => {
        console.log('Loading more data...');
        setShouldLoadMore(false);
        setSupplierPage(supplierPage + 1);
      }, 200);

      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
      if (typeof IntersectionObserver === 'undefined') {
        console.error('IntersectionObserver is not supported');
        return;
      }

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !hideLoadMore) {
            debouncedLoadMore();
          }
        },
        { threshold: 0.5, rootMargin: '200px' }
      );

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideLoadMore, shouldLoadMore]
  );

  const renderRows = (
    arr: PosItem[],
    stockType: StockType,
    hasSets: boolean,
    showBrand?: boolean,
    isLoading?: boolean,
    isSet?: boolean,
    size?: number
  ) => {
    const gridWidth = !showBrand ? 'calc(100% - 150px)' : '100%';

    const renderGrid = () => (
      <Grid width={gridWidth}>
        {arr?.map((posItem, index) => (
          <>
            <RowItem
              key={`${posItem.id}_${index}`}
              posItem={posItem}
              showBrand={showBrand}
              isSet={isSet}
              isLast={isSet && index === 1}
            />
            {stockType === 'current' &&
              !hasSets &&
              size !== 2 &&
              !isSet &&
              ((arr?.length < 5 && index === arr?.length - 1) ||
                index === 4) && <img src={banner} alt="Tyrio banner" />}
          </>
        ))}
      </Grid>
    );

    const renderNoData = () => (
      <NoDataAlertWrapper>
        <NoData />
      </NoDataAlertWrapper>
    );

    if (stockType !== 'suppliers') {
      return arr?.length > 0 ? renderGrid() : renderNoData();
    }
    return (
      <>
        {arr?.length > 0 && renderGrid()}
        {isLoading ? null : arr?.length === 0 ? renderNoData() : null}
      </>
    );
  };

  const renderSets = (
    data: PosItemSet[],
    isLoading: boolean,
    stockType: StockType
  ) => {
    return (
      <ParentSetWrapper>
        {data.map((value) => {
          const minQtyItem = _.minBy(
            value.items,
            (item) => item.quantity - item.reserved
          );
          const totalPrice = _.sumBy(value.items, (item) =>
            !isVatIncluded
              ? Number(item.price ?? 0)
              : Number(item.inclusiveVatPrice ?? 0)
          );
          const cashPrice = _.sumBy(value.items, (item) =>
            !isVatIncluded ? item.cashPrice : Number(item.vatCashPrice)
          );
          const avatarBg =
            minQtyItem && minQtyItem.quantity && minQtyItem.quantity >= 4
              ? 'transparent'
              : minQtyItem?.quantity === 0
              ? tyrioUI.colors.red.B30
              : tyrioUI.colors.yellow.B100;

          const textColor =
            minQtyItem && minQtyItem?.quantity > 4
              ? '#212B36'
              : minQtyItem && minQtyItem?.quantity < 4
              ? 'white'
              : 'black';

          const sortSetsBySearchedSizes = (sets: PosItem[]) => {
            return sets.sort((a, b) => {
              const searchedSizes =
                typeof filterValues['size'] === 'string'
                  ? [filterValues['size']]
                  : filterValues['size'];
              return (
                (searchedSizes as string[])?.indexOf(a.product.size as string) -
                (searchedSizes as string[])?.indexOf(b.product.size as string)
              );
            });
          };
          return (
            <SetWrapper>
              <BrandWrapper>
                <Typography fontWeight={400}>
                  {value.key.split('_')[0]}
                </Typography>
              </BrandWrapper>
              {renderRows(
                sortSetsBySearchedSizes(value.items as PosItem[]),
                stockType,
                false,
                false,
                isLoading,
                true
              )}
              {
                <StyledTableCell
                  style={{ maxWidth: '230px', minWidth: '230px' }}
                  isSet={true}
                >
                  {' '}
                  <QtyPriceWrapper
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      fontSize: '16px',
                      lineHeight: '24px',
                    }}
                  >
                    <NumberWrapper>
                      {formatCurrencyWithoutSymbol(totalPrice)}
                    </NumberWrapper>
                    <NumberWrapper>
                      {formatCurrencyWithoutSymbol(cashPrice)}
                    </NumberWrapper>
                    <NumberWrapper>
                      <PriceWrapper style={{ justifyContent: 'center' }}>
                        <Avatar
                          sx={{
                            background: avatarBg,
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            letterSpacing: '0.3px',
                            color: textColor,
                            width:
                              minQtyItem &&
                              minQtyItem?.quantity.toString()?.length > 4
                                ? 'fit-content'
                                : '40px',
                            height: '40px',
                            position: 'inherit',
                          }}
                        >
                          {minQtyItem?.stockType === 'SUPPLIER' &&
                          minQtyItem.quantity - minQtyItem.reserved > 50
                            ? '50+'
                            : minQtyItem &&
                              minQtyItem?.quantity - minQtyItem?.reserved}
                        </Avatar>
                      </PriceWrapper>
                    </NumberWrapper>
                  </QtyPriceWrapper>
                </StyledTableCell>
              }
            </SetWrapper>
          );
        })}
      </ParentSetWrapper>
    );
  };

  const renderStock = (
    data: PosItemResponse,
    isLoading: boolean,
    stockType: StockType
  ) => {
    if (isLoading) {
      return (
        <PosLoading
          isSkeleton={true}
          isSupplierStock={stockType === 'suppliers' ? true : false}
        />
      );
    }
    const groupUnmatchedBySize = _.groupBy(
      data.unmatchedItems,
      (item) =>
        `${item.product.width}/${item.product.height}-${item.product.rimDiameter}`
    );

    const hasSets = !_.isEmpty(data.matchedSets);
    const sizes = Object.keys(groupUnmatchedBySize).map((size) => size);
    const sortedSizes = sizes.sort((a, b) => {
      const aIndex =
        selectedSizes?.findIndex((selected) => {
          return (
            a.replace(/\D/g, '').trim() === selected.replace(/\D/g, '').trim()
          );
        }) ?? -1;
      const bIndex =
        selectedSizes?.findIndex(
          (selected) =>
            b.replace(/\D/g, '').trim() === selected.replace(/\D/g, '').trim()
        ) ?? -1;
      return aIndex - bIndex;
    });

    return (
      <>
        {renderSets(data.matchedSets, isLoading, stockType)}
        {hasSets && stockType === 'current' && (
          <img src={banner} alt="Tyrio banner" />
        )}
        {sortedSizes &&
        sortedSizes?.length > 1 &&
        filterValues['categoryId'] !== '8'
          ? sortedSizes?.map((size: string, index: number) => {
              return (
                <>
                  <TitleWrapper>
                    <Title>
                      UNMATCHED TYRES SIZE {index + 1} - {size}
                    </Title>
                  </TitleWrapper>
                  {renderRows(
                    groupUnmatchedBySize[size],
                    stockType,
                    hasSets,
                    true,
                    isLoading,
                    false,
                    index + 1
                  )}
                </>
              );
            })
          : renderRows(
              data.unmatchedItems,
              stockType,
              false,
              true,
              isLoading,
              false
            )}
        {stockType === 'suppliers' && shouldLoadMore && (
          <PosLoading isSkeleton={false} />
        )}
      </>
    );
  };

  const isFilterInactive = _.isEmpty(filterValues);

  // if my branch stock is empty expand branch stock
  useEffect(() => {
    if (
      myStockItems?.matchedSets?.length === 0 &&
      myStockItems?.unmatchedItems?.length === 0 &&
      !showMyStockLoader
    )
      setIsBranchesStockExpanded(true);
  }, [
    myStockItems.matchedSets,
    myStockItems.unmatchedItems,
    setIsBranchesStockExpanded,
    showMyStockLoader,
  ]);

  // if my branch and branch stock is empty expand supplier stock
  useEffect(() => {
    if (
      myStockItems?.matchedSets?.length === 0 &&
      myStockItems?.unmatchedItems?.length === 0 &&
      branchesStockItems?.matchedSets?.length === 0 &&
      branchesStockItems?.unmatchedItems?.length === 0 &&
      !showMyStockLoader &&
      !showBranchesLoader
    )
      setIsSupplierStockExpanded(true);
  }, [
    myStockItems,
    branchesStockItems,
    setIsSupplierStockExpanded,
    showBranchesLoader,
    showMyStockLoader,
  ]);

  const supplierStock = useMemo(() => {
    const endIndex = supplierPage * 20 + 20;
    const unmatched = supplierStockItems.unmatchedItems.slice(0, endIndex);

    if (supplierStockItems.unmatchedItems.length <= endIndex) {
      setHideLoadMore(true);
      setShouldLoadMore(false);
    } else {
      setHideLoadMore(false);
      setShouldLoadMore(true);
    }
    return {
      matchedSets: supplierStockItems.matchedSets,
      unmatchedItems: unmatched,
    };
  }, [supplierPage, supplierStockItems]);

  return (
    <Table>
      <PosTableHead>
        <PosTableHeadRow>
          <PosTableCell>
            <SidebarFilterWrapper>
              <SidebarFiltersList onOpen={onOpenSidebarFilters} isPos={true} />
            </SidebarFilterWrapper>
            <PosTableHeadWrapper>
              <PosTableHeadItem>Price</PosTableHeadItem>
              <PosTableHeadItem>Cash</PosTableHeadItem>
              <PosTableHeadItem>Quantity</PosTableHeadItem>
            </PosTableHeadWrapper>
          </PosTableCell>
        </PosTableHeadRow>
      </PosTableHead>
      <TableBody sx={{ padding: 0 }} id="hide">
        <PosRowCell>
          <Grid>
            {myStockItems && (
              <Grid>
                <PosTableItems posItemKey="currentBranchStock">
                  {renderStock(
                    myStockItems,
                    showMyStockLoader && !isFilterInactive,
                    'current'
                  )}
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {branchesStockItems && (
              <Grid id="branches_stock">
                <PosTableItems posItemKey="branchesStock">
                  {renderStock(
                    branchesStockItems,
                    showBranchesLoader && !isFilterInactive,
                    'branches'
                  )}
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {supplierStockItems && supplierStock && (
              <Grid>
                <PosTableItems posItemKey="supplierStock">
                  <Grid>
                    {renderStock(
                      supplierStock,
                      showSuppliersLoader && !isFilterInactive,
                      'suppliers'
                    )}
                    <div ref={lastPostElementRef} style={{ height: '1px' }} />
                  </Grid>
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>
      </TableBody>
    </Table>
  );
};

const PosTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PosTableCell = styled(TableCell)`
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
`;

export const SidebarFilterWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 140px);
`;

const PosTableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 210px;
  width: 100%;
`;

const PosTableHeadRow = styled(TableRow)`
  background: #fff;
`;

const PosTableHeadItem = styled.span`
  color: ${tyrioUI.colors.black.B90};
  font-size: 14px;
`;

const NoDataAlertWrapper = styled.div`
  padding: 24px;
`;

const TitleWrapper = styled.div`
  background: #c4cdd5;
  color: white;
  height: 40px;
  width: 100%;
  align-items: center;
  display: flex;
`;

export const Title = styled(Typography)`
  margin-left: 10px;
  font-weight: 500;
`;

const SetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 2px solid rgb(223, 227, 232);
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  max-width: 150px;
`;

const ParentSetWrapper = styled.div`
  > div&:not(:last-child) {
    border-bottom: 2px solid rgb(223, 227, 232);
  }
`;
