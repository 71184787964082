import { useState } from 'react';

import styled from '@emotion/styled/macro';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import TextField from '@mui/material/TextField/TextField';

import {
  DBDeliveryTypes,
  DBTransferOrderItem,
  DBTransferOrderType,
} from '@prisma/client';
import {
  DBTransferOrderBranchDetails,
  DBTransferOrderLineItem,
  DBTransferOrderStatusEnum,
  InProcessSubStatuses,
  SSE_ENTITY_KEYS,
  TransferOrderStatusShape,
} from '@tyrio/dto';
import { WmsIcon, wmsIcons } from '@tyrio/wms-ui-library';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import { useAuth } from '../../../context/AuthContext';
import { useWS } from '../../../context/WSContext';
import { useDebounce } from '../../../hooks/useDebounce';
import SelectInput from '../components/SelectInput';
import { useGetDeliveryMethods } from '../../../pages/dashboard/delivery-methods/querys/get-delivery-methods';

interface TransferOrderDetailsTabProps {
  selectedOrderData: DBTransferOrderItem;
  originalOrderData: DBTransferOrderItem | null;
  setSelectedOrderData: (item: DBTransferOrderItem | null) => void;
}

const TransferOrderDetailsTab = ({
  selectedOrderData,
  originalOrderData,
  setSelectedOrderData,
}: TransferOrderDetailsTabProps) => {
  const [search, setSearch] = useState('');
  const { state } = useWS();
  const { user } = useAuth();
  const debouncedSearch = useDebounce(search, 500);

  const orderStatuses = parseEnumToArray(DBTransferOrderStatusEnum);
  const processSubStatus = parseEnumToArray(InProcessSubStatuses);

  const { deliveryMethodData } = useGetDeliveryMethods();

  const deliveryTypes = (data?: DBDeliveryTypes[]) => {
    if (!data) return [];

    return data.map((d) => {
      return { label: d.name, value: d.id };
    });
  };

  const filteredData: DBTransferOrderLineItem[] = selectedOrderData?.lineItems
    ? Object.values(selectedOrderData?.lineItems)?.filter(
        (element) =>
          element?.productName
            ?.toLowerCase()
            .includes(debouncedSearch?.toLowerCase()) ||
          element.ean.toLowerCase().includes(debouncedSearch?.toLowerCase()) ||
          element.sku.toLowerCase().includes(debouncedSearch?.toLowerCase()) ||
          element.productionYear
            ?.toLowerCase()
            .includes(debouncedSearch.toLowerCase())
      )
    : [];

  const icon =
    selectedOrderData.type === DBTransferOrderType.INBOUND
      ? wmsIcons.inbound
      : wmsIcons.outbound;

  const lockEntity =
    state.locks &&
    state.locks[`${SSE_ENTITY_KEYS.TRANSFER_ORDER}/${selectedOrderData.id}`];

  const isDisabled = !(
    lockEntity === undefined || lockEntity?.userId === user?.id
  );

  const orderStatus = selectedOrderData?.orderStatus as
    | { status?: DBTransferOrderStatusEnum }
    | undefined;

  const originalOrderStatus = originalOrderData?.orderStatus as
    | { status?: DBTransferOrderStatusEnum }
    | undefined;

  const isDeliveryTypeDisabled =
    isDisabled || orderStatus?.status === DBTransferOrderStatusEnum.CANCELLED;

  const isOrderStatusDisabled =
    isDisabled ||
    originalOrderStatus?.status === DBTransferOrderStatusEnum.CANCELLED;

  const source =
    selectedOrderData.type === 'OUTBOUND'
      ? selectedOrderData.inboundBranchDetails
      : selectedOrderData.outboundBranchDetails;

  const destination =
    selectedOrderData.type === 'OUTBOUND'
      ? selectedOrderData.outboundBranchDetails
      : selectedOrderData.inboundBranchDetails;

  const displayAddress = (value: DBTransferOrderBranchDetails) => {
    const address = [value.address, value.branchCity]
      .filter((v) => v !== undefined)
      .join(', ');
    return address;
  };

  const sumReceived = Object.values(filteredData)
    .map((item) => item.received)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <Container>
      <PaddingWrapper>
        <DropdownWrapper>
          <SelectInput
            valueName="orderStatus"
            attributeKey={'status'}
            label="Order status"
            dropdownData={orderStatuses}
            selectedOrderData={selectedOrderData}
            setSelectedOrderData={setSelectedOrderData}
            isDisabled={isOrderStatusDisabled}
          />

          <FormControl fullWidth>
            <InputLabel>Delivery type</InputLabel>
            <Select
              label="Delivery type"
              value={selectedOrderData.deliveryTypeId}
              disabled={isDeliveryTypeDisabled}
              onChange={({ target: { value } }) => {
                selectedOrderData &&
                  setSelectedOrderData({
                    ...selectedOrderData,
                    deliveryTypeId: value,
                  });
              }}
            >
              {deliveryTypes(
                deliveryMethodData?.filter((item) => item.isActive)
              ).map((item) => (
                <MenuItem value={item.value} key={item.label}>
                  <Typography> {item.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {(
            selectedOrderData?.orderStatus as unknown as TransferOrderStatusShape
          ).status === DBTransferOrderStatusEnum.IN_PROCESS && (
            <SelectInput
              valueName="orderStatus"
              attributeKey={'subStatus'}
              label="Order status"
              dropdownData={processSubStatus}
              selectedOrderData={selectedOrderData}
              setSelectedOrderData={setSelectedOrderData}
              isDisabled={isDisabled}
            />
          )}
        </DropdownWrapper>
        <DeliveryContainer>
          <SourceItem>
            <Label>Source warehouse</Label>
            <BranchName>
              {(source as unknown as DBTransferOrderBranchDetails).branchName}
            </BranchName>
            <BranchAddress>
              {displayAddress(
                source as unknown as DBTransferOrderBranchDetails
              )}
            </BranchAddress>
          </SourceItem>

          <StyledIcon icon={icon} alt="icon" />

          <SourceItem>
            <Label>Destination warehouse</Label>
            <BranchName>
              {
                (destination as unknown as DBTransferOrderBranchDetails)
                  .branchName
              }
            </BranchName>
            <BranchAddress>
              {displayAddress(
                destination as unknown as DBTransferOrderBranchDetails
              )}
            </BranchAddress>
          </SourceItem>
        </DeliveryContainer>

        <SearchContainer>
          <TextField
            id="search-orders"
            variant="outlined"
            label="Search list"
            size="small"
            sx={{ width: '250px' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </SearchContainer>
      </PaddingWrapper>

      <ProductsTable>
        <TableHead>
          <div>Item</div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '25px' }}>Ordered</div>
            <div>Received</div>
          </div>
        </TableHead>
        <ProductItemContainer>
          {filteredData &&
            Object.values(filteredData).map((item) => (
              <ProductItem>
                <ProductDetails>
                  <div>{`${item.brand ? item.brand + ' ' : ''}${
                    item.productName
                  }`}</div>
                  <EanIpcSkuWrapper>
                    <div>EAN: {`${item.ean} `}</div>
                    <div> SKU: {`${item.sku} `}</div>
                    <div>DOT: {`${item.dot} `}</div>
                  </EanIpcSkuWrapper>
                </ProductDetails>
                <div style={{ display: 'flex' }}>
                  <Quantity style={{ marginRight: '15px' }}>
                    {item.quantity}
                  </Quantity>
                  <Quantity
                    style={{
                      borderRadius: '8px 0px 0px 8px',
                      background: '#edeff0',
                    }}
                  >
                    {item.received}
                  </Quantity>
                </div>
              </ProductItem>
            ))}
          <TotalContainer>
            TOTAL:
            <TotalQuantity>{`${sumReceived} / ${selectedOrderData.total}`}</TotalQuantity>
          </TotalContainer>
        </ProductItemContainer>
      </ProductsTable>

      {/* TODO: fix remark on the bottom */}
      <div style={{ padding: '16px' }}>
        <TextField
          value={selectedOrderData.remark}
          onChange={(e) => {
            setSelectedOrderData({
              ...selectedOrderData,
              remark: e.target.value,
            });
          }}
          label="Remark"
          variant="outlined"
          disabled={isDisabled}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100svh - 259px);
`;

const PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
`;

const StyledIcon = styled(WmsIcon)`
  margin-left: 14px;
  margin-right: 14px;
  height: 24px;
  width: 27px;
  color: #637381;
`;

const DeliveryContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

const SourceItem = styled.div`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const BranchName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #212b36;
`;

const BranchAddress = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
`;

const SearchContainer = styled.div`
  margin-top: 20px;
`;

const ProductsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: #ecf0f4;
  padding: 16px;
`;

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;
`;

const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 16px;
  padding-right: 0;
  border-bottom: 1px solid #dfe3e8;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 80px;
  height: 50px;
  background: #f4f6f8;
  border-radius: 8px;
`;

const TotalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #212b36;
  margin-top: 16px;
`;

const TotalQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 80px;
  height: 50px;
  background: #f4f6f8;
  border-radius: 8px 0px 0px 8px;
  background: #454f5b;
  color: white;
  margin-left: 10px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 16px;
`;

export default TransferOrderDetailsTab;
