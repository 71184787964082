import {
  TyrioCheckboxInput,
  TyrioRadioInput,
  TyrioSelectInput,
  TyrioStringInput,
  TyrioMultiselectInput,
  TyrioSwitchInput,
  TyrioInputDivider,
} from '@tyrio/forms';
import { zodServiceSchema } from './validation';

export interface DropdownValues {
  label: string;
  value: string;
}

type Input = TyrioStringInput & { skipField?: boolean };
type Select = TyrioSelectInput & { skipField?: boolean };
type Checkbox = TyrioCheckboxInput & { skipField?: boolean };

export type InputType =
  | Input
  | Select
  | Checkbox
  | (TyrioRadioInput & { skipField?: boolean })
  | (TyrioMultiselectInput & { skipField?: boolean })
  | (TyrioSwitchInput & { skipField?: boolean })
  | (TyrioInputDivider & { skipField?: boolean })
  | (TyrioMultiselectInput & { skipField?: boolean });

export interface FormShape {
  key?: string;
  isGroup?: boolean;
  title?: string;
  inputs: InputType[];
}

export const ServiceFormHelper = (
  categories: DropdownValues[],
  productCategory: DropdownValues[],
  subcategoryDropdown: DropdownValues[],
  rims: DropdownValues[],
  isNew: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues: any
) => {
  const form: FormShape[] = [
    {
      isGroup: false,
      inputs: [
        {
          id: 'name',
          width: '100%',
          type: 'input.string',
          label: 'Name',
        },
      ],
    },
    {
      isGroup: false,
      inputs: [
        {
          id: 'description',
          width: '100%',
          type: 'input.string',
          label: 'Description',
        },
      ],
    },
    {
      isGroup: false,
      inputs: [
        {
          id: 'erpId',
          width: '100%',
          type: 'input.string',
          label: 'ERP ID',
        },
      ],
    },
    {
      isGroup: true,
      title: 'Service price',
      inputs: [
        {
          id: 'radioButton',
          width: '100%',
          type: 'input.radio',
          label: '',
          options: [
            {
              label: 'Fixed price',
              value: 'fixedPrice',
            },
            {
              label: 'Charged by labor time',
              value: 'chargedByLaborTime',
            },
            {
              label: 'Free service',
              value: 'freeService',
            },
          ],
        },
      ],
    },
    {
      isGroup: true,
      title: 'Settings',
      inputs: [
        {
          id: 'serviceDuration',
          width: '30%',
          type: 'input.string',
          label: 'Service duration',
          helperText: 'minutes',
          inputType: 'number',
        },
      ],
    },
    {
      isGroup: true,
      title: 'Cross-Sell Categories',
      inputs: [
        {
          id: 'productCategoryId',
          width: '35%',
          type: 'input.select',
          options: productCategory,
          label: 'Category',
        },
        {
          id: 'subCategoryId',
          width: '62%',
          type: 'input.multiselect',
          options: subcategoryDropdown ?? [],
          label: 'Subcategory',
        },
        {
          id: 'rimDiameter',
          width: '85%',
          type: 'input.multiselect',
          options: rims,
          label: 'Rim diameter',
        },
        {
          id: 'rft',
          width: '10%',
          type: 'input.checkbox',
          label: 'RFT',
          defaultChecked: initialValues.rft,
        },
      ],
    },
  ];

  const fixedPriceForm: FormShape = {
    inputs: [
      {
        id: 'servicePrice.fixedPrice.exclusiveVat',
        width: '40%',
        type: 'input.string',
        label: 'Price exclusive Vat',
        inputType: 'number',
      },
      {
        id: 'servicePrice.fixedPrice.inclusiveVat',
        width: '40%',
        type: 'input.string',
        label: 'Price inclusive Vat',
        inputType: 'number',
        disabled: true,
      },
    ],
  };

  const chargedByLaborTime: FormShape = {
    inputs: [
      {
        id: 'servicePrice.chargedByLaborTime.pricePerHour',
        width: '10%',
        type: 'input.string',
        label: 'Price per hour',
        variant: 'standard',
        disabled: true,
        helperText: 'inclusive VAT',
        inputType: 'number',
      },
      {
        id: 'servicePrice.chargedByLaborTime.laborTime',
        width: '20%',
        type: 'input.string',
        label: 'Labor time (hours)',
        inputType: 'number',
      },
      {
        id: 'servicePrice.chargedByLaborTime.finalPrice',
        width: '10%',
        type: 'input.string',
        variant: 'standard',
        disabled: true,
        label: 'Final price',
        helperText: 'inclusive VAT',
        inputType: 'number',
      },
    ],
  };

  const firstRow: FormShape = {
    inputs: [
      {
        id: 'code',
        width: '25%',
        type: 'input.string',
        label: 'Code',
        disabled: true,
      },
      {
        id: 'category',
        width: 'calc(100% - 25% - 16px)',
        type: 'input.select',
        options: [
          { label: 'This is main category', value: '1' },
          ...categories,
        ],
        label: 'Choose category',
        disabled: !isNew,
      },
    ],
  };

  return {
    form,
    fixedPriceForm,
    chargedByLaborTime,
    zodSchema: zodServiceSchema,
    firstRow,
  };
};
