import styled from '@emotion/styled';

import React from 'react';
import { Divider, FormControlLabel, Switch } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';

const SPACING = 24;
const MOBILE_SPACING = 10;
export const PageGridWrapper = styled.div<{ template?: string }>`
  padding: ${SPACING}px;
  display: grid;
  column-gap: ${SPACING}px;
  grid-template-columns: ${(props) => props.template ?? '380px auto'};
  @media (max-width: 768px) {
    padding: ${MOBILE_SPACING}px;
    column-gap: ${MOBILE_SPACING}px;
  }
`;

export const PageGridContainer = styled.div``;

export const PageGrid = styled.div`
  background-color: white;
  grid-row: 1;
  grid-column: auto;
  border-radius: 8px;
  position: relative;
`;

const HeaderWrapper = styled.div<{ hasButton?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.hasButton ? 'space-between' : '')};
  align-items: center;
  padding: 20px 16px;
  height: 64px;
  align-items: center;
  margin-bottom: 10px;
`;

const PageTitle = styled.h1`
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const ButtonWrapper = styled.button`
  background: none;
  border: none;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
`;

interface PageTemplateHeaderProps {
  showBack?: boolean;
  backAction?: () => void;
  title: string;
  switchedLabel?: string;
  switchedState?: boolean;
  setSwitchedState?: (value: boolean) => void;
}
export const PageTemplateHeader = ({
  backAction,
  title,
  showBack = true,
  switchedLabel = '',
  switchedState = false,
  setSwitchedState,
}: PageTemplateHeaderProps) => {
  const history = useHistory();
  return (
    <>
      <HeaderWrapper hasButton={!!switchedLabel}>
        {showBack && (
          <ButtonWrapper
            onClick={() => {
              if (backAction) return backAction();
              return history.goBack();
            }}
          >
            <ArrowBackIosIcon sx={{ marginRight: '10px' }} />
          </ButtonWrapper>
        )}
        <PageTitle>{title}</PageTitle>
        {switchedLabel && (
          <FormControlLabel
            control={
              <Switch
                id={switchedLabel}
                color="info"
                disabled={false}
                checked={switchedState}
                size="medium"
              />
            }
            label={switchedLabel}
            onClick={() => setSwitchedState && setSwitchedState(!switchedState)}
          />
        )}
      </HeaderWrapper>
      <Divider />
    </>
  );
};
