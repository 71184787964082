import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { NOWEGUMY_PRICELIST_RULESET } from './nowegumy/pricelist';
import { NOWEGUMY_STOCKLIST_RULESET } from './nowegumy/stocklist';
import { NOWEGUMY_CATALOG_RULESET } from './nowegumy/catalog';
// import { TRule } from '../typings';

export const NOWEGUMY_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: NOWEGUMY_CATALOG_RULESET,
  pricelistRuleset: NOWEGUMY_PRICELIST_RULESET,
  stockRuleset: NOWEGUMY_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
      productMeta: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'NOWEGUMY',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    external_rolling_noise_value: {
      db: '',
      '�': '',
    },
    label_noise: {
      db: '',
      '�': '',
    },
    photos: {
      '"': '',
    },
    price: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  async preprocessPricelist(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          (item['ean'] || (item['cai'] && item['manufacturer'])) &&
          !item['size'].includes('tka') &&
          !item['model'].toUpperCase().includes('SEMI-PNEUMATIC') &&
          (item['manufacturer'] === 'JOURNEY' ||
            item['manufacturer'] === 'ROADGUIDER' ||
            item['manufacturer'] === 'WANDA' ||
            item['manufacturer'] === 'OBOR' ||
            item['manufacturer'] === 'ARISUN' ||
            item['manufacturer'] === 'PIT BULL')
      ),
      this.preprocessKeys
    );
  },
  async preprocessStockList(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(
      json.filter(
        (item) =>
          (item['ean'] || (item['cai'] && item['manufacturer'])) &&
          !item['size'].includes('tka') &&
          !item['model'].toUpperCase().includes('SEMI-PNEUMATIC') &&
          (item['manufacturer'] === 'JOURNEY' ||
            item['manufacturer'] === 'ROADGUIDER' ||
            item['manufacturer'] === 'WANDA' ||
            item['manufacturer'] === 'OBOR' ||
            item['manufacturer'] === 'ARISUN' ||
            item['manufacturer'] === 'PIT BULL')
      ),
      this.preprocessKeys
    );
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    const filteredjson = json.filter(
      (item) =>
        (item['ean'] || (item['cai'] && item['manufacturer'])) &&
        !item['size'].includes('tka') &&
        !item['model'].toUpperCase().includes('SEMI-PNEUMATIC')
    );

    console.log(filteredjson);

    return handlePreprocessConversions(filteredjson, this.preprocessKeys);
  },
};
