import styled from '@emotion/styled';
import { FilterSidebar as SidebarFilters } from '@tyrio/products';
import { LandingPage, backIcon, tyrioIcons } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { POSContext } from '../../context/POSContext';
import Header from './components/Header';
import { PosTable } from './components/Table/Table';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from './components/SearchPOS/SearchContext';
import { WheelsSearchPage } from './components/WheelsSearch/WheelsSearchPage';
import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import { WheelsFilterSidebar } from './components/WheelsSearch/Filter/SidebarFilter';
import { useGetPosFilters } from './components/SearchPOS/query/get-filter';

interface LandingProps {
  sidebarOpen: boolean;
}

const Landing = ({ sidebarOpen }: LandingProps) => {
  const [isSidebarFiltersOpen, setIsSidebarFIltersOpen] = useState(false);
  const [isSidebarWheelsFiltersOpen, setIsSidebarWheelsFiltersOpen] =
    useState(false);

  const { setSelectedTableRow, filterValues, setFilterValue, shouldRefetch } =
    useContext(POSContext);
  const { activeTab, categoryId, motoDesiredOption, selectedSizes } =
    usePosSearchContext();
  const { filtersPos } = useGetPosFilters(filterValues, shouldRefetch);

  const isFilterInactive =
    (filterValues['size'] === undefined &&
      filterValues['searchKeyword'] === undefined &&
      filterValues['searchType'] === undefined) ||
    (categoryId === 2 &&
      motoDesiredOption === 'FRONT/REAR' &&
      selectedSizes?.length === 1);

  useEffect(() => {
    if (_.isEmpty(filterValues)) setSelectedTableRow(null);
  }, [filterValues, setSelectedTableRow]);

  useEffect(() => {
    if (!isSidebarFiltersOpen) {
      const posTable = document.getElementById('pos-table');
      posTable?.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [isSidebarFiltersOpen]);

  return (
    <Container>
      <Header
        title="POS"
        icon={isFilterInactive ? tyrioIcons.blackCart : backIcon}
        isSidebarOpen={sidebarOpen}
        isLanding={isFilterInactive}
      />

      {/* FILTER DRAWER - POS TABLE */}
      <SidebarFilters
        filterValues={filterValues}
        setFilterValue={setFilterValue}
        isOpen={isSidebarFiltersOpen}
        setOpen={setIsSidebarFIltersOpen}
        isPos={true}
        data={filtersPos}
      />

      {/* FILTER DRAWER - WHEELS */}
      <WheelsFilterSidebar
        isOpen={isSidebarWheelsFiltersOpen}
        setOpen={setIsSidebarWheelsFiltersOpen}
      />

      {isFilterInactive && activeTab === PosSearchActiveTab.default_search ? (
        <LandingWrapper id="#landing_wrapper">
          <LandingPage
            icon={tyrioIcons.menuIcon3}
            title={'Welcome to POS'}
            subtitle={'Choose product category on right side'}
            cyId={'create-new-pos-landing'}
            buttonIcon={tyrioIcons.menuIcon5}
          />
          <AdWrapper>
            <Ad src={tyrioIcons.largeLandingAd} alt="continental-ad" />
          </AdWrapper>
        </LandingWrapper>
      ) : (
        <TableWrapper
          id="pos-table"
          isDefault={activeTab === PosSearchActiveTab.default_search}
        >
          {activeTab === 0 && (
            <PosTable
              onOpenSidebarFilters={() => setIsSidebarFIltersOpen(true)}
            />
          )}

          {activeTab === PosSearchActiveTab.wheels_search &&
            isFlagEnabled(FEATURE_FLAGS.WHEELS_SEARCH) && (
              <WheelsSearchPage
                onOpenSidebarFilters={() => setIsSidebarWheelsFiltersOpen(true)}
                warehouseId={filterValues?.['warehouseId'] as string}
              />
            )}
        </TableWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const TableWrapper = styled.div<{
  isDefault?: boolean;
}>`
  height: ${({ isDefault }) =>
    isDefault ? `calc(100svh - 110px)` : 'calc(100% - 60px)'};
  overflow-y: ${({ isDefault }) => (isDefault ? 'scroll' : 'unset')};
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;

  @media (max-width: 1630px) {
    height: ${({ isDefault }) => !isDefault && 'calc(100% - 120px)'};
  }
`;

const LandingWrapper = styled.div`
  height: calc(100% - 120px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const AdWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 80%;
  margin-bottom: 20px;
  width: 100%;
`;

const Ad = styled.img`
  width: 100%;
  padding: 0 32px 0 32px;
`;

export default Landing;
