import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AppointmentReq } from '@tyrio/dto';
import { DeleteModal } from '@tyrio/ui-library';
import { QuantityComponent } from '@tyrio/wms-ui-library';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { InputOption } from '../../../../../../../../../src/helpers/enum-parser';
import { SideMenuLayout } from '../../../../../../components/SideMenuLayout/SideMenuLayout';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';
import { customToast } from '../../../../../stock-list/components/Cart/CartToast';
import { MainWrapper } from '../../../../styles/CustomerForm.style';
import { InfoFooter } from '../Footer';
import { useAppointmentsHelper } from './appointment-helper';
import { AppointmentDetails } from './components/AppointmentDetails';
import { BorderedBox } from './components/BorderedBox';
import { IconLabel } from './components/IconLabel';
import { Category, Services } from './components/services-list/Services';
import { useCheckAppointments } from './queries/check-appointments';
import { useGetBrands } from './queries/get-brands';
import { useGetModels } from './queries/get-models';

export const Appointment = () => {
  const {
    appointmentDetails,
    setInput,
    setAppointmentDetails,
    customerDetails,
    setCustomerDetails,
    vehicleDetails,
    setVehicleDetails,
    setActiveInfo,
    setSelectedAppointment,
  } = usePosCartCtx();
  const { brands } = useGetBrands();
  const { models } = useGetModels({ brand: vehicleDetails?.['brand'] });
  const { updateCategoryDuration, getAppointments } = useAppointmentsHelper();
  const [selectedService, setSelectedService] = useState<AppointmentReq | null>(
    null
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { data: checkAppointments, status } = useCheckAppointments();

  const renderAppointment = () => {
    return (
      <MainWrapper>
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              if (selectedService) {
                setIsDeleteModalVisible(false);
                const appointments = appointmentDetails?.[
                  'appointments'
                ] as unknown as Record<string, AppointmentReq>;

                appointments[selectedService.id].appointmentDateFrom =
                  undefined;
                appointments[selectedService.id].appointmentDateTo = undefined;

                setAppointmentDetails({
                  ...appointmentDetails,
                  appointments: appointments as unknown as string,
                });
              }
            }}
            itemName={`appointment for ${selectedService?.name as string}`}
          />
        )}
        <BorderedBox>
          <Grid>
            <HeaderContainer>
              <IconLabel icon={<PersonIcon />} text="Enter customer data" />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      color="info"
                      size="small"
                      checked={
                        appointmentDetails?.['sendSMS'] as unknown as boolean
                      }
                      onChange={(e) => {
                        setAppointmentDetails({
                          ...appointmentDetails,
                          sendSMS: e.target.checked as unknown as string,
                        });
                      }}
                    />
                  }
                  label="Send SMS"
                />
              </FormGroup>
            </HeaderContainer>
            <Grid container spacing={1} sx={{ marginTop: '15px' }}>
              <Grid item xs={6}>
                <PhoneInput
                  specialLabel="Mobile phone"
                  country={'ba'}
                  inputStyle={{
                    width: '100%',
                    height: 56,
                    borderRadius: 8,
                  }}
                  value={customerDetails['mobile_phone']}
                  onChange={(e) => {
                    setCustomerDetails({
                      ...customerDetails,
                      mobile_phone: e,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Vehicle LPN"
                  variant="outlined"
                  value={vehicleDetails['licence_plate']}
                  onChange={(e) => {
                    setVehicleDetails({
                      ...vehicleDetails,
                      licence_plate: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>

            {status === 'success' &&
              checkAppointments &&
              checkAppointments.length > 0 && (
                <Grid sx={{ marginTop: '10px' }}>
                  <Accordion
                    sx={{
                      boxShadow: 0,
                      border: '1px dashed #F19D81',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon color="warning" />}
                    >
                      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                        <WarningIcon color="warning" />
                        <WarningText>
                          This customer already has appointment
                        </WarningText>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AppointmentsWrapper container>
                        {checkAppointments.map((item, index) => (
                          <React.Fragment key={`${item.id}-${index}`}>
                            <AppointmentDetails appointment={item} />
                            {index !== checkAppointments.length - 1 && (
                              <Divider />
                            )}
                          </React.Fragment>
                        ))}
                      </AppointmentsWrapper>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            {(status === 'loading' || status === 'idle') && (
              <LoadingWrapper>
                <CircularProgress size={15} />
                <Typography>Checking customer appointments...</Typography>
              </LoadingWrapper>
            )}
            <Grid container spacing={1} sx={{ marginTop: '5px' }}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    freeSolo
                    options={brands}
                    value={{
                      value: vehicleDetails['brand'],
                      label: _.startCase(_.toLower(vehicleDetails['brand'])),
                    }}
                    onChange={(_e, x) => {
                      const val =
                        x === null ? '' : ((x as InputOption).value as string);
                      setVehicleDetails({
                        ...vehicleDetails,
                        brand: val,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vehicle brand"
                        onChange={(e) => {
                          setVehicleDetails({
                            ...vehicleDetails,
                            brand: e.target.value as string,
                          });
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    freeSolo
                    options={models}
                    value={{
                      value: vehicleDetails['model'],
                      label: _.capitalize(_.toLower(vehicleDetails['model'])),
                    }}
                    onChange={(_e, x) => {
                      const val =
                        x === null ? '' : ((x as InputOption).value as string);
                      setVehicleDetails({
                        ...vehicleDetails,
                        model: val,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vehicle model"
                        onChange={(e) => {
                          setVehicleDetails({
                            ...vehicleDetails,
                            model: e.target.value,
                          });
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ marginTop: '5px' }}>
              <Grid item xs={6}>
                <TextField
                  label="First name"
                  variant="outlined"
                  value={customerDetails['first_name']}
                  onChange={(e) => {
                    setCustomerDetails({
                      ...customerDetails,
                      first_name: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last name"
                  variant="outlined"
                  value={customerDetails['last_name']}
                  onChange={(e) => {
                    setCustomerDetails({
                      ...customerDetails,
                      last_name: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            {appointmentDetails?.['appointments'] && (
              <Grid container sx={{ marginTop: '10px', gap: '10px' }}>
                {Object.values(
                  appointmentDetails?.[
                    'appointments'
                  ] as unknown as AppointmentReq[]
                ).map((item, index) => (
                  <Box sx={{ width: '100%' }} key={`${item.id}-${index}`}>
                    {item.appointmentDateFrom ? (
                      <BorderedBox key={item.id}>
                        <Grid container display="flex" flexDirection="column">
                          <Grid item>
                            <Category
                              isWarning={_warning(item)}
                            >{`Appointment #${index + 1} - ${
                              item.name
                            }`}</Category>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginTop="10px"
                          >
                            <AppointmentItemLabel
                              icon={
                                <DateRangeIcon
                                  color={getColor(item)}
                                  {...iconsProps}
                                />
                              }
                              isWarning={_warning(item)}
                              label={moment(item.appointmentDateFrom).format(
                                'DD.MM.YYYY'
                              )}
                            />
                            <AppointmentItemLabel
                              icon={
                                <AccessTimeIcon
                                  color={getColor(item)}
                                  {...iconsProps}
                                />
                              }
                              isWarning={_warning(item)}
                              label={`${moment(item.appointmentDateFrom).format(
                                'HH:mm'
                              )} - ${moment(item.appointmentDateTo).format(
                                'HH:mm'
                              )}`}
                            />
                            <AppointmentItemLabel
                              icon={
                                <BuildIcon
                                  color={getColor(item)}
                                  {...iconsProps}
                                />
                              }
                              isWarning={_warning(item)}
                              label={item.resource?.meta.subzoneName as string}
                            />
                          </Grid>
                          {_warning(item, 'duration') && (
                            <Grid item marginTop="10px">
                              <Alert severity="warning">
                                The selected time slot is not suitable for added
                                services
                              </Alert>
                            </Grid>
                          )}
                          {_warning(item, 'box-error') && (
                            <Grid item marginTop="10px">
                              <Alert severity="warning">
                                {`${item.resource?.meta.subzoneName} is not suitable for the selected vehicle`}
                              </Alert>
                            </Grid>
                          )}
                          <Grid item marginTop="10px">
                            <TextField
                              label="Remark"
                              variant="outlined"
                              //todo
                              //todo
                              //todo
                              //todo
                              //todo
                              //todo
                              //todo
                              // value={ean}
                              // onChange={(e) => {
                              //   setEan(e.target.value);
                              //   setErrorText('');
                              // }}
                            />
                          </Grid>
                          <Grid item marginTop={2} marginBottom={1}>
                            <Divider />
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Button
                              variant="text"
                              color="error"
                              onClick={() => {
                                setSelectedService(item);
                                setIsDeleteModalVisible(true);
                              }}
                            >
                              DELETE
                            </Button>
                            <Button
                              variant="text"
                              color="info"
                              onClick={() => {
                                setSelectedAppointment(item.id);
                              }}
                            >
                              EDIT
                            </Button>
                          </Grid>
                        </Grid>
                      </BorderedBox>
                    ) : (
                      <BorderedBox key={item.id}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Services
                              category={`Appointment #${index + 1} - ${
                                item.name
                              }`}
                              services={item.services}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Alert severity="info">{`Expected service duration is ${item.duration} minutes`}</Alert>
                          </Grid>
                          <QtyWrapper item xs={5}>
                            <QtyLabel>Service duration (min)</QtyLabel>
                            <QuantityComponent
                              quantity={item.rounded}
                              maxAvailableQuantity={1000}
                              showButton={false}
                              cart={true}
                              qtyIncrement={10}
                              changeCartItemQuantity={(v) => {
                                const { updated } = updateCategoryDuration(
                                  getAppointments(),
                                  item,
                                  v
                                );

                                setAppointmentDetails({
                                  ...appointmentDetails,
                                  appointments: updated as unknown as string,
                                });
                              }}
                            />
                          </QtyWrapper>

                          <Grid
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Button
                              variant="text"
                              color="info"
                              endIcon={<AddCircleOutlineIcon />}
                              onClick={() => {
                                setSelectedAppointment(item.id);
                              }}
                            >
                              ADD APPOINTMENT
                            </Button>
                            {item.appointmentDateFrom}
                          </Grid>
                        </Grid>
                      </BorderedBox>
                    )}
                  </Box>
                ))}
              </Grid>
            )}
          </Grid>
        </BorderedBox>
      </MainWrapper>
    );
  };

  return (
    <SideMenuLayout
      type="posAppointment"
      children={renderAppointment()}
      showSwitch={false}
      switchLabel="Vehicle"
      shouldShowTitle={true}
      footer={
        <InfoFooter
          text1="CANCEL"
          text2="SUBMIT"
          onCancel={() => {
            setActiveInfo('');
          }}
          onSubmit={() => {
            setInput((prevState) => ({
              ...prevState,
              customer: customerDetails,
              vehicle: vehicleDetails,
              appointment: appointmentDetails,
            }));
            customToast('Appointment successfully saved.', 'success');
            setActiveInfo('');
          }}
        />
      }
      viewBox={'0 0 32 32'}
    />
  );
};

export const AppointmentItemLabel = (props: {
  icon: React.ReactNode;
  label: string;
  isWarning: boolean;
  showBadge?: boolean;
  isActive?: boolean;
}) => {
  const { icon, label, isWarning, showBadge, isActive } = props;

  return (
    <Box display="flex" alignItems="center">
      {showBadge && (
        <Badge
          color={isActive ? 'success' : 'error'}
          variant="dot"
          sx={{ marginRight: '5px' }}
        />
      )}
      {icon}
      <AppointmentLabel isWarning={isWarning}>{label}</AppointmentLabel>
    </Box>
  );
};

export const AppointmentLabel = styled.span<{
  isWarning?: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  margin-left: 5px;
  color: ${({ isWarning }) => (isWarning ? '#E75524' : '#212B36')};
`;

export const _warning = (item: AppointmentReq, type?: string) => {
  const errors: Record<string, boolean> = {};

  errors['duration'] =
    item.rounded < item.duration || item.rounded > item.duration;
  errors['box-error'] = !item.resource?.serviceCategories.includes(item.id);

  if (type) return errors[type];

  return _.some(_.values(errors), Boolean);
};

export const iconsProps = {
  sx: { opacity: '0.2' },
};

export const getColor = (item: AppointmentReq) => {
  return _warning(item, 'box-error') ? 'warning' : 'primary';
};

const HeaderContainer = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QtyWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
`;

const QtyLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  padding: 20px 20px;
`;

const AppointmentsWrapper = styled(Grid)`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const WarningText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
`;
