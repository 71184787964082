import styled from '@emotion/styled';
import { DBProduct } from '@prisma/client';
import { DBProductApi, PosItem, PosProductResponse } from '@tyrio/dto';

interface SpecialMarking {
  description: string;
  icon: string;
  id: number;
  key: string;
  longDescription: string;
  name: string;
  shortDescription: string;
}
interface ProductDescriptionProps {
  product: DBProductApi['getOne']['response'];
}

export const generateSpecialMarkings = (product: DBProduct) => {
  const specialMarkingList: SpecialMarking[] = Object.values(
    product?.['productMeta']?.[
      'specialMarking' as keyof DBProduct['productMeta']
    ] || {}
  );

  const specialMarkingKeys = specialMarkingList.map((item) => {
    return item.shortDescription;
  });

  const keys: Record<string, { show: boolean }> = {
    'Run Flat': {
      show: !!product.runFlat,
    },
    'Self Seal': {
      show: !!product.seal,
    },
    '3PMFS': { show: !!product.threepmfs },
    'Ice Grip': { show: !!product.iceGrip },
    'Comm Mark': { show: !!product.commMark },
    Flank: { show: !!product.flank },
    Silent: { show: !!product.silent },
    Electric: { show: !!product.electric },
  };

  Object.keys(keys).forEach((key) => {
    if (keys?.[key]?.show) {
      specialMarkingKeys.push(key);
    }
  });

  return specialMarkingKeys;
};

export const generateSpecialMarkingsMotoTyres = (
  product: PosProductResponse
) => {
  const specialMarkingKeys: string[] = [];
  const keys: Record<
    string,
    { show: boolean; value?: string; notBoolean?: boolean }
  > = {
    Usage: {
      show: !!product?.model?.usage,
      value: product?.model?.usage,
      notBoolean: true,
    },
    Sidewall: {
      show: !!product?.model?.sidewall,
      value: product?.model?.sidewall,
      notBoolean: true,
    },
    Compound: {
      show: !!product?.compound,
      value: product?.compound as string,
      notBoolean: true,
    },
    SpecialMarking: {
      show: !!product?.specialMarking,
      value: product?.specialMarking as string,
      notBoolean: true,
    },
    Oem: { show: !!product?.oem, notBoolean: false },
  };
  Object.keys(keys).forEach((key) => {
    if (keys?.[key]?.show) {
      if (!keys?.[key]?.notBoolean) specialMarkingKeys.push(key);
      else specialMarkingKeys.push(keys?.[key]?.value as string);
    }
  });
  return specialMarkingKeys;
};
const ProductDescription = ({ product }: ProductDescriptionProps) => {
  return (
    <Container>
      <ProductName>{`${product.model?.brand} ${product.productName}`}</ProductName>
      <Markings>
        {generateSpecialMarkings(product).map((item, index) => (
          <span key={index}>{`${item}`}</span>
        ))}
        {product.model?.category_id === 8 && product.model.colorGroup && (
          <span>{product.model.colorGroup}</span>
        )}
        {product.model?.color && <span>{product.model.color}</span>}
      </Markings>
      {product.model?.category_id === 8 && (
        <ProductDetails>
          {` ${product.pcd ? ` PCD: ${product.pcd}` : ''} ${
            product.cb ? `• CB: ${product.cb}` : ''
          } ${product.et ? `• ET: ${product.et}` : ''}`}
        </ProductDetails>
      )}
      <ProductDetails>
        {`${
          product.model?.category
            ? product.model?.category?.parent_category_name
              ? product.model?.category?.parent_category_name +
                ` • ${product.model.category.name}`
              : product.model?.category.name
            : ''
        }
          ${product.model?.season ? `• ${product.model?.season}` : ''} 
          ${product.model?.usage ? `• ${product.model?.usage}` : ''} 
          ${product.vehicleClass ? `• ${product.vehicleClass}` : ''}
          ${product.model?.colorGroup ? `• ${product.model?.colorGroup}` : ''}
          ${product.model?.colorFinish ? `• ${product.model?.colorFinish}` : ''}
          `}
      </ProductDetails>
      <ProductCodes>{`SKU: ${product.sku} EAN: ${product.ean} IPC: ${product.manufacturerCode}`}</ProductCodes>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Markings = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #e75524;
  display: flex;
  gap: 5px;
  // margin-left: -10px;
`;

const ProductDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const ProductCodes = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

const ProductName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #212b36;
`;

export default ProductDescription;
