import styled from '@emotion/styled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { StyledDivider } from '../../../stock-list/components/InboundOrders/InboundOrders.style';
import { PosSearchActiveTab, PosSearchContext } from './SearchContext';
import { usePosCartCtx } from '../../../../context/PosCartContext';
import { useVehicleCtx } from '../../../../context/VehicleContext';

export const Footer = () => {
  const {
    setCategory,
    resetAllValues,
    currentStepIndex,
    setCurrentStepIndex,
    setActiveTab,
  } = useContext(PosSearchContext);

  const { setVehicleDetails, setInput, input, setActiveInfo } = usePosCartCtx();
  const { clearVehicleContext } = useVehicleCtx();
  return (
    <Container>
      <StyledDivider />
      <ButtonWrapper>
        {currentStepIndex > 0 ? (
          <Button
            startIcon={<ArrowBackIcon />}
            color="info"
            onClick={() => {
              if (currentStepIndex <= 0) return;
              if (currentStepIndex === 1) {
                resetAllValues();
                setCategory('');
              }
              setCurrentStepIndex(currentStepIndex - 1);
            }}
          >
            Back
          </Button>
        ) : (
          <div />
        )}

        <StyledButton
          onClick={() => {
            clearVehicleContext();
            resetAllValues();
            setCurrentStepIndex(0);
            setActiveTab(PosSearchActiveTab.default_search);
            setVehicleDetails({});
            setInput({ ...input, vehicle: {} });
            setActiveInfo('');
          }}
          endIcon={<DeleteIcon />}
        >
          Discard
        </StyledButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  padding-top: 0;
`;

const StyledButton = styled(Button)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: #db1e1ecc;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
