/* eslint-disable react/jsx-no-useless-fragment */
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  GridProps,
  InputAdornment,
  InputLabelProps,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DBServiceCategory } from '@prisma/client';
import { TyrioSelectInputOption } from '@tyrio/forms';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { HTMLAttributes, ReactNode } from 'react';
dayjs.extend(customParseFormat);

/**
 * Move this to global form helper
 */

const handlePreventNegativeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  if (!isNaN(Number(value)) && Number(value) < 0) {
    e.target.value = '0';
  }
};

export const getWorkingHoursField = (
  label: string,
  control: Control,
  startTimeKey: string,
  endTimeKey: string,
  gridProps: GridProps
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item {...gridProps} container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name={startTimeKey}
            control={control}
            render={({ field }) => (
              <TimePicker
                label={`${label} Start`}
                value={field.value ? dayjs(field.value, 'HH:mm') : null}
                onChange={(newValue) =>
                  field.onChange(newValue ? newValue.format('HH:mm') : '')
                }
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={endTimeKey}
            control={control}
            render={({ field }) => (
              <TimePicker
                label={`${label} End`}
                value={field.value ? dayjs(field.value, 'HH:mm') : null}
                onChange={(newValue) =>
                  field.onChange(newValue ? newValue.format('HH:mm') : '')
                }
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export const getNumberInputField = (
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  register: UseFormRegister<FieldValues>,
  gridProps: GridProps,
  bgColor?: string,
  helperText?: string
) => {
  return (
    <Grid item {...gridProps}>
      <TextField
        id={key}
        data-cy={key}
        fullWidth
        label={label}
        type="number"
        error={!!errors[key]}
        helperText={errors[key] || helperText}
        required={requiredCheckFn(key)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '&  .MuiFormHelperText-root.Mui-error': {
            backgroundColor: bgColor ? bgColor : 'white',
            margin: bgColor ? 0 : '',
            padding: bgColor ? '4px' : '',
          },
        }}
        {...register(key)}
      />
    </Grid>
  );
};

export const getTextField = (
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  register: UseFormRegister<FieldValues>,
  gridProps: GridProps,
  helperText?: string,
  textFieldProps?: TextFieldProps & { shrink?: boolean },
  inputLabelProps?: InputLabelProps,
  bgColor?: string,
  onChange?: (v: string) => void
) => {
  return (
    <Grid item {...gridProps}>
      <TextField
        id={key}
        data-cy={key}
        fullWidth
        label={label}
        error={!!errors[key]}
        autoComplete="off"
        helperText={errors[key] || helperText}
        InputLabelProps={{
          shrink: textFieldProps?.shrink || textFieldProps?.disabled,
          ...inputLabelProps,
        }}
        sx={{
          '&  .MuiFormHelperText-root.Mui-error': {
            backgroundColor: bgColor ? bgColor : 'white',
            margin: bgColor ? 0 : '',
            padding: bgColor ? '4px' : '',
          },
        }}
        InputProps={{
          inputProps: {
            min: 0,
            onInput: handlePreventNegativeInput,
          },
        }}
        {...(textFieldProps || {})}
        {...register(key)}
        required={requiredCheckFn(key)}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
      />
    </Grid>
  );
};

export const getCheckbox = (
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  control: Control,
  gridProps: GridProps,
  disabled?: boolean
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field: { value, ref, ...field } }) => (
          <>
            <FormControlLabel
              disabled={disabled}
              control={
                <Checkbox
                  {...field}
                  id={key}
                  data-cy={key}
                  color={errors[key] ? 'error' : 'info'}
                  checked={!!value}
                  inputRef={ref}
                  defaultChecked={!!value}
                  required={requiredCheckFn(key)}
                  onChange={(e, val) => {
                    field.onChange(val);
                    field.onBlur();
                  }}
                />
              }
              label={label}
            />
            {errors[key] && (
              <Typography variant="subtitle2" color="error">
                {errors[key]}
              </Typography>
            )}
          </>
        )}
      />
    </Grid>
  );
};

export const getAutocompleteSingleField = (
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  control: Control,
  gridProps: GridProps,
  autocompleteProps: {
    freeSolo?: boolean;
    // eslint-disable-next-line
    getOptionLabel?: (option: any) => string;
  },
  options: TyrioSelectInputOption[],
  disabled?: boolean,
  defaultValue?: string,
  bgColor?: string
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        defaultValue={defaultValue ? defaultValue : ''}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render={({ field }: any) => {
          const fieldValueProps: { defaultValue?: string } = {};
          if (field.value) fieldValueProps.defaultValue = field.value;
          else if (defaultValue) fieldValueProps.defaultValue = defaultValue;

          return (
            <Autocomplete
              {...autocompleteProps}
              fullWidth
              aria-required={requiredCheckFn(key)}
              disabled={disabled}
              disablePortal
              style={{ background: 'white' }}
              value={
                options.find((opt) => opt.value === field.value) ||
                field.value ||
                null
              }
              sx={{
                '&  .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: bgColor ? bgColor : 'white',
                  margin: bgColor ? 0 : '',
                  padding: bgColor ? '4px' : '',
                },
              }}
              options={options}
              onChange={(e, x) => {
                if (typeof x === 'string') field.onChange(x);
                else field.onChange(x?.value);
              }}
              {...fieldValueProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={key}
                  required={requiredCheckFn(key)}
                  label={label}
                  data-cy={key}
                  error={!!errors[key]}
                  helperText={errors[key]}
                  {...(autocompleteProps.freeSolo
                    ? {
                        onChange: (event) => {
                          field.onChange(event.target.value);
                        },
                      }
                    : {})}
                />
              )}
            />
          );
        }}
      />
    </Grid>
  );
};

export const getAutocompleteSelectChip = <
  T extends string | Record<string, string> | DBServiceCategory
>(
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  control: Control,
  gridProps: GridProps,
  options: T[],
  getOptionLabel?: (option: T) => string,
  disabled?: boolean,
  isOptionEqualToValue?: (option: T, value: T) => boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderOption?: (props: HTMLAttributes<HTMLElement>, option: any) => ReactNode
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field }) => {
          const optionLabelParser = getOptionLabel
            ? getOptionLabel
            : (opt: T) => {
                return typeof opt === 'string' ? opt : 'MISSING_OPTION_PARSER';
              };

          return (
            <Autocomplete
              fullWidth
              multiple
              disablePortal
              disableCloseOnSelect
              filterSelectedOptions
              disabled={disabled}
              value={field.value}
              defaultValue={field.value}
              getOptionLabel={optionLabelParser}
              options={options}
              onChange={(e, x) => {
                field.onChange(x);
              }}
              isOptionEqualToValue={isOptionEqualToValue}
              ChipProps={{
                clickable: true,
                style: {
                  background: 'white',
                  color: 'black',
                  border: '1px solid gray',
                },
              }}
              renderOption={renderOption && renderOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={key}
                  data-cy={key}
                  label={label}
                  error={!!errors[key]}
                  helperText={errors[key]}
                  required={requiredCheckFn(key)}
                  disabled={disabled}
                />
              )}
            />
          );
        }}
      />
    </Grid>
  );
};

export const getReactTagInput = <T extends string | Record<string, string>>(
  label: string,
  key: string,
  requiredCheckFn: (key: string) => boolean,
  errors: Record<string, string>,
  control: Control,
  gridProps: GridProps,
  getOptionLabel?: (option: T) => string,
  disabled?: boolean
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              fullWidth
              multiple
              freeSolo
              autoSelect
              disablePortal
              disableCloseOnSelect
              disabled={disabled}
              value={field.value ?? []}
              options={field.value ?? []}
              defaultValue={field.value ?? []}
              onChange={(e, x) => {
                field.onChange(x);
              }}
              // ChipProps={{
              //   clickable: true,
              //   style: {
              //     background: 'white',
              //     color: 'black',
              //     border: '1px solid gray',
              //   },
              // }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={key}
                  data-cy={key}
                  label={label}
                  error={!!errors[key]}
                  helperText={errors[key]}
                  required={requiredCheckFn(key)}
                  disabled={disabled}
                />
              )}
            />
          );
        }}
      />
    </Grid>
  );
};

export const getAutocompleteStandardSelectChip = <
  T extends string | Record<string, string>
>(
  label: string,
  key: string,
  placeholder: string,
  control: Control,
  gridProps: GridProps,
  options: T[],
  getOptionLabel?: (option: T) => string
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field }) => {
          const optionLabelParser = getOptionLabel
            ? getOptionLabel
            : (opt: T) => {
                return typeof opt === 'string' ? opt : 'MISSING_OPTION_PARSER';
              };
          return (
            <Autocomplete
              fullWidth
              multiple
              id="tags-standard"
              options={options}
              getOptionLabel={optionLabelParser}
              disablePortal
              disableCloseOnSelect
              defaultValue={field.value}
              onChange={(e, x) => {
                field.onChange(x);
              }}
              ChipProps={{
                clickable: true,
                style: {
                  background: 'white',
                  color: 'black',
                  border: '1px solid gray',
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  placeholder={placeholder}
                  data-cy={key}
                />
              )}
            />
          );
        }}
      />
    </Grid>
  );
};

export const getSwitchField = (
  label: string,
  key: string,
  errors: Record<string, string>,
  control: Control<FieldValues>,
  gridProps: GridProps,
  switchProps?: {
    color?:
      | 'primary'
      | 'secondary'
      | 'default'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  }
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                {...switchProps}
              />
            }
            label={label}
          />
        )}
      />
      {errors[key] && (
        <Typography variant="caption" color="error" display="block">
          {errors[key]}
        </Typography>
      )}
    </Grid>
  );
};

export const getAutocompleteSelectCheckboxes = <
  T extends string | Record<string, string>
>(
  label: string,
  key: string,
  placeholder: string,
  control: Control,
  gridProps: GridProps,
  options: T[],
  getOptionLabel?: (option: T) => string
) => {
  return (
    <Grid item {...gridProps}>
      <Controller
        name={key}
        control={control}
        render={({ field }) => {
          const optionLabelParser = getOptionLabel
            ? getOptionLabel
            : (opt: T) => {
                return typeof opt === 'string' ? opt : 'MISSING_OPTION_PARSER';
              };
          return (
            <Autocomplete
              fullWidth
              multiple
              id="tags-standard"
              options={options}
              getOptionLabel={optionLabelParser}
              disablePortal
              disableCloseOnSelect
              defaultValue={field.value}
              onChange={(e, x) => {
                field.onChange(x);
              }}
              ChipProps={{
                clickable: true,
                style: {
                  background: 'white',
                  color: 'black',
                  border: '1px solid gray',
                },
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <>
                    <Checkbox
                      style={{
                        marginRight: 8,
                        color: selected ? '#1976d2' : '',
                      }}
                      checked={selected}
                    />
                    {option}
                  </>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  placeholder={placeholder}
                  data-cy={key}
                />
              )}
            />
          );
        }}
      />
    </Grid>
  );
};
