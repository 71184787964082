import { Grid } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { POSContext } from '../../../context/POSContext';
import PosAccordion, {
  PosItemRowBackground,
} from '../components/Table/Accordion';

export interface PosItemConfigProps {
  isAccordion: boolean;
  background: PosItemRowBackground;
  title: string;
  length: number;
  isExpanded: boolean;
  onExpand: () => void;
}

interface ConditionalWrapProps {
  condition: boolean;
  wrap: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

type PosItemKey = 'currentBranchStock' | 'branchesStock' | 'supplierStock';

interface PosTableIemsProps {
  children: JSX.Element;
  posItemKey: PosItemKey;
}

const ConditionalWrap = ({ condition, wrap, children }: ConditionalWrapProps) =>
  condition ? wrap(children) : children;

export const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
  ref.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};

export const useAccordion = () => {
  const {
    isBranchesStockExpanded,
    setIsBranchesStockExpanded,
    isSupplierStockExpanded,
    setIsSupplierStockExpanded,
  } = useContext(POSContext);

  const branchesStockRef = useRef<HTMLDivElement>(null);
  const supplierStockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSupplierStockExpanded) scrollToSection(supplierStockRef);
  }, [isSupplierStockExpanded]);

  useEffect(() => {
    if (isBranchesStockExpanded) scrollToSection(branchesStockRef);
  }, [isBranchesStockExpanded]);

  const config = (att: PosItemKey) => {
    return {
      currentBranchStock: {
        isExpanded: false,
        onExpand: () => {
          console.log('NOT_IMPLEMENTED');
        },
        isAccordion: false,
        background: {
          default: '#fff',
          hover: '#fff',
        },
        title: 'MY STOCK',
      },
      branchesStock: {
        isExpanded: isBranchesStockExpanded,
        onExpand: () => {
          scrollToSection(branchesStockRef);
          setIsBranchesStockExpanded(!isBranchesStockExpanded);
        },
        isAccordion: true,
        background: {
          default: '#1976D2CC',
          hover: '#1976D2',
        },
        title: 'BRANCHES STOCK',
        onclick: () => {
          scrollToSection(branchesStockRef);
        },
      },
      supplierStock: {
        isExpanded: isSupplierStockExpanded,
        onExpand: () => {
          scrollToSection(supplierStockRef);
          setIsSupplierStockExpanded(!isSupplierStockExpanded);
        },
        isAccordion: true,
        background: {
          default: '#DB1E1ECC',
          hover: '#DB1E1E',
        },
        title: 'SUPPLIER STOCK',
        onclick: () => {
          scrollToSection(supplierStockRef);
        },
      },
    }[att];
  };

  const PosTableItems = (props: PosTableIemsProps) => {
    const { children, posItemKey } = props;
    const posItemConf = config(posItemKey);

    const stockRef =
      posItemKey === 'branchesStock'
        ? branchesStockRef
        : posItemKey === 'supplierStock'
        ? supplierStockRef
        : undefined;

    return (
      <ConditionalWrap
        condition={posItemConf.isAccordion}
        wrap={(wrappedChildren) => (
          <Grid sx={{ width: '100%', scrollMarginTop: '60px' }} ref={stockRef}>
            <PosAccordion {...posItemConf} key={posItemKey}>
              {wrappedChildren}
            </PosAccordion>
          </Grid>
        )}
      >
        {children}
      </ConditionalWrap>
    );
  };

  return { PosTableItems };
};
