import api from '@tyrio/api-factory';
import {
  ConnectionDetail,
  DBEdiOrderApi,
  DBEdiOrderInquiryBody,
  DBSupplierClientSettingsPayload,
  EDIInquiryItemRequest,
  HttpsConnectionDetail,
} from '@tyrio/dto';
import * as _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClientSuppliersQuery } from './useClientSuppliersQuery';

export type InquiryState = Record<
  string,
  Record<
    string,
    {
      ean: string;
      deliveryDate: string;
      qty: number;
      minDeliveryDate: string | null;
      maxDeliveryDate: string | null;
    }
  >
>;
export type UseInquireMultipleEansState = {
  inquiryState: InquiryState;
  inquiryLoadingState: Record<string, boolean>;
  counter: number;
  apiErrorState: Record<string, boolean>;
};

const today = () => {
  // Get today's date
  const t = new Date();

  // return yyyy-mm-dd format
  return t.toISOString().split('T')[0];
};
const getOrderConnection = (
  clientSupplierSettings: DBSupplierClientSettingsPayload
) => {
  const connections =
    clientSupplierSettings.connections as unknown as ConnectionDetail[];
  if (!connections) return null;

  return (
    (connections.find(
      (c) => c.connectionType.includes('ORDER') && c.type !== 'EMAIL'
    ) as HttpsConnectionDetail) ?? null
  );
};

export const useInquireEansMultipleSuppliers = ({
  supplierIds,
  items,
}: {
  supplierIds: string[];
  items: EDIInquiryItemRequest[];
}): UseInquireMultipleEansState => {
  /**
   * supplierId => ean
   */

  const [counter, setCounter] = useState(0);
  const [inquiryState, setInquiryState] = useState<InquiryState>({});
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const [apiErrorState, setApiErrorState] = useState<Record<string, boolean>>(
    {}
  );

  const supplierSettings = useClientSuppliersQuery(
    supplierIds,
    !_.isEmpty(items)
  );

  const inquiryPreparationPayloads = useMemo(() => {
    const payloads: DBEdiOrderInquiryBody[] = [];

    if (_.isEmpty(items)) return payloads;

    supplierSettings?.data?.data?.forEach((supplierClientConfig) => {
      const connection = getOrderConnection(supplierClientConfig);
      if (!connection) return;
      let branchId: number | null =
        Number(
          Object.keys(supplierClientConfig?.branchEdiConfiguration ?? {})?.[0]
        ) ?? null;
      if (isNaN(branchId)) branchId = null;
      if (!branchId) return;

      const supplierId = supplierClientConfig.supplierId;
      const preferredDeliveryDate = today();
      const transportPriority: 'RUSH' | 'REPL' = 'RUSH';

      const payload = {
        adminConnectionId: connection.adminConnectionId,
        branchId,
        supplierClientSettingsId: supplierClientConfig.id,
        connection,
        supplierId,
        items,
        preferredDeliveryDate,
        transportPriority,
      };
      payloads.push(payload);
    });

    return payloads;
  }, [items, supplierSettings?.data?.data]);

  const inquireSupplier = useCallback(
    async (payload: DBEdiOrderInquiryBody) => {
      // setLoadingState((old) => _.merge(old, { [payload.supplierId]: true }));
      setLoadingState((old) => ({ ...old, [payload.supplierId]: true }));
      setCounter((old) => old + 1);
      await api
        .fetch<DBEdiOrderApi['inquire']>('inquire_eans', payload)
        .then((res) => {
          setInquiryState((old) => {
            return _.merge(old, { [payload.supplierId]: res });
          });
          // setLoadingState((old) =>
          //   _.merge(old, { [payload.supplierId]: false })
          // );

          setLoadingState((old) => ({ ...old, [payload.supplierId]: false }));

          setCounter((old) => old + 1);

          return res;
        })
        .catch(() => {
          setApiErrorState((old) => ({
            ...old,
            [payload.supplierId]: true,
          }));

          // setLoadingState((old) =>
          //   _.merge(old, { [payload.supplierId]: false })
          // );
          setLoadingState((old) => ({ ...old, [payload.supplierId]: false }));
          return;
        });

      // setInquiryState((old) => {
      //   return _.merge(old, { [payload.supplierId]: response });
      // });
      // setLoadingState((old) => _.merge(old, { [payload.supplierId]: false }));
      // setCounter((old) => old + 1);
      // return response;
    },
    []
  );

  useEffect(() => {
    inquiryPreparationPayloads.forEach((p) => {
      inquireSupplier(p).catch(() => {
        setCounter((old) => old + 1);
        // setLoadingState((old) => _.merge(old, { [p.supplierId]: false }));
        setLoadingState((old) => ({ ...old, [p.supplierId]: false }));
      });
    });
  }, [inquireSupplier, inquiryPreparationPayloads, inquiryState]);

  return {
    inquiryState,
    inquiryLoadingState: loadingState,
    counter,
    apiErrorState,
  };
};
