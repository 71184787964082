import { tyrioIcons } from '@tyrio/ui-library';
import { UnselectedRowWarning } from '@tyrio/wms-ui-library';
import { isEmpty, isUndefined } from 'lodash';
import { useContext, useState } from 'react';
import { SideMenuLayout } from '../../../components/SideMenuLayout/SideMenuLayout';
import { AddItemsFooter } from '../components/AddItemsFooter';
import { StockItems } from '../components/StockItemDetails/StockItems';
import { SuppliersHeader } from '../components/Suppliers/SuppliersHeader';
import { LoaderWrapper } from '../../../components/PageTemplate/PageTemplate';
import { CircularProgress } from '@mui/material';
import { usePosCartCtx } from '../../../context/PosCartContext';
import { POSDetailsContext } from '../../../context/POSDetails';

export const Suppliers = () => {
  const [sortingValue, setSortingValue] = useState('');

  const { isFetchingSupplierStock, suppliersStockItems } =
    useContext(POSDetailsContext);

  console.log('suppliers', suppliersStockItems);
  const { cartData } = usePosCartCtx();
  const renderSuppliers = () => {
    if (isFetchingSupplierStock)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    if (!isUndefined(suppliersStockItems) && !isEmpty(suppliersStockItems))
      return (
        <div>
          <SuppliersHeader
            sortingValue={sortingValue}
            setSortingValue={setSortingValue}
          />
          {suppliersStockItems.map((d, index) => (
            <StockItems
              data={d}
              stockType={'SUPPLIERS STOCK'}
              sale={false}
              key={index}
              index={index + 1}
            />
          ))}
        </div>
      );
    else if (isEmpty(suppliersStockItems))
      return (
        <UnselectedRowWarning
          icon={tyrioIcons.stepMenuSuppliers}
          emptyData="No available items for this product"
        />
      );
    else
      return (
        <UnselectedRowWarning
          icon={tyrioIcons.stepMenuSuppliers}
          text="suppliers stock list"
        />
      );
  };

  return (
    <SideMenuLayout
      type="suppliers"
      children={renderSuppliers()}
      showSwitch={false}
      checked={false}
      footer={
        !isUndefined(suppliersStockItems) &&
        !isEmpty(suppliersStockItems) &&
        cartData.length > 0 && <AddItemsFooter />
      }
    />
  );
};
