import { defaultEnv } from './default.api';
import { APIConfig } from './types';

export const environment: APIConfig = {
  ...defaultEnv,
  production: true,

  awsS3: {
    bucket: 'p-tyrio-storage-cb63451',
    key: 'AKIA3ZYSEBGHZC2QVDUJ',
    secret: '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',
  },
  redis: {
    url: 'redis://default:22eAYoVdWX19tQMuEiiSN4m0JEFs21q8@redis-14749.c278.us-east-1-4.ec2.redns.redis-cloud.com:14749',
    host: 'redis-14749.c278.us-east-1-4.ec2.redns.redis-cloud.com',
    port: 14749,
    username: 'default',
    password: '22eAYoVdWX19tQMuEiiSN4m0JEFs21q8',
  },
  rabbitmq: {
    queueName: 'p-importer-2',
    connectionUrl:
      'amqps://uyubflnm:kXB4rKyBYVKfzoCBh0Z6VA96s5QdFuTt@chimpanzee.rmq.cloudamqp.com/uyubflnm',
    // connectionUrl: 'amqp://api:t1r1op4sword@localhost',
    // config: {
    //   connectionOptions: {
    //     timeout: 30000,
    //     credentials: {
    //       mechanism: 'plain',
    //       username: 'ccaaepyi',
    //       password: 'lxIhQUW4EftFV1-Aj7j8u5wPQXcQ13iz',
    //       response: () => new Buffer(''),
    //     },
    //   },
    // },
  },
  // rabbitmq: {
  //   queueName: 'p-importer',
  //   connectionUrl: 'amqp://api:t1r1op4sword@localhost',
  //   config: {
  //     connectionOptions: {
  //       timeout: 30000,
  //       credentials: {
  //         mechanism: 'plain',
  //         username: 'ccaaepyi',
  //         password: 'lxIhQUW4EftFV1-Aj7j8u5wPQXcQ13iz',
  //         response: () => new Buffer(''),
  //       },
  //     },
  //   },
  // },
};
