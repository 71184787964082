import envName from '../env';
import { environment as a } from './a.app';
import { environment as ci } from './ci.app';
import { environment as d } from './d.app';
import { defaultEnv as defaultConfig } from './default.app';
import { environment as p } from './p.app';
import { environment as t } from './t.app';

const envMap = {
  a,
  ci,
  d,
  defaultConfig,
  p,
  t,
};

type EnvName = 'a' | 'd' | 't' | 'ci' | 'p' | undefined;

const processEnv = envName || (process.env['NX_ENV_NAME'] as EnvName);

const env = processEnv || 'a';

interface AppConfig {
  production: boolean;
  apiUrl: string;
  appUrl: string;
  wsUrl: string;
  tinyMCEApiKey: string;
  mapboxApiKey: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    senderId: string;
    appId: string;
    measurementId: string;
  };
  mapBox: string;
}

interface VersionedAppConfig extends AppConfig {
  env: EnvName;
  version: string;
  versionString: string;
}

const config: VersionedAppConfig = {
  version: '0.1.94',
  versionString: 'TYRIO v0.1.94',
  env,
  ...defaultConfig,
  ...envMap[env],
};

export { AppConfig, VersionedAppConfig, config as appConfig };
