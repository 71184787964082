import api from '@tyrio/api-factory';
import { PosApi } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetPosFilters = (filterValues: qs.ParsedQs, shouldRefetch?: boolean) => {
  const [filtersPos, setFiltersPos] = useState<
    PosApi['getFilters']['response']
  >({});

  const { isLoading, refetch } = useQuery(
    ['get_search_filters'],
    () => api.fetch<PosApi['getFilters']>('get_search_filters', filterValues),
    {
      onSuccess: (res) => {
        setFiltersPos(res);
      },
      enabled: shouldRefetch
    }
  );

  return {
    filtersPos,
    setFiltersPos,
    isLoading,
    refetch,
  };
};
