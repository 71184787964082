import {
  DBConnectionProtocol,
  DBConnectionType,
  DBCurrency,
  DBFileFormat,
  DBRepeatUnit,
  DBSupplierApiConnectionType,
  DBSupplierLocationType,
  DBSupplierType,
} from '@prisma/client';
import {
  DBSupplierApi,
  DBSupplierApiConnectionApi,
  DBSupplierConnectionApi,
  DBSupplierContactApi,
  DBSupplierFtpConnectionApi,
  DBSupplierHttpsConnectionApi,
  DBSupplierLocationApi,
} from '@tyrio/dto';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import { DropdownDataProps, ExpandDataProps } from './types';

export const supplierContactDefaultProps: DBSupplierContactApi['create']['requestBody'] =
  {
    firstName: '',
    lastName: '',
    email: '',
    businessPhone: '',
    mobilePhone: '',
    remark: '',
  };

export const supplierLocationsDefaultProps: DBSupplierLocationApi['create']['requestBody'] =
  {
    locationId: '',
    supplierLocationType: DBSupplierLocationType.CENTRAL_WAREHOUSE,
    locationName: '',
    slug: '',
    countryId: '',
    zipCode: '',
    city: '',
    adress: '',
    isHeadQuarter: true,
  };

export const supplierConnectionsDefaultProps: DBSupplierConnectionApi['create']['requestBody'] =
  {
    connectionName: '',
    connectionTypes: [],
    active: true,
    connectionProtocol: '',
    fileFormat: '',
    isFileZipped: false,
    remark: '',
    rulesetKey: null,
    ftpConnection: undefined,
    apiConnection: undefined,
    httpsConnection: undefined,
    minimumUpdateFrequencyUnit: DBRepeatUnit.HOURS,
    minimumUpdateFrequencyTime: 1,
  };

export const supplierFtpConnectionDefaultProps: DBSupplierFtpConnectionApi['create']['requestBody'] =
  {
    supplierFileName: '',
    fileLocation: '',
    ftpHost: '',
    ftpPort: '',
    ftpUserName: '',
    ftpPassword: '',
    startUpdateTime: new Date(),
    repeatEvery: undefined,
    repeatUntil: DBRepeatUnit.HOURS,
    remark: '',
  };

export const supplierApiConnectionDefaultProps: DBSupplierApiConnectionApi['create']['requestBody'] =
  {
    apiUrl: '',
    apiKey: '',
    apiClientId: '',
    apiClientSecret: '',
    startUpdateTime: new Date(),
    repeatEvery: undefined,
    repeatUntil: DBRepeatUnit.HOURS,
    remark: '',
    connectionType: DBSupplierApiConnectionType.GMP_ITALIA,
  };

export const supplierHttpsConnectionDefaultProps: DBSupplierHttpsConnectionApi['create']['requestBody'] =
  {
    httpsUrl: '',
    username: '',
    password: '',
    customerId: '',
    startUpdateTime: new Date(),
    repeatEvery: undefined,
    repeatUntil: DBRepeatUnit.HOURS,
    remark: '',
  };

export const emailConnectionDefaultProps = {
  supplierEmail: '',
  remark: '',
};

export const formDataDefaultProps: DBSupplierApi['create']['requestBody'] = {
  isActive: true,
  companyLogoId: null,
  supplierType: '',
  vatNumber: '',
  viesStatus: false,
  companyOfficialName: '',
  companyShortName: '',
  slug: '',
  countryId: '',
  zipCode: '',
  city: '',
  address: '',
  remark: '',
  supplierContacts: [],
  supplierLocations: [{ ...supplierLocationsDefaultProps }],
  supplierConnections: [],
};

export const dropdownDataDefaultProps: DropdownDataProps = {
  supplierTypes: parseEnumToArray(DBSupplierType),
  countries: [],
  fileFormats: parseEnumToArray(DBFileFormat),
  connectionTypes: parseEnumToArray(DBConnectionType),
  connectionProtocols: parseEnumToArray(DBConnectionProtocol),
  timeUnits: parseEnumToArray(DBRepeatUnit),
  apiConnectionTypes: parseEnumToArray(DBSupplierApiConnectionType),
};

export const expandedDataDefaultProps: ExpandDataProps = {
  contacts: [],
  locations: [],
  connections: [],
  catalogueSettings: [],
};

export function getDefaultObject(
  key:
    | keyof DBSupplierApi['create']['requestBody']
    | keyof DBSupplierConnectionApi['create']['requestBody']
) {
  if (key === 'supplierContacts') return supplierContactDefaultProps;
  if (key === 'supplierLocations') return supplierLocationsDefaultProps;
  if (key === 'supplierConnections') return supplierConnectionsDefaultProps;
  if (key === 'ftpConnection') return supplierFtpConnectionDefaultProps;
  if (key === 'apiConnection') return supplierApiConnectionDefaultProps;
  if (key === 'httpsConnection') return supplierHttpsConnectionDefaultProps;

  if (key === 'emailConnection') return emailConnectionDefaultProps;
  return {};
}

export function getClientCurrency(value: DBCurrency) {
  if (value === DBCurrency.BAM) return 'BAM';
  if (value === DBCurrency.RSD) return 'DIN';
  if (value === DBCurrency.USD) return '$';
  return '€';
}

export function getSupplierData(
  supplier_data: DBSupplierApi['getOne']['response']
) {
  return {
    id: supplier_data.id,
    companyLogoId: supplier_data.companyLogoId,
    isActive: supplier_data.active,
    supplierType: supplier_data.supplierType,
    vatNumber: supplier_data.vatNumber,
    viesStatus: supplier_data.vies,
    companyOfficialName: supplier_data.companyOfficialName,
    companyShortName: supplier_data.companyShortName,
    slug: supplier_data.slug,
    countryId: supplier_data.countryId ?? '',
    city: supplier_data.city,
    address: supplier_data.address,
    remark: supplier_data.remark,
    zipCode: supplier_data.zipCode,
    supplierContacts: supplier_data.supplierContacts.map((item) => ({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      businessPhone: item.businessPhone,
      mobilePhone: item.mobilePhone,
      remark: item.remark,
    })),
    supplierLocations: supplier_data.supplierLocations.map((item) => ({
      id: item.id,
      locationId: item.locationId,
      locationName: item.locationName,
      slug: item.slug,
      countryId: item.countryId ?? '',
      zipCode: item.zipCode,
      city: item.city,
      adress: item.adress,
      isHeadQuarter: item.isHeadQuarter,
      supplierLocationType: item.supplierLocationType,
    })),
    supplierConnections: supplier_data.supplierConnections.map((item) => ({
      id: item.id,
      connectionName: item.connectionName,
      connectionTypes: item.connectionTypes,
      fileFormat: item.fileFormat,
      isFileZipped: item.isFileZipped,
      active: item.active,
      connectionProtocol: item.connectionProtocol,
      rulesetKey: item.rulesetKey,
      remark: item.remark,
      minimumUpdateFrequencyUnit: item.minimumUpdateFrequencyUnit ?? undefined,
      minimumUpdateFrequencyTime: item.minimumUpdateFrequencyTime ?? undefined,
      ftpConnection: item.ftpConnection
        ? {
            id: item.ftpConnection?.id,
            supplierFileName: item.ftpConnection?.supplierFileName,
            fileLocation: item.ftpConnection?.fileLocation,
            ftpHost: item.ftpConnection?.ftpHost,
            ftpPort: item.ftpConnection?.ftpPort,
            ftpUserName: item.ftpConnection?.ftpUserName,
            ftpPassword: item.ftpConnection?.ftpPassword,
            startUpdateTime: item.ftpConnection?.startUpdateTime,
            repeatEvery: item.ftpConnection?.repeatEvery ?? undefined,
            repeatUntil: item.ftpConnection?.repeatUntil,
            supplierConnectionId: item.ftpConnection?.supplierConnectionId,
            extraEntries:
              (item.ftpConnection?.extraEntries as unknown as string[][]) ?? [],
            remark: item.ftpConnection?.remark,
          }
        : undefined,
      apiConnection: item.apiConnection
        ? {
            id: item.apiConnection?.id,
            apiUrl: item.apiConnection?.apiUrl,
            apiKey: item.apiConnection?.apiKey,
            apiClientId: item.apiConnection?.apiClientId,
            apiClientSecret: item.apiConnection?.apiClientSecret,
            startUpdateTime: item.apiConnection?.startUpdateTime,
            repeatEvery: item.apiConnection?.repeatEvery ?? undefined,
            repeatUntil: item.apiConnection?.repeatUntil,
            supplierConnectionId: item.apiConnection?.supplierConnectionId,
            remark: item.apiConnection?.apiUrl,
            connectionType: item.apiConnection.connectionType,
          }
        : undefined,
      httpsConnection: item.httpsConnection
        ? {
            id: item.httpsConnection?.id,
            httpsUrl: item.httpsConnection?.httpsUrl,
            username: item.httpsConnection?.username,
            password: item.httpsConnection?.password,
            customerId: item.httpsConnection?.customerId ?? undefined,
            startUpdateTime: item.httpsConnection?.startUpdateTime,
            repeatEvery: item.httpsConnection?.repeatEvery ?? undefined,
            repeatUntil: item.httpsConnection?.repeatUntil,
            supplierConnectionId: item.httpsConnection?.supplierConnectionId,
            remark: item.httpsConnection?.remark,
          }
        : undefined,
      emailConnection: item.emailConnection
        ? {
            id: item.emailConnection?.id,
            remark: item.emailConnection?.remark,
            supplierEmail: item.emailConnection?.supplierEmail,
          }
        : undefined,
    })),
  };
}
