import { Header } from './lib/components/Header';
import Search from './lib/components/Search';
import Filter from './lib/components/Filter';
import ProductSection from './lib/components/ProductSidebar/ProductSection';
import ProductHeader from './lib/components/ProductSidebar/ProductHeader';
import ProductReviews from './lib/components/ProductSidebar/ProductReviews';
import ProductTests from './lib/components/ProductSidebar/ProductTests';
import ShortProductDescription from './lib/components/ProductSidebar/ProductDescription';
import ProductItems from './lib/components/ProductItems';
import ProductImages from './lib/components/ProductImages/ProductImages';
import FilterSidebar from './lib/components/Filter/FilterSidebar';
import ProductDetailsGenerator from './lib/components/ProductDetails/ProductDetailsGenerator';
import ProductEprelGenerator from './lib/components/ProductDetails/ProductEprelGenerator';
import EprelToPrint from './lib/components/ProductDetails/EprelToPrint';
import ProductDescription, {
  generateSpecialMarkings, generateSpecialMarkingsMotoTyres
} from './lib/components/ProductDetails/ProductComponents/ProductDescription';
import PerformanceCategory, {
  CategoryLevels,
  CategoryLevelsOld,
} from './lib/components/ProductDetails/ProductComponents/PerformanceCategory';

import NoisePerformance from './lib/components/ProductDetails/ProductComponents/NoisePerformance';
import { ProductSidebarGlobal } from './lib/components/globals/product-sidebar/ProductSidebarGlobal';
import { ImageGalleryGlobal } from './lib/components/globals/image-gallery/ImageGallery';
import SeasonIcon from './lib/components/ProductDetails/ProductComponents/SeasonIcon';

export * from './lib/products';
export {
  Header,
  Search,
  Filter,
  ProductSection,
  ProductHeader,
  ProductReviews,
  ProductTests,
  ShortProductDescription,
  ProductItems,
  ProductImages,
  FilterSidebar,
  ProductDetailsGenerator,
  ProductEprelGenerator,
  generateSpecialMarkings,
 generateSpecialMarkingsMotoTyres,
  EprelToPrint,
  PerformanceCategory,
  NoisePerformance,
  CategoryLevels,
  CategoryLevelsOld,
  ProductSidebarGlobal,
  ImageGalleryGlobal,
  SeasonIcon,
  ProductDescription,
};
