import styled from '@emotion/styled';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { tyrioUI } from '@tyrio/ui-library';

export const InputPhone = ({
  value,
  handleChange,
  isValid,
  disabled = false,
  defaultCode = 'hr',
  required = false,
}: {
  value: string;
  handleChange: (value: string, country: CountryData) => void;
  isValid: boolean;
  disabled?: boolean;
  defaultCode?: string;
  required?: boolean;
}) => {
  return (
    <PhoneInputStyled
      isValid={isValid}
      specialLabel={required ? 'Mobile phone*' : 'Mobile phone'}
      value={value}
      defaultErrorMessage="Number must be between 12 and 13 digits!"
      onChange={(value, country) => {
        handleChange(value, country as CountryData);
      }}
      disabled={disabled}
      country={defaultCode.toLocaleLowerCase()}
    />
  );
};

const PhoneInputStyled = styled(PhoneInput)<{ isValid?: boolean }>`
  &.react-tel-input .form-control {
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    border: ${(props) => (props.isValid ? '' : '1px solid #d32f2f')};
    color: ${(props) =>
      props.isValid
        ? `${tyrioUI.colors.primary}`
        : `${tyrioUI.colors.secondary}`};
  }
  ,
  &.react-tel-input .special-label {
    font-family: Barlow;
    font-weight: 500;
    font-size: 12px;
    color: ${(props) =>
      props.isValid ? 'rgba(0, 0, 0, 0.6)' : `${tyrioUI.colors.secondary}`};
  }
`;
