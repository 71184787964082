/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBClient } from '@prisma/client';
import { DataShape, EDICreateOrderBody } from '@tyrio/dto';

export const getEdiWheelData = (
  data: DataShape,
  client: DBClient | null | undefined
): EDICreateOrderBody => {
  let subtotal = 0;
  let ecoTax = 0;
  let delivery = 0;
  const lineItemsMeta = {} as Record<
    string,
    { ordered: number; confirmed: number; received: number; backorder: number }
  >;
  const lineItems = data.lineItems.map((item: Record<string, any>) => {
    lineItemsMeta[item['uid']] = {
      ordered: item['quantity'],
      confirmed: 0,
      backorder: 0,
      received: 0,
    };
    subtotal += item['price'];
    ecoTax += item['ecoTax'];
    delivery += item['shipping'];

    return {
      uid: item['uid'],
      productName: item['productName'],
      ean: item['ean'],
      ipc: item['ipc'],
      sku: item['sku'],
      price: item['price'],
      suggestedRetailPrice: item['suggestedRetailPrice'],
      qty: item['quantity'],
      total: item['price'] * item['quantity'],
      productYear: item['productYear'] || new Date().getFullYear(),
      orderTimestamp: new Date(),
      invoicePrice: item['invoicePrice'] ?? 0,
    };
  });

  const total = subtotal + ecoTax + delivery;

  return {
    customerOrderId: data.customerOrderId,
    reference: data.reference,
    supplierId: data.supplierId,
    supplierClientSettingsId: data.supplierClientSettingsId,
    alias: data.supplierRemark ?? '',
    notes: data.remark ?? '',
    items: data.lineItems.map((item: { ean: string; quantity: number }) => ({
      code: item.ean,
      quantity: item.quantity,
    })),
    connection: data.connection,
    rulesetKey: data.rulesetKey,
    lineItems: lineItems,
    transportPriority: data.transportPriority,
    preferredDeliveryDate: data.preferredDeliveryDate,
    orderNumberPrefix: data.orderNumberPrefix,
    orderNumberSufix: data.orderNumberSufix,
    deliveryAddress: data.deliveryAddress,
    supplierRemark: data.supplierRemark,
    remark: data.remark,
    branchId: data.branchId,
    lineItemsMeta: lineItemsMeta,
    subtotal,
    ecoTax,
    delivery,
    total,
    dropShipping: data.dropShipping ?? false,
    codAmount: data.codAmount ?? 0,
  };
};
