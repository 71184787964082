import {
  DBStockInItemStatus,
  DBStockInItemType,
  DBStockInItems,
  Prisma,
} from '@prisma/client';

export const stockIn: DBStockInItems[] = [
  {
    id: '00000000-0000-1000-ad00-000000000001',
    clientId: '00000000-0000-1000-b000-0000000000022',
    status: DBStockInItemStatus.CONFIRMED,
    documentId: '1',
    documentMeta: {} as unknown as Prisma.JsonValue,
    createdAt: new Date(),
    orderType: DBStockInItemType.TRANSFER_ORDER,
    receivedFromMeta: {
      branchId: 2,
      branchName: 'Poslovnica Split',
      city: 'Split',
      zipCode: '21000',
      address: 'Ferhadija 1',
    } as unknown as Prisma.JsonValue,
    orderMeta: {
      orderId: 'c9ce9158-830b-4945-be50-16ca66c65b81',
      orderNumber: 1,
      supplierId: null,
      supplierName: '',
    } as unknown as Prisma.JsonValue,
    destinationMeta: {
      branchId: 3,
      branchName: 'Poslovnica Kastela',
      city: 'Split',
      zipCode: '21000',
      address: 'Kaštel Stari',
    },
    lineItems: [
      {
        ean: '4019238036954',
        sku: 'S2354018CON',
        uid: 'a84a73c6-78b9-4712-aa48-c90b53e22e0a',
        quantity: 6,
        productName: '235/40 R 18 95Y SportContact 6 XL SSR FR ',
        productionYear: '2023',
        connectedToItem: '69b0e95c-22b4-4fc9-bf62-3c6af749f987',
        manufacturerCode: '03112090000',
        damaged: false,
        ordered: 3,
        backordered: 0,
        received: 3,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035552',
        sku: 'S2254517CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035551',
        sku: 'S2254547CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035551',
        sku: 'S2254537CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035151',
        sku: 'S2254217CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035521',
        sku: 'S2254117CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238031551',
        sku: 'S2254519CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'CONTINENTAL',
      },
    ] as Prisma.JsonArray,
    priceMeta: {} as Prisma.JsonValue,
    quantity: 21,
    remark: 'Order contains damaged item!',
    reference: 'B2B-000001',
    referenceMeta: {} as unknown as Prisma.JsonValue,
    isLocked: false,
    favoritedBy: [],
    locationInfo: {},
    stockInProductsReq: {},
    createdBy: {},
    customerDetails: {},
    dropShipping: false,
  },
  {
    id: '00000000-0000-1000-ad00-000000000002',
    clientId: '00000000-0000-1000-b000-0000000000022',
    status: DBStockInItemStatus.CONFIRMED,
    documentId: '2',
    documentMeta: {} as unknown as Prisma.JsonValue,
    createdAt: new Date(),
    orderType: DBStockInItemType.TRANSFER_ORDER,
    receivedFromMeta: {
      branchId: 2,
      branchName: 'Poslovnica Split',
      city: 'Split',
      zipCode: '21000',
      address: 'Ferhadija 1',
    } as unknown as Prisma.JsonValue,
    orderMeta: {
      orderId: 'c9ce9158-830b-4945-be50-16ca66c65b81',
      orderNumber: 2,
      supplierId: null,
      supplierName: '',
    } as unknown as Prisma.JsonValue,
    destinationMeta: {
      branchId: 3,
      branchName: 'Poslovnica Kastela',
      city: 'Split',
      zipCode: '21000',
      address: 'Kaštel Stari',
    },
    lineItems: [
      {
        ean: '4019238025551',
        sku: 'S2254517CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: false,
        ordered: 17,
        backordered: 13,
        received: 4,
        brand: 'CONTINENTAL',
      },
      {
        ean: '4019238035521',
        sku: 'S2254117CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 18,
        backordered: 2,
        received: 16,
        brand: 'VANDENBAN',
      },
    ] as Prisma.JsonArray,
    priceMeta: {} as Prisma.JsonValue,
    quantity: 17,
    remark: 'Order contains damaged item!',
    reference: 'FOR STOCK',
    referenceMeta: {} as unknown as Prisma.JsonValue,
    isLocked: false,
    favoritedBy: [],
    locationInfo: {},
    stockInProductsReq: {},
    createdBy: {},
    customerDetails: {},
    dropShipping: false,
  },
  {
    id: '00000000-0000-1000-ad00-000000000003',
    clientId: '00000000-0000-1000-b000-0000000000022',
    status: DBStockInItemStatus.UNCONFIRMED,
    documentId: '3',
    documentMeta: {} as unknown as Prisma.JsonValue,
    createdAt: new Date(),
    orderType: DBStockInItemType.PURCHASE_ORDER,
    receivedFromMeta: {
      branchId: 3,
      branchName: 'Poslovnica Kastela',
      city: 'Split',
      zipCode: '21000',
      address: 'Kaštel Stari',
    } as unknown as Prisma.JsonValue,
    orderMeta: {
      orderId: 'c9ce9158-830b-4945-be50-16ca66c65b81',
      orderNumber: 3,
      supplierId: '33b2ab3a-f07f-426d-a637-da5647ddfc5e',
      supplierName: 'Continental',
    } as unknown as Prisma.JsonValue,
    destinationMeta: {
      branchId: 2,
      branchName: 'Poslovnica Split',
      city: 'Split',
      zipCode: '21000',
      address: 'Ferhadija 1',
    } as unknown as Prisma.JsonValue,
    lineItems: [
      {
        ean: '4019238035551',
        sku: 'S2254517CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: false,
        ordered: 15,
        backordered: 13,
        received: 2,
        brand: 'CONTINENTAL',
      },
    ] as Prisma.JsonArray,
    priceMeta: {} as Prisma.JsonValue,
    quantity: 15,
    remark: '',
    reference: 'B2C-000003',
    referenceMeta: {} as unknown as Prisma.JsonValue,
    isLocked: false,
    favoritedBy: [],
    locationInfo: {},
    stockInProductsReq: {},
    createdBy: {},
    customerDetails: {},
    dropShipping: false,
  },
  {
    id: '00000000-0000-1000-ad00-000000000004',
    clientId: '00000000-0000-1000-b000-0000000000022',
    status: DBStockInItemStatus.COMPLETED,
    documentId: '4',
    documentMeta: {} as unknown as Prisma.JsonValue,
    createdAt: new Date(),
    orderType: DBStockInItemType.PURCHASE_ORDER,
    receivedFromMeta: {
      branchId: 3,
      branchName: 'Poslovnica Kastela',
      city: 'Split',
      zipCode: '21000',
      address: 'Kaštel Stari',
    } as unknown as Prisma.JsonValue,
    orderMeta: {} as unknown as Prisma.JsonValue,
    destinationMeta: {
      branchId: 2,
      branchName: 'Poslovnica Split',
      city: 'Split',
      zipCode: '21000',
      address: 'Ferhadija 1',
    } as unknown as Prisma.JsonValue,
    lineItems: [
      {
        ean: '4019238035551',
        sku: 'S2254517CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: true,
        ordered: 17,
        backordered: 13,
        received: 4,
        brand: 'GMP',
      },
    ] as Prisma.JsonArray,
    priceMeta: {} as Prisma.JsonValue,
    quantity: 17,
    remark: 'Order contains damaged item!',
    reference: 'POS-000004',
    referenceMeta: {} as unknown as Prisma.JsonValue,
    isLocked: false,
    favoritedBy: [],
    locationInfo: {},
    stockInProductsReq: {},
    createdBy: {},
    customerDetails: {},
    dropShipping: false,
  },
  {
    id: '00000000-0000-1000-ad00-000000000005',
    clientId: '00000000-0000-1000-b000-0000000000022',
    status: DBStockInItemStatus.CANCELLED,
    documentId: '',
    documentMeta: {} as unknown as Prisma.JsonValue,
    createdAt: new Date(),
    orderType: DBStockInItemType.UNMATCHED,
    receivedFromMeta: {
      branchId: 2,
      branchName: 'Poslovnica Split',
      city: 'Split',
      zipCode: '21000',
      address: 'Ferhadija 1',
    } as unknown as Prisma.JsonValue,
    orderMeta: {
      orderId: null,
      orderNumber: null,
      supplierId: '07c91a78-e661-47df-800e-f197ea7b7022',
      supplierName: 'Vanderban',
    } as unknown as Prisma.JsonValue,
    destinationMeta: {
      branchId: 3,
      branchName: 'Poslovnica Kastela',
      city: 'Split',
      zipCode: '21000',
      address: 'Kaštel Stari',
    } as unknown as Prisma.JsonValue,
    lineItems: [
      {
        ean: '4019238035551',
        sku: 'S2254517CON',
        uid: 'f47d08c0-b99d-43bd-a807-825179413887',
        quantity: 2,
        productName: '225/45 R 17 91W PremiumContact 6 FR ',
        productionYear: '2019',
        connectedToItem: '73acedc8-f29c-4e91-b813-9e59cdc18f9a',
        manufacturerCode: '03112060000',
        damaged: false,
        ordered: 20,
        backordered: 17,
        received: 3,
        brand: 'CONTINENTAL',
      },
    ] as Prisma.JsonValue,
    priceMeta: {} as Prisma.JsonValue,
    quantity: 20,
    remark: '',
    reference: 'FOR STOCK',
    referenceMeta: {} as unknown as Prisma.JsonValue,
    isLocked: false,
    favoritedBy: [],
    locationInfo: {},
    stockInProductsReq: {},
    createdBy: {},
    customerDetails: {},
    dropShipping: false,
  },
];
