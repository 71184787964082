import styled from '@emotion/styled';
import { FormControlLabel, Grid, Switch } from '@mui/material';

interface AutomaticOrdersProps {
  props: {
    ac_shippingStockOut: boolean;
    set_ac_shippingStockOut: (val: boolean) => void;
    ac_dispatchStockOut: boolean;
    set_ac_dispatchStockOut: (val: boolean) => void;
    ac_collectionStockOut: boolean;
    set_ac_collectionStockOut: (val: boolean) => void;
    ac_fittingStockOut: boolean;
    set_ac_fittingStockOut: (val: boolean) => void;
  };
}

export const AutomaticStockOut = (props: AutomaticOrdersProps) => {
  const {
    ac_shippingStockOut,
    set_ac_shippingStockOut,
    ac_dispatchStockOut,
    set_ac_dispatchStockOut,
    ac_collectionStockOut,
    set_ac_collectionStockOut,
    ac_fittingStockOut,
    set_ac_fittingStockOut,
  } = props.props;

  return (
    <Wrapper>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Auto complete shipping stock out"
          control={
            <Switch
              checked={ac_shippingStockOut}
              onChange={(e) => {
                set_ac_shippingStockOut(e.target.checked);
              }}
            />
          }
        />
      </Grid>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Auto complete dispatch stock out"
          control={
            <Switch
              checked={ac_dispatchStockOut}
              onChange={(e) => {
                set_ac_dispatchStockOut(e.target.checked);
              }}
            />
          }
        />
      </Grid>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Auto complete collection stock out"
          control={
            <Switch
              checked={ac_collectionStockOut}
              onChange={(e) => {
                set_ac_collectionStockOut(e.target.checked);
              }}
            />
          }
        />
      </Grid>
      <Grid display="flex" alignItems="center" justifyContent="center">
        <FormControlLabel
          label="Auto complete fitting stock out"
          control={
            <Switch
              checked={ac_fittingStockOut}
              onChange={(e) => {
                set_ac_fittingStockOut(e.target.checked);
              }}
            />
          }
        />
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
`;
