import styled from '@emotion/styled/macro';
import {
  DBCustomerOrderStatusEnum,
  EDIInquiryItemRequest,
  IWorkOrderLineItem,
  InProcessSubStatuses,
} from '@tyrio/dto';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import {
  PageGrid,
  PageTemplateHeader,
} from '../../components/PageTemplateV2/PageTemplateV2';
import { PriceComparisonContext } from '../../pages/price-compare/components/context';
import { OrderDataInterface } from '../../pages/price-compare/types';
import { CustomerOrdersSidebar } from '../customer-orders/CustomerOrdersSidebar';
import { SalesOrdersFlowContext } from './SalesOrdersFlowContext';
import { OrderAccordion } from './components/OrderAccordion';
import { useInquireEansMultipleSuppliers } from '../../hooks/api/useInquireEansMultipleSuppliers';
import _, { get, isEmpty, isNull, set } from 'lodash';
import { WorkOrdersStatus } from '@prisma/client';
import { ExpiredSessionModal } from '../../components/Timer/modals/ExpiredSession';

export const SalesOrderData = () => {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [outOfStockModal, setOutOfStockModal] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<OrderDataInterface>({});
  const [showSalesPrice, setShowSalesPrice] = useState<boolean>(false);

  const ctx = useContext(SalesOrdersFlowContext);
  const poctx = useContext(PriceComparisonContext);

  const [isDirty, setIsDirty] = useState(false);

  const supplierIds = useMemo(
    () => Object.keys(ctx.data.suppliers),
    [ctx.data.suppliers]
  );

  const inquiryOrderItems = useMemo(() => {
    const requested = ctx.data.requested;

    const items: EDIInquiryItemRequest[] = [];

    Object.keys(requested ?? {}).forEach((productId) => {
      const requestedQty = requested?.[productId];
      const ean = ctx.data.products?.[productId]?.ean;

      if (requestedQty > 0)
        items.push({
          ean,
          quantity: requestedQty,
        });
    });

    return items;
  }, [ctx.data.products, ctx.data.requested]);

  const inquiryState = useInquireEansMultipleSuppliers({
    supplierIds,
    items: inquiryOrderItems,
  });

  const setIsOpen = useCallback(
    (id: string) => (value: boolean) => {
      setOpen((old) => ({
        ...old,
        [id]: value,
      }));
    },
    []
  );

  const customerOrderBranchErpId = useMemo(() => {
    return ctx.customerOrder?.branchErpId &&
      !_.isEmpty(ctx.customerOrder?.branchErpId)
      ? ctx.customerOrder?.branchErpId
      : undefined;
  }, [ctx.customerOrder?.branchErpId]);

  const erpToBranchId = useCallback(
    (erpBranchId: string) => {
      const branches = Object.values(ctx.data.warehouses);
      return (branches ?? []).find((b) => b.erpId === erpBranchId)?.id;
    },
    [ctx.data.warehouses]
  );

  const productsList = useMemo(
    () => Object.values(ctx.data.products || {}),
    [ctx.data.products]
  );

  const getEan = useCallback(
    (productId: string) => {
      const findProduct = get(ctx.data.products, productId);
      return findProduct?.ean ?? '';
    },
    [ctx.data.products]
  );

  const prepLineItems = useCallback(
    (value: Record<string, number>) => {
      const lineItems: (IWorkOrderLineItem | null)[] = Object.entries(
        value
      ).map(([key, value], index) => {
        const productId = key;
        const quantity = value;

        if (quantity <= 0) return null;

        const ean = getEan(productId);

        const lineItem = ctx.data.lineItems.find((item) => item.ean === ean);

        if (lineItem) {
          const item = {
            erpId: '',
            ean: lineItem?.ean ?? '',
            productName: lineItem?.productName,
            brand: lineItem?.brand ?? '',
            quantity: quantity,
            received: 0,
            deliveryDateFrom: lineItem?.deliveryDateFrom?.toString() ?? '',
            deliveryDateTo: lineItem?.deliveryDateTo?.toString() ?? '',
            sku: lineItem?.sku ?? '',
            sellingPrice: lineItem?.sellingPrice,
            reservedOnLocation: [],
            dot: lineItem?.dot ?? '',
            productId,
            cashDiscount: lineItem.cashDiscount ?? 0,
            branchId:
              poctx.data.workOrderData?.serviceAddressBranches?.[index + 1] ??
              undefined,
          };
          return item;
        }
        return null;
      });
      return lineItems;
    },
    [
      ctx.data.lineItems,
      getEan,
      poctx.data.workOrderData?.serviceAddressBranches,
    ]
  );

  const prepareWorkOrders = useCallback(() => {
    const data: Record<string, Record<string, number>> = get(
      ctx,
      'qty.stock',
      {}
    );

    const order = {};
    const serviceAddressBranches = {};

    Object.entries(data).map(([key, value]) => {
      const branchId = key;
      const branch = ctx.data.warehouses[branchId];

      const lineItems = prepLineItems(value).filter(
        (l) => !isNull(l)
      ) as IWorkOrderLineItem[];

      const obj = {
        branchId,
        branchName: branch.branchName,
        customerOrderId: ctx.customerOrderId,
        erpBranchId: branch.erpId ?? '',
        orderStatus: {
          status: DBCustomerOrderStatusEnum.IN_PROCESS,
          subStatus: InProcessSubStatuses.Ready_for_picking,
        },
        externalStatus: WorkOrdersStatus.NEW,
        lineItems,
        serviceErpBranchId: branch.erpId ?? '',
      };

      if (!isEmpty(lineItems)) set(order, key, obj);

      const isQtyZero = Object.values(value).every((q) => q === 0);

      if (!isQtyZero) {
        /**
         * if order has erp branch id, that id has priority
         */
        let serviceAddressBranchId = Number(branchId);
        if (customerOrderBranchErpId)
          serviceAddressBranchId =
            erpToBranchId(customerOrderBranchErpId) ?? Number(branchId);
        set(serviceAddressBranches, branchId, serviceAddressBranchId);
      }

      return obj;
    });

    const serviceAddressBranchesNew = {
      ...(poctx.data.workOrderData?.serviceAddressBranches ?? {}),
      ...serviceAddressBranches,
    };

    poctx.setInputValue({
      workOrderData: {
        ...poctx.data.workOrderData,
        order: order,
        serviceAddressBranches: serviceAddressBranchesNew,
      },
    });
  }, [ctx, customerOrderBranchErpId, erpToBranchId, poctx, prepLineItems]);

  const preparePurchaseOrder = useCallback(() => {
    const order: OrderDataInterface = {};

    const data = ctx.qty?.supplier ?? {};

    let shouldOpenModal = false;

    Object.keys(data).forEach((supplierId) => {
      Object.keys(data[supplierId] ?? {}).forEach((productId) => {
        const qty = data[supplierId][productId];

        if (qty > 0) {
          if (!order[supplierId]) order[supplierId] = [];

          const orderPriceObject =
            ctx.data.prices?.[supplierId]?.[productId] ?? {};

          const resolvedSupplierQty =
            inquiryState.inquiryState?.[supplierId]?.[orderPriceObject?.ean]
              ?.qty;

          const supplierQty =
            ctx.data.supplierQty?.[productId]?.[supplierId] ?? 0;

          const supplierQuantity = resolvedSupplierQty ?? supplierQty;

          if (supplierQuantity === 0) {
            setOutOfStockModal(true);
            shouldOpenModal = true;
          }

          let price = 0;
          if (_.isEmpty(orderPriceObject)) {
            // this supplier doesn't exist on supplier stock and it is main supplier for this item
            price =
              ctx.data.lineItems.find((i) => i.extUid === productId)?.purchasing
                ?.purchasePrice ?? 0;
          }
          const product = ctx.data.products[productId];

          order[supplierId].push({
            productName: orderPriceObject?.productName ?? product.productName,
            shipping: orderPriceObject?.shipping ?? 0,
            price: orderPriceObject?.price ?? price,
            ecoTax: orderPriceObject?.ecoTax ?? 0,
            bonus: 0,
            productYear: orderPriceObject?.productYear ?? '',
            sku: orderPriceObject?.sku ?? product.sku,
            ipc: orderPriceObject?.ipc ?? product.manufacturerCode,
            ean: orderPriceObject?.ean ?? product.ean,
            quantity: qty,
            uid: productId,
            brand: orderPriceObject?.brand ?? product.model?.brand ?? '',
            invoicePrice: 0,
          });
        }
      });
    });

    setPurchaseOrder(order);

    if (!shouldOpenModal) {
      prepareWorkOrders();

      poctx.setInputValue({
        reference: ctx.data.orderRef,
        order,
      });
      ctx.setScreen('purchase_order');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, poctx, prepareWorkOrders]);

  const canContinue = useMemo(() => {
    if (!ctx.isFillQtyDone) return false;
    else {
      let sum = 0;
      Object.values(ctx.qty?.supplier ?? {}).forEach((product) => {
        Object.values(product ?? {}).forEach((value) => {
          sum += value;
        });
      });

      Object.values(ctx.qty?.stock ?? {}).forEach((product) => {
        Object.values(product ?? {}).forEach((value) => {
          sum += value;
        });
      });

      const purchasedQty = Object.values(ctx.data.purchased ?? {})
        .flatMap(Object.values)
        .reduce((acc, value) => acc + value, 0);
      return (
        sum > 0 &&
        Object.keys(ctx.selected ?? {}).length > 0 &&
        sum !== purchasedQty
      );
    }
  }, [ctx.data.purchased, ctx.qty, ctx.selected, ctx.isFillQtyDone]);

  console.log({ inquiryState });

  return (
    <PageTemplateWrapper
      style={{
        paddingRight: poctx.canSidebarOpen && poctx.data.sidebarOpen ? 450 : 0,
        transition: 'all .3s ease',
      }}
    >
      <ExpiredSessionModal
        text={'Some products are currently out of stock'}
        subtitle={'are you sure you want to continue?'}
        isOpen={outOfStockModal ?? false}
        onContinue={() => {
          prepareWorkOrders();

          poctx.setInputValue({
            reference: ctx.data.orderRef,
            order: purchaseOrder,
          });
          ctx.setScreen('purchase_order');
        }}
        onCancel={() => {
          setOutOfStockModal(false);
        }}
      />
      <PageTemplateContent>
        <PageGrid is="process_order">
          <PageTemplateHeader
            title="Order process"
            showBack={false}
            switchedLabel="show sale prices"
            switchedState={showSalesPrice}
            setSwitchedState={setShowSalesPrice}
          />
          <Content>
            {productsList.map((product, index) => (
              <OrderAccordion
                key={product.uid + index}
                product={product}
                setIsOpen={setIsOpen(product.uid)}
                inquiryState={inquiryState}
                isOpen={
                  typeof open[product.uid] === 'undefined'
                    ? true
                    : open[product.uid]
                }
                isSalePrices={showSalesPrice}
              />
            ))}
          </Content>
          <BottomSpacer />
        </PageGrid>
      </PageTemplateContent>

      <CustomerOrdersSidebar
        isDirty={isDirty}
        isUploadInProcess={false}
        setIsUploadInProcess={() => null}
        setIsDirty={setIsDirty}
        isProcessOrder={true}
        preparePurchaseOrder={preparePurchaseOrder}
        canContinueProcessOrder={canContinue}
        setIsWarningModalOpen={setIsWarningModalOpen}
        isWarningModalOpen={isWarningModalOpen}
      />
    </PageTemplateWrapper>
  );
};

const BottomSpacer = styled.div`
  display: block;
  height: 100px;
  width: 100%;
`;

const Content = styled.div`
  padding: 8px;
  height: fit-content;
`;
