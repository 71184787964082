import styled from '@emotion/styled';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DBStockCountStatus } from '@prisma/client';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import { StockCountContext } from '../../../context/StockCountContext';
import { useBranchQuery } from '../../../hooks/api/useBranchQuery';
import { useStockCountHelper } from '../helpers/helper';
import { useUpdateStatus } from '../queries/update-status-desc';
import { Container } from './style/styled';

export const Summary = () => {
  const { calculateQty } = useStockCountHelper();

  const { mutate } = useUpdateStatus();

  const { selectedStockCountData } = useContext(StockCountContext);

  const [status, setStatus] = useState<DBStockCountStatus | null>(null);
  const [remark, setRemark] = useState<string | null>(null);

  useEffect(() => {
    setStatus(selectedStockCountData?.status as DBStockCountStatus);
    setRemark(selectedStockCountData?.description as string);
  }, [selectedStockCountData]);

  const { data: branches } = useBranchQuery();

  const branchName = branches?.data.find(
    (item) => item.id === selectedStockCountData?.branchId
  )?.branchName;

  const expected = calculateQty(selectedStockCountData?.expected);
  const scanned = calculateQty(selectedStockCountData?.scanned);

  const diff = scanned - expected;

  const getScannedColor = () => {
    if (scanned === 0) return '#212B36';

    if (expected === scanned) return '#38A169';

    if (expected > scanned) return '#FFCC4D';
    else return '#DB1E1E';
  };

  const getDiffColor = () => {
    if (diff < 0) return '#DB1E1E';

    return '#212B36';
  };

  const isBolded = () => {
    return scanned !== 0 || diff < 0;
  };

  return (
    <Container>
      <Grid>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Title>Stock count summary</Title>
          <Chip
            label={`${status}`}
            color="info"
            variant="filled"
            size="medium"
          />
        </Grid>
        <Grid>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              defaultValue={selectedStockCountData?.status}
              label="Status"
              size="medium"
              onChange={(e) => {
                setStatus(e.target.value as DBStockCountStatus);
              }}
            >
              {parseEnumToArray(DBStockCountStatus)?.map((s) => (
                <MenuItem key={s.value} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid display="flex" gap={2} marginTop={2}>
            <Box>
              <BoxTitle>Branch</BoxTitle>
              <BoxSubtitle>{branchName}</BoxSubtitle>
            </Box>
            <Box>
              <BoxTitle>Scheduled date</BoxTitle>
              <BoxSubtitle>
                {moment(selectedStockCountData?.scheduledDate).format(
                  'DD.MM.YYYY'
                )}
              </BoxSubtitle>
            </Box>
          </Grid>
          <Grid display="flex" gap={2} marginTop={2}>
            <BoxDashed>
              <BoxNumbersContainer>
                <BoxTitle>Expected</BoxTitle>
                <BoxNumbers>{expected}</BoxNumbers>
              </BoxNumbersContainer>
              <BoxNumbersContainer>
                <BoxTitle>Scanned</BoxTitle>
                <BoxNumbers
                  sx={{
                    fontWeight: isBolded() ? 600 : 400,
                    color: getScannedColor(),
                  }}
                >
                  {scanned}
                </BoxNumbers>
              </BoxNumbersContainer>
              <BoxNumbersContainer>
                <BoxTitle>Difference</BoxTitle>
                <BoxNumbers
                  sx={{
                    color: getDiffColor(),
                    fontWeight: isBolded() ? 600 : 400,
                  }}
                >
                  {diff}
                </BoxNumbers>
              </BoxNumbersContainer>
            </BoxDashed>
          </Grid>
          <Grid>
            <TextField
              variant="outlined"
              label="Remark"
              fullWidth
              style={{ marginTop: '16px' }}
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="space-between">
        <Button
          variant="text"
          color="error"
          onClick={() => console.log('error')}
        >
          CANCEL
        </Button>
        <Grid display="flex" gap={2}>
          <Button
            variant="text"
            color="info"
            startIcon={<SaveIcon />}
            onClick={() => {
              mutate({
                id: selectedStockCountData?.id as string,
                status: status as DBStockCountStatus,
                description: remark as string,
              });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() =>
              mutate({
                id: selectedStockCountData?.id as string,
                status: DBStockCountStatus.COUNTED,
                description: remark as string,
              })
            }
          >
            FINISH COUNTING
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
`;

const Box = styled.div`
  padding: 16px;
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  width: 50%;
`;

const BoxDashed = styled.div`
  padding: 16px;
  border: 1px dashed #dfe3e8;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const BoxTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  display: flex;
  flex-direction: column;
`;

const BoxNumbersContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BoxNumbers = styled(Typography)`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.30000001192092896px;
`;

const BoxSubtitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  color: #212b36;
`;
