import { useContext } from 'react';
import { ExpiredSessionModal } from '../../components/Timer/modals/ExpiredSession';
import { PosCartContext } from '../../context/PosCartContext';
import { useWS } from '../../context/WSContext';
import { POSContext } from '../../context/POSContext';

export const PosSessionExpiredModal = () => {
  const ws = useWS();
  const {
    openSessionExpiredModal,
    setOpenSessionExpiredModal,
    timer,
    clearAllPosCartData,
  } = useContext(PosCartContext);
  const { setIsPosCheckout } = useContext(POSContext);

  const handleCancel = () => {
    ws.socket?.emit('remove-all-pos-cart-items', {});
    clearAllPosCartData();
    setIsPosCheckout(false);
    setOpenSessionExpiredModal(false);
    timer?.clear();
  };

  const handleContinue = () => {
    setOpenSessionExpiredModal(false);
    timer?.restart();
  };

  return (
    <ExpiredSessionModal
      text={'POS RESERVATION SESSION EXPIRED'}
      isOpen={openSessionExpiredModal}
      onCancel={handleCancel}
      onContinue={handleContinue}
    />
  );
};
