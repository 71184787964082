export const splitPCD = (input?: string | string[]) => {

  const holeNumberSet = new Set<string>();
  const holeCircleSet = new Set<string>();
  const pcdSet = new Set<string>();

  let pcdOptions: string[] = [];

  if (typeof input === 'string') pcdOptions = input.toLowerCase().split(',');
  else pcdOptions = input ?? [];

  pcdOptions.forEach((pcd) => {
    const [holeNumber, holeCircle] = pcd.toLowerCase().split('x');
    if (!!holeNumber && !!holeCircle) {
      holeNumberSet.add(holeNumber);
      holeCircleSet.add(holeCircle);
      pcdSet.add(pcd.toLowerCase());
    }
  });

  return {
    holeNumber: Array.from(holeNumberSet),
    holeCircle: Array.from(holeCircleSet),
    pcd: Array.from(pcdSet),
  };
};
